import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Components
import { sortBy } from 'lodash';
import cn from '../../utils/cn';
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
import { getVehiculelist } from '../../services/vehicules';
import { postDocumentBaseItem, getDocumentwithid } from '../../services/docBase';
import useAppContext from '../../store/useAppContext';
// css
import styles from './AddDocument.module.css';

function AddDocument() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlParams = useParams();
  const [context] = useAppContext();

  const goBack = (message) => {
    toast.success(message);
    navigate(-1);
  };
  // Api call
  const postDocumentBaseItemMutation = useMutation(postDocumentBaseItem, {
    onSuccess: () => {
      goBack(t('documentary.succesAdd'));
    },
  });

  const initialValues = {
    name: '',
    documentFile: '',
    date: '',
    vehicule: '',
    categoryId: urlParams.categorieid,
    typeId: urlParams.typeid,
    category: '',
    // nature: '',
    // society: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(t('global.required_field')).typeError(t('global.wrong_type')),
    documentFile: Yup.mixed().nullable().typeError(t('global.wrong_type')).required('global.required_field')
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg')),
    date: Yup.string().nullable().required(t('global.required_field')),
    categoryId: Yup.string(),
    typeId: Yup.string(),
    vehicule: Yup.string().nullable(),
    category: Yup.string(),
    // nature: Yup.string(),
    // society: Yup.string(),
  });

  const getDocumentwithidQuery = useQuery(['getDocumentwithid'], () => getDocumentwithid({
    id: urlParams.categorieid,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const getVehiclesListQuery = useQuery(['vehiclesList'], () => getVehiculelist({
    page: 0,
    establishmentId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
  }));

  const mapForSelectVehicule = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: `${item?.brand} ${item?.model} (${item?.licensePlate})`,
      value: item.id,
    }))
    : []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('documentFile', values.documentFile);
      formData.append('date', values.date);
      if (values.vehicule !== '') formData.append('vehicule', values.vehicule);
      formData.append('categoryId', urlParams.categorieid);
      if (urlParams.typeid) {
        formData.append('typeId', urlParams.typeid);
      }
      formData.append('structureIds[0]', context.choiceEstablishment.id);
      if (urlParams.folderid) {
        formData.append('folderId', urlParams.folderid);
        formData.append('typeId', values.category);
      }
      // formData.append('nature', getDocumentwithidQuery.data?.data?.nature);
      // formData.append('society', getDocumentwithidQuery.data?.data?.society);
      postDocumentBaseItemMutation.mutate(formData);
    },
  });

  const category = [
    { label: 'Autorités', value: 'autorites', id: 'autorites' },
    { label: 'Organismes Agréés', value: 'orgaAgree', id: 'orgaAgree' },
    { label: 'Entreprises', value: 'entreprises', id: 'entreprises' },
  ];

  // const autorites = [
  //   { label: 'Demande de travaux CERFA', value: 'workRequest', id: 'workRequest' },
  //   { label: 'Notification d’avis de la mairie', value: 'notif', id: 'notif' },
  //   { label: 'PV de réception des travaux', value: 'pv', id: 'pv' },
  //   { label: 'Autre', value: 'autre', id: 'autre' },
  // ];

  // const orgaAgree = [
  //   { label: 'RVAT', value: 'rvat', id: 'rvat' },
  //   { label: 'Autre', value: 'autre', id: 'autre' },
  // ];

  // const entreprises = [
  //   { label: 'Cahier des charges', value: 'specifications', id: 'specifications' },
  //   { label: 'Dossier de consultation des entreprises', value: 'consultation', id: 'consultation' },
  //   { label: 'Assurance Décennale', value: 'decennial', id: 'decennial' },
  //   { label: 'DAACT', value: 'daact', id: 'daact' },
  //   { label: 'Rapport de Réception Technique SSI', value: 'ssi', id: 'ssi' },
  //   { label: 'PV de réception technique', value: 'pv', id: 'pv' },
  //   { label: 'Déclaration de conformité', value: 'declaration', id: 'declaration' },
  //   { label: 'Notice de sécurité', value: 'safety', id: 'safety' },
  //   { label: 'Autre', value: 'autre', id: 'autre' },
  // ];

  // const choiceOptions = () => {
  //   if (formik.values.category === 'autorites') {
  //     return autorites;
  //   }
  //   if (formik.values.category === 'orgaAgree') {
  //     return orgaAgree;
  //   }
  //   if (formik.values.category === 'entreprises') {
  //     return entreprises;
  //   }
  //   return [];
  // };

  return (
    <Layout
      title="Documentaire Base Item"
      layout="table"
      queryError={
        postDocumentBaseItemMutation?.error
        || getDocumentwithidQuery?.error
        || getVehiclesListQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.return')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {getDocumentwithidQuery.data?.data?.name}
          </h1>
        </div>
      </header>
      <div className={styles.table}>
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          <div className="form_group">
            <Input
              id="name"
              name="name"
              type="text"
              label={t('documentary.name')}
              placeholder={t('documentary.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              required
            />
            {formik?.errors?.name && formik?.touched?.name ? (
              <div className="error">
                {t(formik?.errors?.name)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="date"
              name="date"
              type="date"
              label={t('documentary.date')}
              placeholder={t('documentary.date')}
              value={formik.values.date}
              onChange={formik.handleChange}
              required
            />
            {formik?.errors?.date && formik?.touched?.date ? (
              <div className="error">
                {t(formik?.errors?.date)}
              </div>
            ) : null }
          </div>
          { context?.choiceBooklet === 3 && (
            <>
              <div className="form_group">
                <Select
                  label={t('docVehicule.vehicule')}
                  id="vehicule"
                  name="vehicule"
                  type="text"
                  placeholder={t('docVehicule.vehicule')}
                  onChange={(value) => formik.setFieldValue('vehicule', value.value)}
                  onBlur={formik.handleBlur}
                  required
                  valueInput={formik.values.vehicule}
                  options={mapForSelectVehicule(
                    getVehiclesListQuery?.data?.data?.vehicles,
                  )}
                  value={mapForSelectVehicule(
                    getVehiclesListQuery?.data?.data?.vehicles,
                  ).find((item) => item.value === formik.values.buildingId)}
                  loading={getVehiclesListQuery.isLoading}
                />
                {formik.touched.vehicule && formik.errors.vehicule ? (
                  <div className="error">{formik.errors.vehicule}</div>
                ) : null}
              </div>
              <div className="form_group">
                {' '}
              </div>
            </>
          )}
          <div className="form_group">
            <div className="label">
              {formik.values.categoryId === '8' ? t('permis_feu.ajout_creer') : t('permis_feu.add_doc')}
            </div>
            <UploadFile
              id="documentFile"
              name="documentFile"
              fileName={formik.values.documentFile ? formik.values.documentFile.name : formik.values.documentFileName}
              onChange={(event) => { formik.setFieldValue('documentFile', event.target.files[0]); }}
              onBlur={formik.handleBlur}
            />
            {formik?.errors?.documentFile && formik?.touched?.documentFile ? (
              <div className="error">
                {t(formik?.errors?.documentFile)}
              </div>
            ) : null }
            {formik.values.documentFile === '' && formik.values.categoryId === '8' ? (
              <Button
                type="button"
                label={t('permis_feu.creer_permis')}
                className={cn(['form_submit', styles.button_creer])}
                onClick={() => { navigate('/permisfeu'); }}
              >
                {' '}
              </Button>
            ) : null}
          </div>
          {urlParams.folderid && (
            <div className="form_group">
              <Select
                name="category"
                type="select"
                label={t('dossierBase.category')}
                value={
                  category.find(
                    (item) => item.value === formik.values.category,
                  )
                }
                onChange={(value) => formik.setFieldValue('category', value.value)}
                options={category}
                valueInput={formik.values.category}
                required={formik.values.category === '1'}
              />
              {formik.errors.category && formik.touched.category ? (
                <div className="error">
                  {t(formik.errors.category)}
                </div>
              ) : null }
            </div>
          )}
          {/* <div className="form_group">
            <Select
              name="nature"
              type="select"
              label={t('dossierBase.nature')}
              value={
                choiceOptions().find(
                  (item) => item.value === formik.values.nature,
                )
              }
              onChange={(value) => formik.setFieldValue('nature', value.value)}
              options={choiceOptions()}
              valueInput={formik.values.nature}
              disabled={formik.values.category === ''}
            />
            {formik.errors.nature && formik.touched.nature ? (
              <div className="error">
                {t(formik.errors.nature)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="society"
              name="society"
              type="text"
              label={t('dossierBase.society')}
              placeholder={t('dossierBase.society')}
              value={formik.values.society}
              onChange={formik.handleChange}
              required
            />
            {formik?.errors?.society && formik?.touched?.society ? (
              <div className="error">
                {t(formik?.errors?.society)}
              </div>
            ) : null }
          </div> */}
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addReports.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('documentary.save')}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AddDocument;
