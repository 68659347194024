/* eslint-disable max-len */
/* eslint-enable  no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from 'react-query';

import Layout from '../../components/template/Layout';
import EstablishmentCardChoice from '../../components/molecules/List/EstablishmentCardChoice/EstablishmentCardChoice';
import Search from '../../components/molecules/Search/Search';
// hooks
import useAppContext from '../../store/useAppContext';

// api
import { getEstablishmentsList } from '../../services/structures';

// style
import styles from './ChoiceEstablishment.module.css';

// util
import cn from '../../utils/cn';
import Picto from '../../components/atoms/Picto/Picto';

function ChoiceEstablishment() {
  const [search, setSearch] = useState('');
  const [establishmentsFiltered, setEstablishmentsFiltered] = useState('');
  const [establishments, setEstablishments] = useState();
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const navigate = useNavigate();
  const [getEstablishmentsListQuery, setGetEstablishmentsListQuery] = useState();

  const fetchData = async () => {
    try {
      const data = await getEstablishmentsList({ bookletId: 1 });
      setGetEstablishmentsListQuery(data);
      setEstablishmentsFiltered(data?.config?.params?.bookletId);
      setEstablishments(data?.data?.establishments);
      console.log('data', data?.data?.establishments);
      // dispatch({ type: 'ESTABLISHMENTS_Sanitaire', payload: data });
    } catch (error) {
      // Handle error
      console.error('Error fetching establishments:', error);
    }
  };

  const handleChoiceEstablishment = (establishment) => {
    let estab = establishment.name.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: establishment });
    navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
  };

  useEffect(() => {
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
    if (context?.Establishment_Sanitaire == null) {
      fetchData();
    } else {
      setEstablishmentsFiltered(1);
      setGetEstablishmentsListQuery(context?.Establishment_Sanitaire);
      setEstablishments(context?.Establishment_Sanitaire?.data?.establishments);
    }
  }, []);

  const handleSearch = (e) => {
    const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.establishments?.filter(
      (establishment) => establishment?.name
        .toString()
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0,
    );
    setEstablishments(establishmentsFilteredBySearch);
    setSearch(e.target.value);
  };

  return (
    <Layout
      title={t('choice_establishment.title_page')}
      queryError={
        getEstablishmentsListQuery?.error
      }
    >
      <header className={
        cn(['header',
          styles.headerChoice,
          'shadow-md',
          styles.sanitary,
        ])
}
      >
        <h1 className="title">
          <Picto name="etablissementsRoundedBlue" />
          {' '}
          {t('choice_establishment.title_page')}
          {' '}
          <Picto name="etablissementsRoundedBlue" />
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        <div className={styles.searchRow}>
          <Search
            className="mb-20"
            value={search || ''}
            onChange={handleSearch}
          />
        </div>
        <div>
          {establishmentsFiltered !== 1 && (
            <div className="loader" />
          )}
          {establishmentsFiltered === 1 && (
            <div className={styles.sectionChoiceList}>
              { establishments?.map((establishment) => (
                <EstablishmentCardChoice
                  onClick={handleChoiceEstablishment}
                  establishment={establishment}
                  key={establishment?.id}
                  texte={(
                    <div className={styles.blockSanitaire}>
                      {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent || establishment?.indicators?.lastYearSanitaryInputsDonePercent ? (
                        <div className={styles.texteCard}>
                          <Picto name="contrats" className={styles.picto} />
                          <div className={styles.CardText}>
                            {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent ? (
                              <li key={establishment.indicators?.lastTwoWeekSanitaryInputsDonePercent} className={styles.reporttext}>
                                {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent === 100 ? (
                                  <span className={cn([styles.valueSanitaire, 'tag--success'])}>
                                    {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent}
                                    {' '}
                                  </span>
                                ) : null}
                                {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent < 100
                                  && establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent >= 70 ? (
                                    <span className={cn([styles.valueSanitaire, styles.tagWarning])}>
                                      {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent}
                                      {' '}
                                    </span>
                                  ) : null}
                                {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent < 70 ? (
                                  <span className={cn([styles.valueSanitaire])}>
                                    {establishment?.indicators?.lastTwoWeekSanitaryInputsDonePercent}
                                    {' '}
                                  </span>
                                ) : null}
                                <span className={styles.reporttext}>
                                  {' '}
                                  {t('choice_establishment.lastTwoWeekSanitaryInputsDonePercent')}
                                </span>
                                {' '}
                              </li>
                            ) : ''}
                            {establishment?.indicators?.lastYearSanitaryInputsDonePercent ? (
                              <li key={establishment.indicators?.lastYearSanitaryInputsDonePercent} className={styles.reporttext}>
                                <span className={styles.valueSanitaire}>
                                  {establishment?.indicators?.lastYearSanitaryInputsDonePercent}
                                  {' '}
                                </span>
                                <span className={styles.reporttext}>
                                  {' '}
                                  {t('choice_establishment.lastYearSanitaryInputsDonePercent')}
                                </span>
                                {' '}
                              </li>
                            ) : ''}
                            {establishment?.indicators?.complianceLastSanitaryAnalysisPercent ? (
                              <li key={establishment.indicators?.complianceLastSanitaryAnalysisPercent} className={styles.reporttext}>
                                <span className={styles.valueSanitaire}>
                                  {establishment?.indicators?.complianceLastSanitaryAnalysisPercent}
                                  {' '}
                                </span>
                                <span className={styles.reporttext}>
                                  {' '}
                                  {t('choice_establishment.complianceLastSanitaryAnalysisPercent')}
                                </span>
                              </li>
                            ) : ''}
                          </div>
                        </div>
                      ) : null}
                      <div>
                        {' '}
                      </div>
                      {establishment?.indicators?.lateSanitaryReportsCount ? (
                        <div className={styles.texteCard}>
                          <Picto name="inputs" className={styles.picto} />
                          <div className={styles.CardText}>
                            <li key={establishment.indicators?.lateSanitaryReportsCount} className={styles.reporttext}>
                              <span className={styles.valueSanitaire}>
                                {establishment?.indicators?.lateSanitaryReportsCount}
                                {' '}
                              </span>
                              <span className={styles.reporttext}>
                                {' '}
                                {t('choice_establishment.lateSecurityReportsCount')}
                              </span>
                            </li>
                          </div>
                        </div>
                      ) : ''}
                    </div>
                  )}
                />
              )) || ''}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default ChoiceEstablishment;
