/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Link, useNavigate, useParams, useLocation,
} from 'react-router-dom';
import {
  Formik, Form, ErrorMessage, useFormik,
} from 'formik';
import {
  faChevronLeft, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { sortBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getSociety,
  getSocietyOne,
  addPrestataireToSociety,
  editPrestataireToSociety,
} from '../../services/societies';
import {
  getContractVehicle,
} from '../../services/contracts';
import styles from './AddSociety.module.css';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import useAppContext from '../../store/useAppContext';
import Button from '../../components/atoms/Button/Button';
import Textarea from '../../components/atoms/Textarea/Textarea';
import Input from '../../components/atoms/Input/Input';
import Permissions from '../../components/molecules/Permissions/Permissions';

function AddSociety() {
  const location = useLocation();
  const urlParams = useParams();
  const [name, setName] = React.useState(location?.state?.society?.name || location?.state?.name || '');
  const [type, setType] = React.useState(location?.state?.society?.type || location?.state?.type || '');
  const [SructureID, setSructureID] = React.useState(urlParams?.id);
  const [address, setAdress] = React.useState(location?.state?.society?.address || location?.state?.address || '');
  const [city, setCity] = React.useState(location?.state?.society?.city || location?.state?.city || '');
  const [postalCode, setPostalCode] = React.useState(
    location?.state?.society?.postalCode || location?.state?.postalCode || '',
  );
  const [prestataireId, setPrestataireId] = React.useState();
  const [context] = useAppContext();
  const [buildingId] = React.useState(context?.choiceEstablishment?.buildingIds);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getSocieties = useQuery('societies', () => getSociety({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    mine: true,
  }));

  const initialValues = {
    structuredid: location?.state?.id || location?.state?.society?.id || '',
    comment: '',
    name: '',
    society: '',
    lastname: '',
    email: '',
    telephone1: '',
    telephone2: '',
  };
  const validationSchema = Yup.object().shape({
    society: Yup.string().nullable(),
    name: Yup.string().nullable(),
    lastname: Yup.string().nullable(),
    email: Yup.string().email(t('prestataire.email_invalid')).nullable(),
    telephone1: Yup.string()
      .matches(
        /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/,
        { message: t('prestataire.phone_number_invalid'), excludeEmptyString: true },
      )
      .nullable(),
    telephone2: Yup.string()
      .matches(
        /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/,
        { message: t('prestataire.phone_number_invalid'), excludeEmptyString: true },
      )
      .nullable(),
    comment: Yup.string().nullable(),
  });

  const title = () => {
    if (urlParams.action === 'add') {
      return t('prestataire.add');
    }
    return t('prestataire.edit');
  };
  const backUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };
  const addPrestataireToSocietys = useMutation(addPrestataireToSociety, {
    onSuccess: () => {
      backUrl(t('prestataire.success'));
    },
    onError: () => {
      toast.error(t('prestataire.error'));
    },
  });

  const editPrestataireToSocietys = useMutation(editPrestataireToSociety, {
    onSuccess: () => {
      backUrl(t('prestataire.success'));
    },
    onError: () => {
      toast.error(t('prestataire.error'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams.action === 'add') {
        const data = {
          structureId: context?.choiceEstablishment?.id,
          bookletId: context?.choiceBooklet,
          comment: formValues.comment,
          firstNameContact1: formValues.name,
          lastNameContact1: formValues.lastname,
          emailContact1: formValues.email,
          mainPhoneContact1: formValues.telephone1,
          otherPhoneContact1: formValues.telephone2,
        };
        const config = {
          id: SructureID,
          data,
        };
        addPrestataireToSocietys.mutate(config);
      } else {
        const data = {
          structureId: context?.choiceEstablishment?.id,
          bookletId: context?.choiceBooklet,
          societyId: SructureID,
          firstNameContact1: formValues.name,
          lastNameContact1: formValues.lastname,
          emailContact1: formValues.email,
          mainPhoneContact1: formValues.telephone1,
          otherPhoneContact1: formValues.telephone2,
          comment: formValues.comment,
        };
        const config = {
          id: SructureID,
          data,
        };
        editPrestataireToSocietys.mutate(config);
      }
    },
  });
  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: `${item.name} (${item.postalCode})`,
      value: item.id,
    }))
    : []);

  const getSocietyOneQuery = useQuery(['society', urlParams?.id], () => getSocietyOne({
    establishmentId: context?.choiceEstablishment?.id,
    societyId: SructureID,
    bookletId: context?.choiceBooklet,
  }), {
    enabled: !!urlParams.action === 'edit',
    onSuccess: (data) => {
      setPrestataireId(data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.id);
      // setSocieties(data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id));
      formik.setFieldValue('name', data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.firstNameContact1);
      formik.setFieldValue('lastname', data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.lastNameContact1);
      formik.setFieldValue('email', data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.emailContact1);
      formik.setFieldValue('telephone1', data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.mainPhoneContact1);
      formik.setFieldValue('telephone2', data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.otherPhoneContact1);
      formik.setFieldValue('comment', data.data.societyStructures.find((society) => society.structureId === context?.choiceEstablishment?.id)?.comment);
    },
  });

  const validoption = (option) => {
    console.log(option);
    formik.setFieldValue('society', option.name);
    setName(option.name);
    setType(option.type);
    setAdress(option.address);
    setPostalCode(option.postalCode);
    setCity(option.city);
    setSructureID(option.id);
    formik.setFieldValue('structuredid', option.id);
  };

  useEffect(() => {
    if (urlParams.action === 'edit') {
      getSocietyOneQuery.refetch();
    }
  }, [urlParams.action === 'edit', SructureID]);

  return (
    <Layout
      title={t('societies.title')}
      queryError={getSocieties?.error
        || (urlParams.action === 'edit' && getSocietyOneQuery?.error)
        || addPrestataireToSocietys?.error}
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/society')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('prestataire.back')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{title()}</h1>
        </div>
      </header>
      <div className="form shadow-sm">
        <div className="form_group">
          <Formik
            initialValues={initialValues}
          >
            {({
              values,
              handleSubmite,
            }) => (
              <Form onSubmit={handleSubmite}>
                <div>
                  <Select
                    id="society"
                    label="Prestataire"
                    name="society"
                    options={
                      getSocieties?.isLoading ? [] : mapForSelect(getSocieties?.data?.data?.societies)

                    }
                    onChange={(option) => {
                      validoption(option);
                    }}
                    values={mapForSelect(getSocieties?.data?.data?.societies || [])
                      .find((option) => option?.value === values?.society)
                      || location?.state?.society?.name || location?.state?.name || ''}
                    loading={getSocieties.isLoading}
                    disabled={urlParams.action === 'edit'}
                  />
                  <ErrorMessage name="society" component="div" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className={styles.society}>
          {name && type && address && postalCode && city && (
          <div>
            <span className={styles.span}>
              {t('societies.Name')}
              :
              <br />
              {name}
            </span>
            <br />
            <span className={styles.span}>
              {t('societies.type')}
              :
              <br />
              {t(`societies.${type}`) !== `societies.${type}` ? t(`societies.${type}`) : type}
            </span>
            <br />
            <span className={styles.span}>
              {t('societies.Address')}
              :
              <br />
              {address}
              {' '}
              {postalCode}
              {' '}
              {city}
            </span>
          </div>
          )}
        </div>
        {urlParams.action === 'add' && (
          <div className="form_footer">
            <div className="form_infos">
              <small>{' '}</small>
            </div>
            <Link to="/addSociety" className="add col-3">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('societies.new_add')}</span>
            </Link>
          </div>
        )}
      </div>
      <div className="py-4">
        <span>
          <h2 className="title">
            {t('prestataire.interlocuteur')}
          </h2>
        </span>
      </div>
      <div className="mb-2">
        <span className={styles.info}>
          Ces informations ne seront pas partagées et disponibles uniquement pour cet établissement.
        </span>
      </div>
      <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
        <div className="form_group">
          <Input
            label="prenom"
            name="name"
            id="name"
            type="text"
            value={formik.values.name}
            placeholder={t('prestataire.firstname')}
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.name && formik.touched.name ? (
          <div className="error">
            {formik.errors.name}
          </div>
        ) : null}
        <div className="form_group">
          <Input
            type="text"
            label="nom"
            name="lastname"
            id="lastname"
            value={formik.values.lastname}
            placeholder={t('prestataire.lastname')}
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.lastname && formik.touched.lastname ? (
          <div className="error">
            {formik.errors.lastname}
          </div>
        ) : null}
        <div className="form_group">
          <Input
            type="tel"
            label="telephone principal"
            name="telephone1"
            id="telephone1"
            value={formik.values.telephone1}
            placeholder={t('prestataire.phone1')}
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.telephone1 && formik.touched.telephone1 ? (
          <div className="error">
            {formik.errors.telephone1}
          </div>
        ) : null}
        <div className="form_group">
          <Input
            type="tel"
            label="telephone secondaire"
            name="telephone2"
            id="telephone2"
            value={formik.values.telephone2}
            placeholder={t('prestataire.phone2')}
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.telephone2 && formik.touched.telephone2 ? (
          <div className="error">
            {formik.errors.telephone2}
          </div>
        ) : null}
        <div className="form_group">
          <Input
            type="text"
            label="email"
            name="email"
            id="email"
            value={formik.values.email}
            placeholder={t('prestataire.email')}
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.email && formik.touched.email ? (
          <div className="error">
            {formik.errors.email}
          </div>
        ) : null}
        <div className={styles.commentaire}>
          <Textarea
            label="commentaire"
            name="comment"
            id="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            placeholder={t('prestataire.comment')}
          />
        </div>
        {formik.errors.comment && formik.touched.comment ? (
          <div className="error">
            {formik.errors.comment}
          </div>
        ) : null}
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('addInterventions.mandatory_fields')}</small>
          </div>
          {Permissions({ permission: 'SOCIETY_EDIT' }) !== undefined
            ? (
              <div className={styles.form_button}>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('prestataire.save')}
                />
                <Button
                  type="button"
                  className={styles.cancel}
                  onClick={() => navigate('/societyList')}
                  label={t('prestataire.cancel')}
                />
              </div>
            ) : null }
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate('/society')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('prestataire.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddSociety;
