import api from './_api';

/**
 * GET - Get dairy list
 * @param {Number: page, Number: bookletId, Number: buildingId} pageid
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const getDairyList = (queryParams) => api.get('/diaries', { params: queryParams });

/**
 * GET - Get dairy detail
 * @param {Number: dairyId} params
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const getDairyDetail = (params) => api.get(`/diaries/${params.id}`);

/**
 * GET - get dairy signatures
 * @param {id: Number} param
 * @returns {Promise<AxiosResponse>}
 */
export const getSignature = (param) => api.get(`/diaries/${param.id}/download-signature`);

/**
 * POST - Add dairy
 * @param {Object} params
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const addDairy = (params) => api.post('/diaries', params);

/**
 * Put - update dairy
 * @param {Object} params
 * @returns {Promise<AxiosResponse>}
 */
export const updateDairy = (params) => api.put(`/diaries/${params.id}`, params);

/**
 * DELETE - Delete dairy
 * @param {Number: dairyId} params
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const deleteDairy = (params) => api.delete(`/diaries/${params}`);
