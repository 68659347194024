/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Hooks
import useAppContext from '../../store/useAppContext';
// Components
import Input from '../../components/atoms/Input/Input';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Button from '../../components/atoms/Button/Button';
import InfoMessage from '../../components/atoms/InfoMessage/InfoMessage';
import QueryError from '../../components/molecules/QueryError/QueryError';
// Services
import { postLoginCheck, postRequestResetPassword } from '../../services/security';
import { getUserMe } from '../../services/users';
// Utils
import LogoWhite from '../../assets/img/logos/e-carnet-white-logo.png';
import LogoSecurite from '../../assets/img/logos/e-carnet-securite-logo.png';
import LogoSanitaire from '../../assets/img/logos/e-carnet-sanitaire-logo.png';
import LogoVehicule from '../../assets/img/logos/e-carnet-vehicule-logo.png';
import styles from './Home.module.css';
import cn from '../../utils/cn';

const initialLoginValues = {
  email: '',
  password: '',
};

const initialForgotPasswordValues = {
  email: '',
};

const validationLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('global.invalid_email_address')
    .required('global.required_field'),
  password: Yup.string()
    .required('global.required_field'),
});

const validationForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('global.invalid_email_address')
    .required('global.required_field'),
});

function Home() {
  // States
  const [forgotPassword, setForgotPassword] = useState(false);
  const [successForm, setSuccesForm] = useState(false);
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [context, dispatch] = useAppContext();
  // API calls
  const postLoginCheckMutation = useMutation(postLoginCheck, {
    onSuccess: (data) => {
      localStorage.setItem('auth', JSON.stringify(data?.data));
    },
  });
  const postRequestResetPasswordMutation = useMutation(postRequestResetPassword, {
    onSuccess: () => {
      setSuccesForm(true);
    },
  });

  const handleCheckboxClick = (event) => {
    dispatch({ type: 'SET_IS_STAY_LOGIN_CHECK', payload: event?.target?.checked });
  };

  const getUserMeQuery = useQuery('get-user-me', getUserMe, {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: postLoginCheckMutation.isSuccess,
    onSuccess: (response) => {
      if (localStorage.getItem('url')) {
        handleCheckboxClick({ target: { checked: true } });
        window.location.href = localStorage.getItem('url');
        sessionStorage.removeItem('url');
      }
      dispatch({ type: 'SET_USER', payload: response?.data });
      if (response?.data?.role === 'ROLE_ADMIN') {
        navigate('/dashboard');
      } else if (response?.data?.role === 'ROLE_ADMIN_GROUP') {
        navigate('/dashboard');
      } else if (response?.data?.role === 'ROLE_SUPER_ADMIN') {
        navigate('/dashboard');
      } else if (response?.data?.role === 'ROLE_USER') {
        navigate('/choix-carnet');
        // if (response?.data?.permissions?.length <= 1 && response?.data?.permissions[0]?.bookletIds?.length <= 1) {
        //   dispatch({ type: 'CHOICE_BOOKLET', payload: response?.data?.permissions[0]?.bookletIds[0] });
        //   navigate('/choix-etablissement');
        // } else {
        // }
      }
      // if (response?.data?.role === 'ROLE_USER' && response?.data?.permissions?.length < 2) {
      //   dispatch({ type: 'CHOICE_BOOKLET', payload: response?.data?.permissions[0]?.bookletIds[0] });
      //   // dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: getEstablishmentQuery.data });
      //   navigate('/choix-etablissement');
      // }
    },
  });

  return (
    <div className={styles.page}>
      <Helmet>
        <title>
          {t('home.title')}
          {' '}
          -
          {' '}
          {t('global.site_name')}
        </title>
      </Helmet>
      <div className="container">
        <img
          className={styles.logo}
          src={LogoWhite}
          alt="Ecarnet"
          width="320"
          height="102"
        />
        <h2 className={styles.subtitle}>{t('home.subtitle')}</h2>
        <div className={styles.login}>
          {!forgotPassword ? (
            <>
              <h1 className={styles.title}>{t('home.title')}</h1>
              <QueryError error={postLoginCheckMutation.error} />
              <Formik
                initialValues={initialLoginValues}
                validationSchema={validationLoginSchema}
                onSubmit={(formLoginValues) => {
                  postLoginCheckMutation.mutate(formLoginValues);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.formGroup}>
                      <Input
                        id="email"
                        type="email"
                        name="email"
                        label={t('home.email')}
                        labelHidden
                        placeholder={t('home.email')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div className={styles.error}>
                        {t(errors.email) && t(touched.email) && t(errors.email)}
                      </div>
                    </div>
                    <div className={styles.formGroup}>
                      <Input
                        id="password"
                        type="password"
                        name="password"
                        label={t('home.password')}
                        labelHidden
                        placeholder={t('home.password')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <div className={styles.error}>
                        {t(errors.password) && t(touched.password) && t(errors.password)}
                      </div>
                    </div>
                    <div className={styles.formGroup}>
                      <Checkbox
                        id="stayLogged"
                        name="stayLogged"
                        label={t('home.stay_logged')}
                        checked={context.isStayLoginCheck}
                        onChange={handleCheckboxClick}
                        value={context.isStayLoginCheck}
                      />
                    </div>
                    <Button
                      type="submit"
                      label={t('home.login')}
                      className={cn([styles.submit, 'form_submit'])}
                      isLoading={postLoginCheckMutation.isLoading}
                    />
                  </form>
                )}
              </Formik>
              <div className={styles.forget}>
                <button
                  type="button"
                  className={styles.link}
                  onClick={() => { setForgotPassword(true); }}
                >
                  {t('home.forgot_password_link')}
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className={styles.title}>{t('home.forgot_password')}</h2>
              <QueryError error={postRequestResetPasswordMutation.error} />
              {successForm ? <InfoMessage type="success" label={t('home.success_send')} /> : null}
              <Formik
                initialValues={initialForgotPasswordValues}
                validationSchema={validationForgotPasswordSchema}
                onSubmit={(formForgotPasswordValues) => {
                  postRequestResetPasswordMutation.mutate(formForgotPasswordValues);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.formGroup}>
                      <Input
                        id="email"
                        type="email"
                        name="email"
                        label={t('home.email')}
                        labelHidden
                        placeholder={t('home.email')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div className={styles.error}>
                        {t(errors.email) && t(touched.email) && t(errors.email)}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      label={t('home.reset_password')}
                      className={cn([styles.submit, 'form_submit'])}
                      disabled={postLoginCheckMutation.isLoading}
                    />
                  </form>
                )}
              </Formik>
              <div className={styles.forget}>
                <button
                  type="button"
                  className={styles.link}
                  onClick={() => { setForgotPassword(false); }}
                >
                  {t('home.have_login')}
                </button>
              </div>
            </>
          )}
          <hr className={styles.divider} />
          <div className={styles.logosAlt}>
            <img
              className={styles.logo}
              src={LogoSecurite}
              alt="Ecarnet Sécurité"
              width="252"
              height="80"
            />
            <img
              className={styles.logo}
              src={LogoSanitaire}
              alt="Ecarnet Sanitaire"
              width="252"
              height="80"
            />
            <img
              className={styles.logo}
              src={LogoVehicule}
              alt="Ecarnet vehicule"
              width="252"
              height="80"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
