import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
// Components
import cn from '../../../../utils/cn';
import Input from '../../../atoms/Input/Input';
import UploadFile from '../../../atoms/UploadFile/UploadFile';
// import Textarea from '../../../atoms/Textarea/Textarea';
import Button from '../../../atoms/Button/Button';
import { useAppContext } from '../../../../store/AppContext';
import styles from './DateUploadNumber.module.css';

function DateUploadNumber({
  id, label, value, onChange,
}) {
  const { t } = useTranslation();

  const [pop, setPop] = useState();
  // const [remarque, setRemarque] = useState('');
  const [remarqueFinal, setRemarqueFinal] = useState();
  const EffectPop = useRef();
  const ColorRemarque = useRef();
  // const [RemarqueNull, setRemarqueNull] = useState();
  const [titlePop, setTitlePop] = useState();
  const [Nb, setNb] = useState();
  const [context] = useAppContext();
  const navigate = useNavigate();
  const handleFileChange = (newFile) => {
    onChange({ ...value, file: newFile });
  };

  const PopUp = (nb, min) => {
    setPop(true);
    // setRemarqueNull();
    let title;
    if (context.choiceBooklet !== 3) {
      title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancien [${min}]`;
    } else {
      title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancienne valeur [${min}]`;
    }
    setTitlePop(title);
    setNb(nb);
  };
  const PopEffect = () => {
    EffectPop.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValue = () => {
    setRemarqueFinal();
    setPop(false);
    // setRemarque('');
    document.getElementById(`number-${id}`).focus();
  };
  // const PopValid = (params) => {
  //   if (remarque !== '') {
  //     setRemarqueFinal(remarque);
  //     setPop(false);
  //   } else {
  //     EffectPop.current.style.transform = 'scale(1.01)';
  //     setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  //     // setRemarqueNull(true);
  //   }
  //   if (params === 'valid') {
  //     ColorRemarque.current.style.color = 'green';
  //   } else if (params === 'aide') {
  //     ColorRemarque.current.style.color = 'orange';
  //   }
  // };

  return (
    <div>
      <div className={cn(['inputForm', styles.height])}>
        <div className="inputForm_control inputForm_label" style={{ fontSize: '1.25rem' }}>
          {label}
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.date')}
        </div>
        <div className={cn(['inputForm_control inputForm_date'])}>
          <Input
            id={`date-${id}`}
            type="date"
            labelHidden
            value={value.date}
            onChange={(e) => onChange({ ...value, date: e.target.value })}
            required
            min="2000-01-01"
            max="2099-12-31"
          />
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.file')}
        </div>
        <div className={cn(['inputForm_control, inputForm_control--center', styles.UploadFile])}>
          <UploadFile
            id={`file-${id}`}
            labelHidden
            fileName={value?.file ? value.file.name : value.file}
            onChange={(file) => handleFileChange(file.currentTarget.files[0])}
            required
          />
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.km')}
        </div>
        <div style={{ color: 'grey' }}>
          {`Ancienne valeur : ${value.mileageIndex}`}
        </div>
        <div className="inputForm_control inputForm_input">
          <Input
            id={`number-${id}`}
            type="number"
            labelHidden
            value={value.number}
            onChange={(e) => onChange({ ...value, number: e.target.value })}
            onBlur={(e) => {
              if (e.target.value < value?.mileageIndex && e.target.value !== '') {
                PopUp(e.target.value, value?.mileageIndex);
              }
            }}
            min="0"
            required
          />
        </div>
      </div>
      {!remarqueFinal ? (
        <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
          <div className="inputForm_control inputForm_label">
            {'Commentaire de l\'alerte'}
          </div>
          <div className={styles.remarque} style={{ borderBottom: '0' }}>
            <p style={{ color: '#28337e' }}>{value.alertComment}</p>
          </div>
        </div>
      ) : null }
      <div style={{ display: remarqueFinal ? '' : 'none' }}>
        <div className={styles.remarque}>
          <p ref={ColorRemarque}>{remarqueFinal}</p>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => { PopUp(Nb, value?.mileageIndex); }}
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: pop ? '' : 'none' }} ref={EffectPop}>
        <h3 className={styles.title_popUp}>{titlePop}</h3>
        <div className={styles.div_button}>
          {value.lastInputId !== null ? (
            <Button
              type="button"
              label={t('alert.change_value_historique')}
              onClick={() => { navigate(`/saisies/historique/edit/${value.lastInputId}`); }}
              className={cn([styles.btn_popUp, styles.btn_blue])}
            />
          ) : null}
          <Button
            type="button"
            label={t('alert.change_value')}
            onClick={() => { ChangeValue(); }}
            className={cn([styles.btn_popUp, styles.btn_red])}
          />
        </div>
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: pop ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffect(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
}

DateUploadNumber.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  value: PropTypes.shape({
    date: PropTypes.string,
    number: PropTypes.number,
    file: PropTypes.string.isRequired,
    mileageIndex: PropTypes.number,
    lastInputId: PropTypes.number,
    alertComment: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

DateUploadNumber.defaultProps = {
};

export default DateUploadNumber;
