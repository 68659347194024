/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// Components
import Picto from '../../atoms/Picto/Picto';
import Divider from '../../atoms/Divider/Divider';
import List from '../List/List';
import ListItem from '../../atoms/ListItem/ListItem';
// Hooks
import useAppContext from '../../../store/useAppContext';

import styles from './Navigation.module.css';
import cn from '../../../utils/cn';
// utils
import {
  STRUCTURES, USERS, ESTABLISHMENTS, CONFIGURATION,
} from '../../../utils/constant';

function NavigationAdmin({ isCollapsed }) {
  // States
  const [accordionStructuresClosed, setAccordionStructuresClosed] = useState(false);
  const [accordionConfigurationClosed, setAccordionConfigurationClosed] = useState(false);
  const [accordionUsersClosed, setAccordionUsersClosed] = useState(false);
  const [accordionSanitaireClosed, setAccordionSanitaireClosed] = useState(false);
  const [accordionSecuriteClosed, setAccordionSecuriteClosed] = useState(false);
  // Hooks
  const { t } = useTranslation();
  const [, dispatch] = useAppContext();
  // const isEstablishmentChoosed = !!context?.choiceEstablishment;

  const configuration = [
    {
      link: CONFIGURATION.EQUIPMENTS_LINK,
      picto: CONFIGURATION.EQUIPMENTS_PICTO,
      label: 'navigation.equipment',
    },
    {
      link: CONFIGURATION.EQUIPMENTS_FAMILIES_LINK,
      picto: CONFIGURATION.EQUIPMENTS_FAMILIES_PICTO,
      label: 'navigation.equipment_family',
    },
    {
      link: CONFIGURATION.CONTROLS_LINK,
      picto: CONFIGURATION.CONTROLS_PICTO,
      label: 'navigation.control',
    },
    {
      link: CONFIGURATION.CONTROLS_TYPES_LINK,
      picto: CONFIGURATION.CONTROLS_TYPES_PICTO,
      label: 'navigation.control_types',
    },
    {
      link: CONFIGURATION.BUILDINGS_TEMPLATES_LINK,
      picto: CONFIGURATION.BUILDINGS_TEMPLATES_PICTO,
      label: 'navigation.buildings_templates',
    },
    {
      link: CONFIGURATION.CRON_LINK,
      picto: CONFIGURATION.CRON_PICTO,
      label: 'navigation.cron',
    },
  ];

  const structures = [
    {
      link: STRUCTURES.STRUCTURES_LINK,
      picto: STRUCTURES.STRUCTURES_PICTO,
      label: 'navigation.structures',
    },
    {
      link: ESTABLISHMENTS.ESTABLISHMENTS_LINK,
      picto: ESTABLISHMENTS.ESTABLISHMENTS_PICTO,
      label: 'navigation.establishments',
    },
  ];

  const users = [
    {
      link: USERS.USERS_LINK,
      picto: USERS.USERS_PICTO,
      label: 'navigation.users',
    },
  ];

  const SanitaireDoc = [
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.contracts',
    },
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.DepotSanitaire',
    },
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.infogen',
    },
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.reglemtation',
    },
  ];
  const SecuriteDoc = [
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.contracts',
    },
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.DepotSecurite',
    },
    {
      link: '/contracts/admin',
      picto: 'contrats',
      label: 'navigation.reglemtation',
    },
  ];

  return (
    <nav className={styles.navigation}>
      <List>
        <ListItem>
          <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/dashboard">
            <Picto className={styles.picto} name="home" />
            {!isCollapsed ? (
              <span className={styles.linkLabel}>{t('navigation.dashboardAdmin')}</span>
            ) : null}
          </Link>
        </ListItem>
        <Divider />
        <ListItem>
          <div
            role="button"
            className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
            onClick={() => { setAccordionConfigurationClosed(!accordionConfigurationClosed); }}
            aria-hidden="true"
          >
            <Picto className={styles.picto} name="configuration" />
            {!isCollapsed ? (
              <>
                <span className={styles.linkLabel}>{t('navigation.configuration')}</span>
                <FontAwesomeIcon
                  className={styles.linkPicto}
                  icon={accordionConfigurationClosed ? faChevronDown : faChevronRight}
                />
              </>
            ) : null}
          </div>
          {accordionConfigurationClosed ? (
            <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
              {configuration.map((item) => (
                <ListItem key={item.id} className={styles.subItem}>
                  <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                    <Picto className={styles.picto} name={item.picto} />
                    <span className={styles.linkLabel}>{t(item.label)}</span>
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : null}
        </ListItem>
        <ListItem>
          <div
            role="button"
            className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
            onClick={() => { setAccordionStructuresClosed(!accordionStructuresClosed); }}
            aria-hidden="true"
          >
            <Picto className={styles.picto} name="contratsBlanc" />
            {!isCollapsed ? (
              <>
                <span className={styles.linkLabel}>{t('navigation.structures')}</span>
                <FontAwesomeIcon
                  className={styles.linkPicto}
                  icon={accordionStructuresClosed ? faChevronDown : faChevronRight}
                />
              </>
            ) : null}
          </div>
          {accordionStructuresClosed ? (
            <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
              {structures.map((item) => (
                <ListItem key={item.id} className={styles.subItem}>
                  <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                    <Picto className={styles.picto} name={item.picto} />
                    <span className={styles.linkLabel}>{t(item.label)}</span>
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : null}
        </ListItem>
        <ListItem>
          <div
            role="button"
            className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
            onClick={() => { setAccordionUsersClosed(!accordionUsersClosed); }}
            aria-hidden="true"
          >
            <Picto className={styles.picto} name="usersWhite" />
            {!isCollapsed ? (
              <>
                <span className={styles.linkLabel}>{t('navigation.users')}</span>
                <FontAwesomeIcon
                  className={styles.linkPicto}
                  icon={accordionUsersClosed ? faChevronDown : faChevronRight}
                />
              </>
            ) : null}
          </div>
          {accordionUsersClosed ? (
            <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
              {users.map((item) => (
                <ListItem key={item.id} className={styles.subItem}>
                  <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                    <Picto className={styles.picto} name={item.picto} />
                    <span className={styles.linkLabel}>{t(item.label)}</span>
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : null}
        </ListItem>
        <ListItem>
          <div
            role="button"
            className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
            onClick={() => { setAccordionSecuriteClosed(!accordionSecuriteClosed); }}
            aria-hidden="true"
          >
            <Picto className={styles.picto} name="multifileBlanc" />
            {!isCollapsed ? (
              <>
                <span className={styles.linkLabel}>{t('navigation.DepotSecurite')}</span>
                <FontAwesomeIcon
                  className={styles.linkPicto}
                  icon={accordionSecuriteClosed ? faChevronDown : faChevronRight}
                />
              </>
            ) : null}
          </div>
          {accordionSecuriteClosed ? (
            <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
              {SecuriteDoc.map((item) => (
                <ListItem key={item.id} className={styles.subItem}>
                  <Link className={cn([styles.link, styles.subLink])} to={item.link} onClick={() => dispatch({ type: 'CHOICE_BOOKLET', payload: 2 })}>
                    <Picto className={styles.picto} name={item.picto} />
                    <span className={styles.linkLabel}>{t(item.label)}</span>
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : null}
        </ListItem>
        <ListItem>
          <div
            role="button"
            className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
            onClick={() => { setAccordionSanitaireClosed(!accordionSanitaireClosed); }}
            aria-hidden="true"
          >
            <Picto className={styles.picto} name="multifileBlanc" />
            {!isCollapsed ? (
              <>
                <span className={styles.linkLabel}>{t('navigation.DepotSanitaire')}</span>
                <FontAwesomeIcon
                  className={styles.linkPicto}
                  icon={accordionSanitaireClosed ? faChevronDown : faChevronRight}
                />
              </>
            ) : null}
          </div>
          {accordionSanitaireClosed ? (
            <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
              {SanitaireDoc.map((item) => (
                <ListItem key={item.id} className={styles.subItem}>
                  <Link className={cn([styles.link, styles.subLink])} to={item.link} onClick={() => dispatch({ type: 'CHOICE_BOOKLET', payload: 1 })}>
                    <Picto className={styles.picto} name={item.picto} />
                    <span className={styles.linkLabel}>{t(item.label)}</span>
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : null}
        </ListItem>
        <Divider />
      </List>
    </nav>
  );
}

export default NavigationAdmin;
