/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import { useQuery } from 'react-query';
import React from 'react';
import { format } from 'date-fns';
import {
  faChevronLeft, faFileArrowDown, faCircle, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRegistreList } from '../../services/registre';
import { getInputsToComplete } from '../../services/inputs';
import { getBuildingsList } from '../../services/structures';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import useAppContext from '../../store/useAppContext';
import styles from './Registre.module.css';
import Table from '../../components/molecules/Table/Table';
import cn from '../../utils/cn';
import Permissions from '../../components/molecules/Permissions/Permissions';
import { TRAINING_NAMES, TRAINING_TYPES } from '../../utils/constant';

function Registre() {
  // const [context] = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [buildingId, setBuildingId] = React.useState(context?.choiceEstablishment?.buildingIds[0]);
  const [Registres, setRegistres] = React.useState('');

  const getRegistreListQuery = useQuery(['RegistreList'], () => getRegistreList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    onSuccess: (data) => {
      setRegistres(data);
      console.log('test', data?.data);
    },
  });
  const getInputsToCompleteQuery = useQuery(['inputs', buildingId], () => getInputsToComplete({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }));
  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => data?.data?.buildings?.length > 0 && setBuildingId(data?.data?.buildings[0]?.id),
  });
  const handleBuilding = (id) => {
    setBuildingId(id);
  };
  const docfile = (id, data) => {
    window.open(`http://api-ecarnet.dev.zol.fr/reports/download-report/${id}/${data}`, '_blank');
  };

  const commission = React.useMemo(
    () => [
      {
        Header: t('registre.commisiondesecu'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report.societyInChargeName',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.resquise'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.prescRestantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    // if (window.innerWidth < 768) {
                    //   navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    // } else {
                    navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    // }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const organisme = React.useMemo(
    () => [
      {
        Header: t('registre.RapportOrganismes'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report.societyInChargeName',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const technicien = React.useMemo(
    () => [
      {
        Header: t('registre.techie'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report.societyInChargeName',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const formation = React.useMemo(
    () => [
      {
        Header: `${t('trainings.date_title')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          format(new Date(date), 'dd/MM/yyyy')
        ),
      },
      {
        Header: `${t('trainings.company')}`,
        accessor: 'society',
        Cell: ({
          row: {
            original: {
              speakerName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? speakerName || t('add_training.house_keeping')
            : '-'}`
        ),
      },
      {
        Header: `${t('trainings.fullname')}`,
        accessor: 'fullname',
        Cell: ({
          row: {
            original: {
              firstName, lastName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? '-'
            : `${firstName} ${lastName}`}`
        ),
      },
      {
        Header: `${t('trainings.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          `${type === TRAINING_TYPES?.FIRE_SAFETY_PREVENTION
            ? t('add_training.fire_safety_prevention')
            : t('add_training.maintenance_manager')}`
        ),
      },
      {
        Header: `${t('trainings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          `${name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS ? t('add_training.handling_emergency_means') : ''}
          ${name === TRAINING_NAMES.EVACUATION ? t('add_training.evacuation') : ''}
          ${name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION
            ? t('add_training.designated_employees_evacuation')
            : ''
          }
          ${name === TRAINING_NAMES.UNANNOUNCED_EXERCISE ? t('add_training.unannounced_exercise') : ''}
          ${name === TRAINING_NAMES.SSI ? t('add_training.ssi') : ''}
          ${name === TRAINING_NAMES.INTERNAL ? t('add_training.internal') : ''}
          ${name === TRAINING_NAMES.SSIAP ? t('add_training.ssiap') : ''}
          ${name === TRAINING_NAMES.SST ? t('add_training.sst') : ''}
          ${name === TRAINING_NAMES.ELECTRICAL_CLEARANCE ? t('add_training.electrical_clearance') : ''}`
        ),
      },
      {
        Header: `${t('trainings.headcount')}`,
        accessor: 'headcount',
      },
      {
        Header: `${t('trainings.person_in_charge')}`,
        accessor: 'personInCharge',
      },
      {
        Header: `${t('trainings.document')}`,
        accessor: 'documentFile',
        Cell: ({ row: { original: { documentFile, documentFileName, id } } }) => (
          documentFile !== null && (
            <Button
              type="button"
              className="action"
              label={<FontAwesomeIcon icon={faFileArrowDown} />}
              title={t('trainings.download')}
              onClick={() => docfile(id, documentFileName)}
            />
          )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/formations/edit/${row?.original?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
  );
  const regie = React.useMemo(
    () => [
      {
        Header: t('registre.regieName'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report?.societyInChargeName',
      },
      {
        Header: t('registre.interventioninterneDate'),
        accessor: 'report?.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <Layout
      title="Registre"
      layout="table"
      queryError={
        getInputsToCompleteQuery?.error
        || getBuildingsQuery?.error
        || getRegistreListQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">{t('registre.title')}</h1>
        </div>
      </header>
      <div className={styles.filters}>
        <div className={styles.filtersToComplete}>
          {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
            <Button
              key={building.id}
              type="button"
              label={building.name}
              className={cn([styles.filter, buildingId === building.id
                ? styles.active
                : '', 'shadow-md'])}
              onClick={() => handleBuilding(building.id)}
            />
          ))}
        </div>
      </div>
      <div className={styles.shadow}>
        {Registres?.data?.safetyCommittee.length >= 1
          ? (
            <div className={styles.table}>
              <Table
                columns={commission}
                isLoading={getRegistreListQuery.isLoading}
                data={Registres?.data?.safetyCommittee || []}
                hasSort
              />
            </div>
          ) : null }
        <div className={styles.table}>
          <Table
            columns={organisme}
            isLoading={getRegistreListQuery.isLoading}
            data={Registres?.data?.approvedBody || []}
            hasSort
          />
        </div>
        <div className={styles.table}>
          <Table
            columns={technicien}
            isLoading={getRegistreListQuery.isLoading}
            data={Registres?.data?.qualifiedTechnician || []}
            hasSort
          />
        </div>
        <div className={styles.tableFormations}>
          <Table
            columns={formation}
            isLoading={getRegistreListQuery.isLoading}
            data={Registres?.data?.training || []}
            hasSort
          />
        </div>
        {Registres?.data?.internalManagement?.length >= 1
          ? (
            <div className={styles.tableRegie}>
              <Table
                columns={regie}
                isLoading={getRegistreListQuery.isLoading}
                data={Registres?.data?.internalManagement || []}
                hasSort
              />
            </div>
          ) : null }
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Registre;
