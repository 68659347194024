/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import { getControlTypesList, deleteControlType } from '../../services/controlTypes';
// Utils
import styles from './ControlTypes.module.css';
import { CONTROL_PERIOD, VISIBILITY } from '../../utils/constant';
import useAppContext from '../../store/useAppContext';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function ControlTypes() {
  // Hooks
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  // States
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [context] = useAppContext();
  const [controlTypeToDelete, setControlTypeToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  // API Calls
  const getControlTypesListQuery = useQuery(['control-types', page, search], () => getControlTypesList(
    {
      page: 0,
      search,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    },
  ));

  const deleteControlTypeMutation = useMutation(deleteControlType, {
    onSuccess:
      () => {
        getControlTypesListQuery.refetch();
      },
  });

  const handleModal = (selectedControlTypeToDelete) => {
    setOpenModal(true);
    setControlTypeToDelete(selectedControlTypeToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteControlTypeMutation.mutate(controlTypeToDelete.id);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('control_types.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('control_types.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('control_types.control_period')}`,
        accessor: 'controlPeriod',
        Cell: ({ row: { original: { controlPeriod } } }) => (
          <>
            <span>{controlPeriod === CONTROL_PERIOD.MONDAY_BEFORE ? t('control_types.monday_before') : null}</span>
            <span>{controlPeriod === CONTROL_PERIOD.CONTROL_DATE ? t('control_types.control_date') : null}</span>
            <span>{controlPeriod === CONTROL_PERIOD.PERMANENTLY ? t('control_types.permanently') : null}</span>
          </>
        ),
      },
      {
        Header: `${t('control_types.visibility')}`,
        accessor: 'visibility',
        Cell: ({ row: { original: { visibility } } }) => (
          <>
            <span>{visibility === VISIBILITY.UNIQUE ? t('control_types.unique') : null}</span>
            <span>{visibility === VISIBILITY.ONE_WEEK ? t('control_types.one_week') : null}</span>
            <span>{visibility === VISIBILITY.WHOLE_CYCLE ? t('control_types.whole_cycle') : null}</span>
            <span>{visibility === VISIBILITY.PERMANENTLY ? t('control_types.permanently') : null}</span>
          </>
        ),
      },
      {
        Header: `${t('control_types.display_order')}`,
        accessor: 'displayOrder',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/controles-types/edit/${id}`}
              title={t('control_types.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('control_types.suppr')}
              onClick={() => handleModal({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('control_types.title')}
        queryError={
          getControlTypesListQuery?.error
          || deleteControlTypeMutation?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('control_types.title')}</h1>
            <Link className="add" to="/controles-types/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_abel">{t('control_types.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getControlTypesListQuery.isLoading}
            data={getControlTypesListQuery?.data?.data?.controlTypes || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getControlTypesListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <button type="button" className="link" onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default ControlTypes;
