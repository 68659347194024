import React from 'react';
import {
  useParams, useNavigate,
} from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { omit, uniqWith, isEqual } from 'lodash';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import SelectForm from '../../components/atoms/Select/Select';
import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';

// services
import {
  editEstablishmentInformations,
  getEstablishment,
  getStructuresList,
  postEstablishmentInformations,
} from '../../services/structures';

// utils & misc
import { STRUCTURES_NAME } from '../../utils/constant';
import BuildingsList from './BuildingsList/BuildingsList';
import useAppContext from '../../store/useAppContext';

const initialValues = {
  name: '',
  parentId: null,
  address: '',
  postalCode: '',
  city: '',
  code: '',
  phone: '',
  siret: '',
  email: '',
  workforce: 0,
  trainingsPerYear: '0.0',
  numberOfResidents: 0,
};
const InputsMapper = [
  {
    key: 'name', type: 'text', labelKey: 'add_establishment.input_name', required: true,
  },
  {
    key: 'parentId', type: 'select', labelKey: 'add_establishment.input_parentId', required: false,
  },
  {
    key: 'address', type: 'text', labelKey: 'add_establishment.input_address', required: true,
  },
  {
    key: 'postalCode', type: 'number', labelKey: 'add_establishment.input_postalCode', required: true,
  },
  {
    key: 'city', type: 'text', labelKey: 'add_establishment.input_city', required: true,
  },
  {
    key: 'code', type: 'text', labelKey: 'add_establishment.input_code', required: false,
  },
  {
    key: 'phone', type: 'text', labelKey: 'add_establishment.input_phone', required: true,
  },
  {
    key: 'siret', type: 'text', labelKey: 'add_establishment.input_siret', required: false,
  },
  {
    key: 'email', type: 'text', labelKey: 'add_establishment.input_email', required: true,
  },
  {
    key: 'workforce', type: 'number', labelKey: 'add_establishment.input_workforce', required: false,
  },
  {
    key: 'trainingsPerYear',
    type: 'select',
    labelKey: 'add_establishment.input_training_per_year',
    options: [{
      value: '0.0',
      label: '0',
    }, {
      value: '0.5',
      label: '0,5',
    }, {
      value: '1.0',
      label: '1',
    }, {
      value: '2.0',
      label: '2',
    }],
    required: true,
  },
  {
    key: 'numberOfResidents', type: 'number', labelKey: 'add_establishment.input_number_of_residents', required: true,
  },
];

function AddEstablishment() {
  const { t } = useTranslation();
  const urlParams = useParams();
  const isEditing = urlParams.action === 'edit';
  const isViewing = urlParams.action === 'view';
  const isCreating = !isEditing && !isViewing;
  const [context] = useAppContext();

  const navigate = useNavigate();

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const postEstablishmentMutation = useMutation(postEstablishmentInformations, {
    onSuccess: () => goBackUrl(t('add_establishment.added')),
  });
  const editEstablishmentMutation = useMutation(editEstablishmentInformations, {
    onSuccess: () => goBackUrl(t('add_establishment.edited')),
  });

  const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('global.required_field').typeError(t('global.wrong_type')),
    parentId: Yup.number().nullable().typeError(t('global.wrong_type')),
    address: Yup.string().nullable().required('global.required_field').typeError(t('global.wrong_type')),
    postalCode: Yup.number().required('global.required_field').typeError(t('global.wrong_type')),
    city: Yup.string().nullable().required('global.required_field').typeError(t('global.wrong_type')),
    code: Yup.string().nullable().typeError(t('global.wrong_type')),
    siret: Yup.string().nullable().typeError(t('global.wrong_type')),
    phone: Yup.string().nullable().required('global.required_field').typeError(t('global.wrong_type'))
      .matches(phoneRegExp, 'global.invalid_phone_number')
      .min(10, 'global.too_short')
      .max(10, 'global.too_long'),
    email: Yup.string().nullable().required('global.required_field').typeError(t('global.wrong_type')),
    workforce: Yup.number().nullable().typeError(t('global.wrong_type')),
    trainingsPerYear: Yup.string().nullable().required('global.required_field').typeError(t('global.wrong_type')),
    numberOfResidents: Yup.number().nullable()
      .positive('global.positive_field')
      .required('global.required_field')
      .typeError(t('global.wrong_type')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (isEditing) {
        editEstablishmentMutation.mutate(formValues);
      } else {
        postEstablishmentMutation.mutate(formValues);
      }
    },
  });

  const getAllStructuresQuery = useQuery('structures', () => getStructuresList({
    page: undefined,
    search: undefined,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const allStructuresOptions = React.useMemo(() => (
    uniqWith(getAllStructuresQuery?.data?.data?.structures.map((structure) => (
      {
        value: structure.id,
        label: `
        ${structure.name} - 
        ${structure.type === STRUCTURES_NAME.GROUP ? t('structures.group') : ''} 
        ${structure.type === STRUCTURES_NAME.SUBSIDIARY ? t('structures.subsidiary') : ''} 
        ${structure.type === STRUCTURES_NAME.DIVISION ? t('structures.division') : ''} 
        ${structure.groupName !== null ? `("${t('structures.group')}" - ${structure.groupName})` : ''}`,
      }
    )).concat({ value: null, label: t('global.no_parent') }), isEqual)
  ));

  const getEstablishmentQuery = useQuery('establishment', () => getEstablishment(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues({
        ...omit(
          {
            ...data.data,
            parentId: data.data.divisionId ?? data.data.subsidiaryId ?? data.data.groupId,
          },
          'establishment',
        ),
      });
    },
  });

  return (
    <Layout
      title={t('add_establishment.title')}
      queryError={
        getEstablishmentQuery?.error
          || editEstablishmentMutation?.error
          || postEstablishmentMutation?.error
          || getAllStructuresQuery?.error
        }
    >
      <div>
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_establishment.back_to_establishment')}</span>
          </button>
        </div>
        <header>
          <h1 className="title">
            {isCreating ? t('add_establishment.title') : null}
            {isEditing ? t('add_establishment.edit_title') : null}
            {isViewing ? t('add_establishment.view_title') : null}
          </h1>
        </header>
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          {InputsMapper.map((inputObject) => {
            if (inputObject.type === 'text' || inputObject.type === 'number') {
              return (
                <div key={inputObject.key} className="form_group">
                  <Input
                    id={inputObject.key}
                    type={inputObject.type}
                    name={inputObject.key}
                    label={t(inputObject.labelKey)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[inputObject.key]}
                    disabled={isViewing}
                  />
                  {formik.errors[inputObject.key] && formik.touched[inputObject.key] ? (
                    <div className="error">
                      {t(formik.errors[inputObject.key])}
                    </div>
                  ) : null }
                </div>
              );
            }
            const isParentId = inputObject.key === 'parentId';
            const selectValue = isParentId
              ? allStructuresOptions.find((option) => option.value === formik.values[inputObject.key])
              : inputObject.options.find((option) => option.value === formik.values[inputObject.key]);
            return (
              <div key={inputObject.key} className="form_group">
                <SelectForm
                  id={inputObject.key}
                  label={t(inputObject.labelKey)}
                  options={isParentId ? allStructuresOptions : inputObject.options}
                  value={selectValue}
                  onChange={(option) => formik.setFieldValue(inputObject.key, option.value)}
                  disabled={isViewing}
                  required={getAllStructuresQuery.required}
                />
                {formik.errors[inputObject.key] && formik.touched[inputObject.key] ? (
                  <div className="error">
                    {t(formik.errors[inputObject.key])}
                  </div>
                ) : null }
              </div>
            );
          })}
          {urlParams.id && <BuildingsList establishmentId={urlParams.id} isViewing={!!isViewing} />}
          {urlParams?.action === 'edit' ? (
            <div className="form_subfooter">
              <div>
                {t('add_user.created_at')}
                {' '}
                {getEstablishmentQuery?.data?.data?.createdAt ? (
                  format(new Date(`${getEstablishmentQuery?.data?.data?.createdAt}`), 'dd/MM/yyyy à HH:mm')
                ) : null}
                {getEstablishmentQuery?.data?.data?.createdBy ? (
                  ` ${t('global.by')} ${getEstablishmentQuery?.data?.data?.createdBy}`
                ) : null}
              </div>
              {getEstablishmentQuery?.data?.data?.createdAt !== getEstablishmentQuery?.data?.data?.updatedAt ? (
                <div>
                  {t('add_user.updated_at')}
                  {' '}
                  {getEstablishmentQuery?.data?.data?.updatedAt ? (
                    format(new Date(`${getEstablishmentQuery?.data?.data?.updatedAt}`), 'dd/MM/yyyy à HH:mm')
                  ) : null}
                  {getEstablishmentQuery?.data?.data?.updatedBy ? (
                    ` ${t('global.by')} ${getEstablishmentQuery?.data?.data?.updatedBy}`
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null }
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('add_structure.mandatory_fields')}</small>
            </div>
            {!isViewing && (
            <Button
              type="submit"
              className="form_submit"
              label={isEditing ? t('add_establishment.save') : t('add_establishment.create')}
              isLoading={isEditing ? editEstablishmentMutation.isLoading : postEstablishmentMutation.isLoading}
            />
            )}
          </div>
        </form>
        <footer className="footer">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_establishment.back_to_establishment')}</span>
          </button>
        </footer>
      </div>
    </Layout>
  );
}

AddEstablishment.propTypes = {};

export default AddEstablishment;
