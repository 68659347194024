/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ToastContainer } from 'react-toastify';
import {
  AppProvider,
  AppStateContext,
} from './store/AppContext';
// Routes
import { publicRoutes, privateRoutes } from './routes';
// Utils
import 'react-toastify/dist/ReactToastify.min.css';

// React Query Client config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  // const navigate = useNavigate();
  const onErrorHandler = (error) => {
    sessionStorage.setItem('error', JSON.stringify(error));
    sessionStorage.setItem('errorPath', window.location.pathname);
    // alert(error);
    // window.location.href = '/error/';
  };

  React.useEffect(() => {
    window.onerror = onErrorHandler;
    return () => {
      window.onerror = null;
    };
  }, []);
  return (
    <>
      <ToastContainer position="top-center" />
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <AppStateContext.Consumer>
            {(context) => (
              <BrowserRouter>
                <Routes>
                  {[...context.user ? privateRoutes : publicRoutes]
                    .map((route) => (!route.avalaibleToRole || route?.avalaibleToRole
                      .includes(context?.user?.role) ? (
                        <Route key={route.path} {...route} />
                      ) : null)) }
                  {(context?.user?.role?.includes('ADMIN')) ? (
                    <Route
                      path="*"
                      element={<Navigate to={context.user ? '/Error404' : '/'} replace />}
                    />
                  ) : (
                    <Route
                      path="*"
                      element={<Navigate to={context.user ? '/Error404' : '/'} replace />}
                    />
                  )}
                </Routes>
              </BrowserRouter>
            )}
          </AppStateContext.Consumer>
        </AppProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
