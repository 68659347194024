/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  // faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getEstablishmentsList } from '../../services/structures';
import Input from '../../components/atoms/Input/Input';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
import useAppContext from '../../store/useAppContext';
import { postDocumentBaseItem, getDocumentBaseItemCategory } from '../../services/docBase';
import SelectMulti from '../../components/atoms/SelectMultiStructure/selectMultiStructure';
import Layout from '../../components/template/Layout';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';

function DepotDoc() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useAppContext();
  const [Check, setCheck] = useState(true);

  const initialValues = {
    typeId: '',
    categoryId: '',
    buildingId: '',
    name: '',
    vehicles: '',
    date: '',
    documentFile: '',
    structureIds: [],
  };

  const goBack = (message) => {
    toast.success(message);
    navigate(-1);
  };

  const getEstablishments = useQuery(['establishmentsList'], () => getEstablishmentsList({
    parentid: 799,
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }));

  const postDocumentBaseItemMutation = useMutation(postDocumentBaseItem, {
    onSuccess: () => {
      goBack(t('documentary.succesAdd'));
    },
  });

  const validationSchema = Yup.object().shape({
    typeId: Yup.string(),
    name: Yup.string(),
    categoryId: Yup.string(),
    buildingId: Yup.string(),
    buildings: Yup.string(),
    date: Yup.string(),
    structureIds: Yup.mixed(),
    documentFile: Yup.mixed().nullable().typeError(t('global.wrong_type')).required('global.required_field')
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg')),
  });

  const getCategorieQuery = useQuery(['categorie'], () => getDocumentBaseItemCategory({
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      values.structureIds.forEach((item, index) => {
        formData.append(`structureIds[${index}]`, item);
      });
      formData.append('name', values.name);
      formData.append('documentFile', values.documentFile);
      formData.append('date', values.date);
      formData.append('buldingId', values.buildingId);
      formData.append('categoryId', values.typeId);
      formData.append('typeId', values.categoryId);
      postDocumentBaseItemMutation.mutate(values);
    },
  });

  const handleCheckboxClickAll = (fieldName, isChecked) => {
    formik.setFieldValue(
      fieldName,
      isChecked
        ? formik.values[fieldName].concat(getEstablishments?.data?.data?.establishments?.map((item) => item.id))
        : [],
    );
    setCheck(!isChecked);
  };

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);

  return (
    <Layout title="Depot de document">
      <div>
        <header>
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('docVehicule.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('docVehicule.add')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Checkbox
            id="structureIds[all]"
            name="structureIds[all]"
            label={t('contract.all_structureIds')}
            onChange={(event) => {
              handleCheckboxClickAll('structureIds', event.target.checked);
            }}
            onBlur={formik.handleBlur}
            checked={Check === false}
            value={formik.values.structureIds}
          />
        </div>
        { Check === true ? (
          <div className="form_group">
            <SelectMulti
              name="structureIds"
              onChange={(envent) => {
                formik.setFieldValue('structureIds', envent.map((item) => item.value));
              }}
              value={mapForSelect(getEstablishments?.data?.data?.establishments)?.filter(
                (item) => formik.values.structureIds?.includes(item.value),
              )}
              valueInput={formik.values.structureIds}
              required={formik.values.structureIds.length === 0}
              loading={getEstablishments.isLoading}
            />
          </div>
        ) : (
          <div className="form_group">
            {' '}
          </div>
        )}
        <div className="form_group">
          <Select
            label={t('vehicule.typedoc')}
            id="type"
            name="typeId"
            type="text"
            placeholder={t('vehicule.typedoc')}
            onChange={(value) => {
              formik.setFieldValue('typeId', value.value);
              formik.setFieldValue('categoryId', value.documentBaseItemTypes[0].id);
            }}
            onBlur={formik.handleBlur}
            value={mapForSelect(getCategorieQuery?.data?.data?.documentBaseItemCategories).find((option) => option.value === formik.values.typeId)}
            options={mapForSelect(getCategorieQuery?.data?.data?.documentBaseItemCategories)}
            required
            valueInput={formik.values.typeId}
            loading={getCategorieQuery.isLoading}
          />
          {formik.touched.type && formik.errors.type ? (
            <div className="error">{formik.errors.type}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.titreDoc')}
            id="name"
            name="name"
            type="text"
            required
            placeholder={t('vehicule.titreDoc')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('docVehicule.date')}
            id="date"
            name="date"
            type="date"
            required
            placeholder={t('docVehicule.date')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.date}
          />
          {formik.touched.date && formik.errors.date ? (
            <div className="error">{formik.errors.date}</div>
          ) : null}
        </div>
        <div className="form_group">
          <div className="label">{t('add_training.document_file')}</div>
          <UploadFile
            id="documentFile"
            name="documentFile"
            fileName={formik.values.documentFile ? formik.values.documentFile.name : formik.values.documentFileFileName}
            onChange={(event) => { formik.setFieldValue('documentFile', event.target.files[0]); }}
            onBlur={formik.handleBlur}
          />
          {formik?.errors?.documentFile && formik?.touched?.documentFile ? (
            <div className="error">
              {t(formik?.errors?.documentFile)}
            </div>
          ) : null }
        </div>
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('docVehicule.add')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('docVehicule.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default DepotDoc;
