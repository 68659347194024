/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  faEye,
  faChevronLeft,
  faEnvelopeOpenText,
  faClose,
  faChevronRight,
  faTrashCan,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import { GetNotificationAllList, GetNotification, DeleteNotification } from '../../services/notification';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './NotificationDashbord.module.css';
import Search from '../../components/molecules/Search/Search';
import useAppContext from '../../store/useAppContext';

function NotificationAdminList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [responses, setResponses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [info, setInfo] = useState([]);
  const [seachResponse, setSeachResponse] = useState([]);
  const [searchPad, setSearchPad] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [supprimer, setSupprimer] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [context] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const GetNotificationAllLis = useQuery(['notificationList', page], () => GetNotificationAllList({
    page,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: '100vh',
      height: '80vh',
      right: 'auto',
      bottom: 'auto',
      borderRadius: '1rem',
      boxShadow: '0.1rem 0.1rem white',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '80vw',
      minWidth: '1024px',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const supp = useMutation(DeleteNotification, {
    onSuccess: () => {
      setOpenModalDelete(false);
      GetNotificationAllLis.refetch();
      toast.success(t('notificationAdminList.notificationDelete'));
    },
  });

  const read = useMutation(GetNotification, {
    onSuccess: (data) => {
      setInfo(data);
      setStart(0);
      setEnd(10);
      setResponses(data?.data?.usersConcerned?.slice(start, end));
    },
  });
  const open = (value) => {
    read.mutate(value);
    setOpenModal(true);
  };
  // bar de recherche de la popup
  const accountNotifsurbar = (value) => {
    const filteredData = info?.data?.usersConcerned.filter((item) => {
      const itemData = `${item?.firstName?.toLowerCase()} || ${item?.lastName?.toLowerCase()}`;
      const textData = value.toLowerCase();
      return itemData.indexOf(textData) > -1;
    });
    setSeachResponse(filteredData);
    setStart(0);
    setEnd(10);
    setSearchPad(filteredData.slice(start, end));
  };
  const handleSearch = debounce((e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearch(e.target.value.toLowerCase());
    accountNotifsurbar(e.target.value.toLowerCase());
  }, 500);

  // bar de recherche de la page principale
  const principalsurbar = (value) => {
    if (value !== '') {
      const filteredData = GetNotificationAllLis?.data?.data?.notifications?.filter((item) => {
        const itemData = `${item?.name?.toLowerCase()}` && `${item?.title?.toLowerCase()}`;
        const textData = value.toLowerCase();
        return itemData.indexOf(textData) > -1;
      });
      GetNotificationAllLis.data.data.notifications = filteredData;
    } else {
      GetNotificationAllLis.refetch();
    }
  };
  const firstSearch = debounce((e) => {
    e.preventDefault();
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
    principalsurbar(e.target.value.toLowerCase());
  }, 500);
  const handleDelete = (value) => {
    setSupprimer(value);
    setOpenModalDelete(true);
  };

  // pagination de la popup
  const next = () => {
    setCurrentPage(currentPage + 1);
    if (search === '' && seachResponse.length === 0) {
      setResponses(info?.data?.usersConcerned?.slice(currentPage * 10, currentPage * 10 + 10));
    } else {
      setSearchPad(seachResponse.slice(currentPage * 10, currentPage * 10 + 10));
      setStart(currentPage * 10);
      setEnd(currentPage * 10 + 10);
    }
  };
  const previous = () => {
    setCurrentPage(currentPage - 1);
    if (search === '' && seachResponse.length === 0) {
      setResponses(info?.data?.usersConcerned?.slice(currentPage * 10 - 10 - 10, currentPage * 10 - 10));
    } else {
      setSearchPad(seachResponse.slice(currentPage * 10 - 10 - 10, currentPage * 10 - 10));
      setStart(currentPage * 10 - 10 - 10);
      setEnd(currentPage * 10 - 10);
    }
  };
  const changePage = (e) => {
    setCurrentPage(e);
    if (search === '' && seachResponse.length === 0) {
      setStart((e - 1) * 10);
      setEnd(e * 10 + 10);
      setResponses(info?.data?.usersConcerned?.slice(e * 10, e * 10 + 10));
    } else {
      setStart((e - 1) * 10);
      setEnd(e * 10);
      setSearchPad(seachResponse.slice((e - 1) * 10, e * 10));
    }
  };
  // fin de la pagination de la popup

  const collum = useMemo(
    () => [
      {
        Header: t('notification.name'),
        accessor: 'name',
      },
      {
        Header: t('notification.titre'),
        accessor: 'title',
      },
      {
        Header: t('notification.message'),
        accessor: 'message',
        Cell: ({ row }) => (
          <div>
            {row.original.message.length > 25 ? (
              <div>
                {row.original.message.substring(0, 25)}
                ...
              </div>
            ) : (
              <div>{row.original.message}</div>
            )}
          </div>
        ),
      },
      {
        Header: t('notification.dateStart'),
        accessor: 'beginAt',
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleDateString();
        },
      },
      {
        Header: t('notification.dateEnd'),
        accessor: 'endAt',
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleDateString();
        },
      },
      {
        Header: t('notification.nbrConsultation'),
        accessor: 'countUsers',
      },
      {
        Header: t('notification.action'),
        accessor: 'id',
        Cell: ({ value }) => (
          <div className="actions">
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('interventions.suppr')}
              onClick={() => handleDelete(value)}
            />
            <Button
              type="button"
              className="action"
              onClick={() => open(value)}
              label={<FontAwesomeIcon icon={faEye} />}
            />
          </div>
        ),
      },
    ],
    [],
  );
  const collum2 = useMemo(
    () => [
      {
        Header: t('notification.name'),
        accessor: 'firstName',
        Cell: ({ row }) => (
          <div>
            {row.original.firstName}
            {' '}
            {row.original.lastName}
          </div>
        ),
      },
      {
        Header: t('notification.readAt'),
        accessor: 'readAt',
        Cell: ({ value }) => {
          if (value) {
            const date = new Date(value);
            return date.toLocaleDateString();
          }
          return '-';
        },
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('notification.title')}
        description={t('notification.description')}
        queryError={
          GetNotificationAllLis?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('notification.allNotifications')}</h1>
            <Link className="add col-3" to="/addNotification">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('notification.add')}</span>
            </Link>
          </div>
        </header>
        <div className={styles.table}>
          <div className="row">
            <Search
              onChange={firstSearch}
            />
          </div>
          <br />
          <Table
            columns={collum}
            isLoading={GetNotificationAllLis?.isLoading}
            data={GetNotificationAllLis?.data?.data?.notifications || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
          )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
          )}
                onPageChange={onPageChange}
                totalCount={GetNotificationAllLis?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={GetNotificationAllLis?.data?.data?.length || 0}
              />
      )}
          />
        </div>
        <footer className="footer">
          <button type="button" className="link" onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <h2 className={styles.header}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
            {' '}
            {t('notification.message')}
          </h2>
          <div className={styles.buttonhaut}>
            <Button
              type="button"
              className={styles.clos}
              label={<FontAwesomeIcon icon={faClose} />}
              onClick={() => setOpenModal(false)}
            />
          </div>
          <hr />
          <div className={styles.titre}>
            <div>{info?.title}</div>
          </div>
          <p>
            {info?.data?.readingRequired ? (
              <div className={styles.required}>
                {t('notification.readingRequired')}
              </div>
            ) : (
              <div className={styles.norequired}>
                {t('notification.readingNotRequired')}
              </div>
            )}
          </p>
          <div className="container">
            <div className="row">
              <div className={styles.date}>
                {t('notification.beginAt')}
                {info?.data?.beginAt && (
                  <div>
                    {new Date(info?.data?.beginAt).toLocaleDateString()}
                  </div>
                )}
              </div>
              <div className={styles.date}>
                {t('notification.endAt')}
                {info?.data?.endAt && (
                  <div className={styles.date}>
                    {new Date(info?.data.endAt).toLocaleDateString()}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.text}>
            <p className={styles.message}>
              <pre>
                {info?.data?.message}
              </pre>
            </p>
          </div>
          <div className={styles.tables}>
            <div className="row">
              <Search
                onChange={handleSearch}
              />
            </div>
            <Table
              columns={collum2}
              isLoading={responses?.isLoading}
              data={search ? searchPad : responses || []}
              hasSort
              pagination={(
                <Pagination
                  previousLabel={(
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      onClick={() => { previous(); }}
                    />
            )}
                  nextLabel={(
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      onClick={() => {
                        next();
                      }}
                    />
            )}
                  onPageChange={(e) => {
                    changePage(e);
                  }}
                  currentPage={currentPage}
                  totalCount={search ? seachResponse.length : info?.data?.usersConcerned?.length || 0}
                  pageSize={10}
                  numberElementsDisplayed={search ? seachResponse.length : info?.data?.usersConcerned?.total || 0}
                />
        )}
            />
          </div>
          <div className={styles.button}>
            <Button
              type="button"
              className={styles.close}
              label={<FontAwesomeIcon icon={faClose} />}
              onClick={() => setOpenModal(false)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openModalDelete}
        onRequestClose={() => setOpenModalDelete(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModalDelete(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => supp.mutate(supprimer)}
          />
        </div>
      </Modal>
    </>
  );
}
export default NotificationAdminList;
