/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  // faFileArrowDown,
  faTrashCan,
  faChevronRight,
  faPlus,
  faEdit,
  // faFile,
  // faFileContract,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { format } from 'date-fns';
import Modal from 'react-modal';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Search from '../../components/molecules/Search/Search';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Pagination from '../../components/molecules/Pagination';
import Button from '../../components/atoms/Button/Button';
import { deleteFolder, getFolderlist } from '../../services/dossier';
import Picto from '../../components/atoms/Picto/Picto';
import useAppContext from '../../store/useAppContext';
import styles from './DossierBase.module.css';
import { getDocumentwithid } from '../../services/docBase';

function DossierBase() {
  const [context] = useAppContext();
  const urlParams = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [openModal, setOpenModal] = useState(false);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [search, setSearch] = useState('');
  const title = 'Titre dossier';

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const GetFolderList = useQuery(['contractlist', page, search], () => getFolderlist({
    structureId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    categoryId: urlParams.categoryid,
    search,
    page: 0,
  }));

  const getDocumentwithidQuery = useQuery(['getDocumentwithid'], () => getDocumentwithid({
    id: urlParams.categoryid,
    data: {
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    },
  }));

  const handleModal = (id) => {
    setOpenModal(true);
    setsupplierToDelete(id);
  };
  const folderDelete = useMutation(deleteFolder, {
    onSuccess: () => {
      toast.success(t('dossierBase.delete_dossier'));
      setOpenModal(false);
      GetFolderList.refetch();
    },
    onError: () => {
      toast.error(t('dossierBase.errordelete'));
    },
  });
  const handleDelete = (id) => {
    setOpenModal(false);
    folderDelete.mutate(id);
  };

  const DossierBaseItem = useMemo(
    () => [
      {
        Header: `${t('dossierBase.dossier')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name, id } } }) => (
          <Link to={`/base-documentaire/${urlParams.categoryid}/dossier/${id}`}>
            {name}
          </Link>
        ),
      },
      {
        Header: `${t('dossierBase.numAT')}`,
        accessor: 'numAT',
      },
      {
        Header: `${t('dossierBase.dateCrea')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          <span>{format(new Date(date), 'yyyy-MM-dd')}</span>
        ),
      },
      {
        Header: `${t('dossierBase.autorites')}`,
        accessor: 'autorites',
        Cell: ({ row: { original: { id } } }) => (
          <Link to={`/base-documentaire/${urlParams.categoryid}/dossier/${id}`}>
            <Picto className={styles.picto} name="autorites" />
          </Link>
        ),
      },
      {
        Header: `${t('dossierBase.orgaAgrees')}`,
        accessor: 'organismeAgrees',
        Cell: ({ row: { original: { id } } }) => (
          <Link to={`/base-documentaire/${urlParams.categoryid}/dossier/${id}`}>
            <Picto className={styles.picto} name="organismeAgrees" />
          </Link>
        ),
      },
      {
        Header: `${t('dossierBase.entreprises')}`,
        accessor: 'entreprises',
        Cell: ({ row: { original: { id } } }) => (
          <Link to={`/base-documentaire/${urlParams.categoryid}/dossier/${id}`}>
            <Picto className={styles.picto} name="entreprises" />
          </Link>
        ),
      },
      {
        Header: `${t('dossierBase.action')}`,
        accessor: 'actions',
        Cell: ({ row: { original: { id } } }) => (
          <div>
            <Button
              type="button"
              className="action edit"
              label={<FontAwesomeIcon icon={faEdit} />}
              title={t('documentary.edit')}
              onClick={() => navigate(`/base-documentaire/${urlParams.categoryid}/dossier/edit/${id}`)}
            />
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('documentary.supp')}
              onClick={() => handleModal(id)}
            />
          </div>
        ),
      },
    ],
    [],
  );

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  return (
    <>
      <Layout
        title="Dossier Base"
        layout="table"
        queryError={
          GetFolderList?.isError
          || getDocumentwithidQuery?.isError
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/base-documentaire')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('documentary.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {title}
            </h1>
            <Link className="add" to={`/base-documentaire/${urlParams.categoryid}/dossier/add`}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('dossierBase.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search
              className="mb-20"
              onChange={handleSearch}
            />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={DossierBaseItem}
            isLoading={GetFolderList?.isLoading || false}
            data={GetFolderList?.data?.data?.documentBaseFolderDto || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={GetFolderList?.data?.data?.documentBaseFolderDto?.length || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('documentary.back')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}

export default DossierBase;
