import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  sendUserSecuriteEmailAll,
  sendUserSanitaireEmailAll,
  sendAdminSecuriteEmailAll,
  sendAdminSanitaireEmailAll,
  sendRecapreport,
} from '../../services/mail';
import { calculeIndicateur } from '../../services/indicator';
import { generateInput } from '../../services/inputs';
import { getRewardContract } from '../../services/contracts';
import Layout from '../../components/template/Layout';
import cn from '../../utils/cn';
import Card from '../../components/atoms/Card/Card';
import useAppContext from '../../store/useAppContext';
import styles from './Cron.module.css';
import Button from '../../components/atoms/Button/Button';

function Cron() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();

  const navigateDashboards = () => {
    const etablissement = context?.choiceEstablishment?.name;
    if (context?.choiceBooklet === 1 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/sanitaire/${etablissement}/dashboards`);
    } else if (context.choiceBooklet === 2 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/securite/${etablissement}/dashboards`);
    } else if (context.choiceBooklet === 3 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/vehicule/${etablissement}/dashboards`);
    } else if (context?.user?.role?.includes('ADMIN')) {
      navigate('/dashboard');
    } else {
      navigate('/choix-carnet');
    }
  };

  const SaisieCronQuery = useMutation(generateInput, {
    onSuccess: () => {
      toast.success(t('cron.successSaisie'));
    },
    onError: () => {
      toast.error(t('cron.errorSaisie'));
    },
  });

  const IndicateurSaisie = useMutation(calculeIndicateur, {
    onSuccess: () => {
      toast.success(t('cron.successIndicateur'));
    },
    onError: () => {
      toast.error(t('cron.errorIndicateur'));
    },
  });

  const sendRecapEmail = useMutation(sendRecapreport, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendAdminSanitaire = useMutation(sendAdminSanitaireEmailAll, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendAdminSecurite = useMutation(sendAdminSecuriteEmailAll, {
    onSuccess: () => {
      toast.success(t('cron.successEmails'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendUserSecuriteEmail = useMutation(sendUserSecuriteEmailAll, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendUserSanitairyEmail = useMutation(sendUserSanitaireEmailAll, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const ContractSaisie = useMutation(getRewardContract, {
    onSuccess: () => {
      toast.success(t('cron.successContract'));
    },
    onError: () => {
      toast.error(t('cron.errorContract'));
    },
  });

  return (
    <Layout
      title="Cron"
      description="Cron"
      keywords="Cron"
    >
      <button type="button" className="link" onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
      <br />
      <br />
      <Card>
        <div className={styles.title}>
          <h1 className="title no-margin">{t('cron.email')}</h1>
        </div>
      </Card>
      <br />
      <div className={styles.emails}>
        <Button
          label={t('cron.utilisateur_sanitaire')}
          onClick={() => sendUserSanitairyEmail.mutate()}
          className={styles.utilisateur_sanitaire}
        />
        <Button
          label={t('cron.utilisateur_securite')}
          onClick={() => sendUserSecuriteEmail.mutate()}
          className={styles.utilisateur_securite}
        />
        <Button
          label={t('cron.admin_secutite')}
          onClick={() => sendAdminSecurite.mutate()}
          className={styles.admin_secutite}
        />
        <Button
          label={t('cron.admin_sanitaire')}
          onClick={() => sendAdminSanitaire.mutate()}
          className={styles.admin_sanitaire}
        />
        <Button
          label={t('cron.recap_email')}
          onClick={() => sendRecapEmail.mutate()}
          className={styles.recap_email}
        />
      </div>
      <br />
      <div className={styles.indicateur}>
        <Card>
          <div className={cn([styles.topTitle, styles.alignedCenter])}>
            <h1 className="title no-margin">{t('cron.indicateur')}</h1>
          </div>
        </Card>
        <br />
        <Button
          label={t('cron.indicateur')}
          onClick={() => IndicateurSaisie.mutate()}
          className={styles.indicateur}
        />
      </div>
      <br />
      <div className={styles.saisie}>
        <Card>
          <div className={cn([styles.titleSaisie, styles.alignedCenter])}>
            <h1 className="title no-margin">{t('cron.saisie')}</h1>
          </div>
        </Card>
        <br />
        <Button
          label={t('cron.saisie_refresh')}
          onClick={() => SaisieCronQuery.mutate()}
          className={styles.saisie_refresh}
        />
        <br />
      </div>
      <div className={styles.indicateur}>
        <Card>
          <div className={cn([styles.topTitle, styles.alignedCenter])}>
            <h1 className="title no-margin">{t('cron.contract')}</h1>
          </div>
        </Card>
        <br />
        <Button
          label={t('cron.contract')}
          onClick={() => ContractSaisie.mutate()}
          className={styles.indicateur}
        />
      </div>
      <br />
      <button type="button" className="link" onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
    </Layout>
  );
}

export default Cron;
