/* eslint-disable max-len */
/* eslint-enable  no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/template/Layout';
import EstablishmentCardChoice from '../../components/molecules/List/EstablishmentCardChoice/EstablishmentCardChoice';
import Search from '../../components/molecules/Search/Search';
import Button from '../../components/atoms/Button/Button';
// hooks
import useAppContext from '../../store/useAppContext';

// infrastructures
import { getEstablishmentsList } from '../../services/structures';

// style
import styles from './ChoiceEstablishment.module.css';
import { formatDateToUser } from '../../utils/dates';

// util
import cn from '../../utils/cn';
import Picto from '../../components/atoms/Picto/Picto';

function ChoiceEstablishment() {
  const [search, setSearch] = useState('');
  const [establishmentsFiltered, setEstablishmentsFiltered] = useState('');
  const [establishments, setEstablishments] = useState([]);
  const [buildingId, setBuildingId] = useState(2);
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const navigate = useNavigate();
  // const [getEstablishmentsListQuery, setGetEstablishmentsListQuery] = useState();

  // const fetchData = async () => {
  //   try {
  //     const data = await getEstablishmentsList({ bookletId: 2 });
  //     setGetEstablishmentsListQuery(data);
  //     setEstablishmentsFiltered(data?.config?.params?.bookletId);
  //     setEstablishments(data?.data?.establishments);
  //     console.log('data', data?.data?.establishments);
  //     // dispatch({ type: 'ESTABLISHMENTS_Securite', payload: data });
  //   } catch (error) {
  //     // Handle error
  //     console.error('Error fetching establishments:', error);
  //   }
  // };
  const getEstablishmentsListQuery = useQuery('building-template', () => getEstablishmentsList({ bookletId: 2 }), {
    onSuccess: (data) => {
      console.log('data', data?.data?.establishments);
      setEstablishmentsFiltered(data?.config?.params?.bookletId);
      setEstablishments(data?.data?.establishments);
      console.log('data', data?.data?.establishments);
    },
  });

  const handleChoiceEstablishment = (establishment) => {
    let estab = establishment.name.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: establishment });
    navigate(`/securite/${establishment.id}-${estab}/dashboards`);
  };

  useEffect(() => {
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
    if (!context?.Establishment_Securite) {
      // fetchData();
    } else {
      setEstablishmentsFiltered(2);
      // setGetEstablishmentsListQuery(context?.Establishment_Securite);
      // setEstablishments(context?.Establishment_Securite);
    }
  }, []);

  const handleSearch = (e) => {
    const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.data?.establishments?.filter(
      (establishment) => establishment?.name
        .toString()
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0,
    );
    setEstablishments(establishmentsFilteredBySearch);
    setSearch(e.target.value);
  };

  const avis = [
    {
      label: 'Tous',
      value: 2,
    },
    {
      label: 'Avis favorables',
      value: 1,
    },
    {
      label: 'Avis défavorables',
      value: 0,
    },
  ];

  const handleBuilding = (id) => {
    setBuildingId(id);
    if (id === 0) {
      const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.data?.establishments?.filter(
        (establishment) => establishment?.indicators?.lastCommitteeNotice === false,
      );
      console.log('establishmentsFilteredBySearch', establishmentsFilteredBySearch);
      setEstablishments(establishmentsFilteredBySearch);
    } else if (id === 1) {
      const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.data?.establishments?.filter(
        (establishment) => establishment?.indicators?.lastCommitteeNotice === true,
      );
      console.log('establishmentsFilteredBySearch', establishmentsFilteredBySearch);
      setEstablishments(establishmentsFilteredBySearch);
    } else {
      setEstablishments(getEstablishmentsListQuery?.data?.data?.establishments);
    }
  };

  const commission = (establishment) => {
    if (establishment?.indicators?.lastCommitteeNotice === true) {
      return true;
    }
    if (establishment?.indicators?.lastCommitteeNotice === false) {
      return false;
    }
    return null;
  };
  return (
    <Layout
      title={t('choice_establishment.title_page')}
      queryError={
        getEstablishmentsListQuery?.error
      }
    >
      <header className={
        cn(['header',
          styles.headerChoice,
          'shadow-md',
          styles.security,
        ])
}
      >
        <h1 className="title">
          <Picto name="etablissementsRoundedBlue" />
          {' '}
          {t('choice_establishment.title_page')}
          {' '}
          <Picto name="etablissementsRoundedBlue" />
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        <div className={styles.searchRow}>
          <Search
            className="mb-20"
            value={search || ''}
            onChange={handleSearch}
          />
        </div>
        <div className={styles.filtersToComplete}>
          {avis?.map((building) => (
            <Button
              key={building.value}
              type="button"
              label={building.label}
              className={cn([styles.filter, buildingId === building.value
                ? styles.active
                : ''])}
              onClick={() => handleBuilding(building.value)}
            />
          ))}
        </div>
        <div>
          {establishmentsFiltered !== 2 && (
            <div className="loader" />
          )}
          {establishmentsFiltered === 2 && (
            <div className={styles.sectionChoiceList}>
              {establishments?.map((establishment) => (
                <EstablishmentCardChoice
                  onClick={handleChoiceEstablishment}
                  establishment={establishment}
                  key={establishment?.id}
                  commission={commission(establishment)}
                  texte={(
                    <div className={styles.blockSecu}>
                      {establishment?.indicators?.lastCommitteeDate && establishment?.indicators?.nextCommitteeDate ? (
                        <div>
                          {establishment?.indicators?.lastCommitteeNotice === true ? (
                            <div className={styles.comisionSecu}>
                              <div className={styles.comisionItem}>
                                <span className={styles.label}>
                                  {' '}
                                  {t('choice_establishment.favorable')}
                                </span>
                                {' '}
                                <span className={styles.favorable}>
                                  {formatDateToUser(establishment?.indicators?.lastCommitteeDate?.date)}
                                </span>
                              </div>
                              <li className={cn([styles.chrono_balls, styles.left_ball])}>{' '}</li>
                              <div className={styles.vertical} />
                              <li className={cn([styles.chrono_ball_prochaine, styles.right_ball])}>{' '}</li>
                              <div className={styles.comisionItem}>
                                <span className={styles.label}>
                                  {t('choice_establishment.next_commission')}
                                </span>
                                {' '}
                                <span className={styles.values}>
                                  {formatDateToUser(establishment?.indicators?.nextCommitteeDate?.date)}
                                </span>
                              </div>
                            </div>
                          ) : null }
                          {establishment?.indicators?.lastCommitteeNotice === false ? (
                            <div className={styles.comisionSecu}>
                              <div className={styles.comisionItem}>
                                <span className={styles.label}>
                                  {' '}
                                  {t('choice_establishment.defavorable')}
                                </span>
                                {' '}
                                <span className={styles.defavorable}>
                                  {formatDateToUser(establishment?.indicators?.lastCommitteeDate?.date)}
                                </span>
                              </div>
                              <li className={cn([styles.chrono_ball, styles.left_ball])}>{' '}</li>
                              <div className={styles.verticalDefavorable} />
                              <li className={cn([styles.chrono_ball_prochaine, styles.right_ball])}>{' '}</li>
                              <div className={styles.comisionItem}>
                                <span className={styles.label}>
                                  {t('choice_establishment.next_commission')}
                                </span>
                                {' '}
                                <span className={styles.values}>
                                  {formatDateToUser(establishment?.indicators?.nextCommitteeDate?.date)}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className={styles.comisionSecus}>
                          <div className={styles.comisionItem}>
                            {' '}
                          </div>
                        </div>
                      )}
                      {establishment?.indicators?.totalLiftedObservationsCount
                        || establishment?.indicators?.prescriptionsToLiftCount
                        || establishment?.indicators?.recordKeepingRate2
                        || establishment?.indicators?.lateSecurityReportsCount
                        || establishment?.indicators?.securityInputsDonePercent
                        ? (
                          <>
                            <hr />
                            <div className={styles.container}>
                              {establishment?.indicators?.totalLiftedObservationsCount
                              || establishment?.indicators?.prescriptionsToLiftCount
                              || establishment?.indicators?.recordKeepingRate2
                                ? (
                                  <div className={styles.texteCard}>
                                    <Picto name="contrats" className={styles.picto} />
                                    <div className={styles.CardTexts}>
                                      {establishment?.indicators?.totalLiftedObservationsCount ? (
                                        <li key={establishment.indicators?.totalLiftedObservationsCount} className={styles.reporttext}>
                                          <span className={styles.value}>
                                            {establishment?.indicators?.totalLiftedObservationsCount}
                                          </span>
                                          <span className={styles.reporttext}>
                                            {' '}
                                            {t('choice_establishment.totalLiftedObservationsCount')}
                                          </span>
                                        </li>
                                      ) : ''}
                                      {establishment?.indicators?.prescriptionsToLiftCount ? (
                                        <li key={establishment.indicators?.prescriptionsToLiftCount} className={styles.reporttext}>
                                          <span className={styles.value}>
                                            {establishment?.indicators?.prescriptionsToLiftCount}
                                            {' '}
                                          </span>
                                          <span className={styles.reporttext}>
                                            {' '}
                                            {t('choice_establishment.prescriptionsToLiftCount')}
                                          </span>
                                        </li>
                                      ) : ''}
                                      {establishment?.indicators?.recordKeepingRate2 ? (
                                        <li key={establishment.indicators?.recordKeepingRate2} className={styles.reporttext}>
                                          {establishment?.indicators?.recordKeepingRate2 === 100 ? (
                                            <span className={cn([styles.value, 'tag--success'])}>
                                              {' '}
                                              {establishment?.indicators?.recordKeepingRate2}
                                              {' '}
                                            </span>
                                          ) : null}
                                          {establishment?.indicators?.recordKeepingRate2 < 100
                                            && establishment?.indicators?.recordKeepingRate2 >= 70 ? (
                                              <span className={cn([styles.value, styles.tagWarning])}>
                                                {' '}
                                                {establishment?.indicators?.recordKeepingRate2}
                                                {' '}
                                              </span>
                                            ) : null}
                                          {establishment?.indicators?.recordKeepingRate2 < 70 ? (
                                            <span className={cn([styles.value])}>
                                              {' '}
                                              {establishment?.indicators?.recordKeepingRate2}
                                              {' '}
                                            </span>
                                          ) : null}
                                          <span className={styles.reporttext}>
                                            {' '}
                                            {t('choice_establishment.recordKeepingRate1')}
                                          </span>
                                        </li>
                                      ) : ''}
                                    </div>
                                  </div>
                                ) : null}
                              {establishment?.indicators?.lateSecurityReportsCount ? (
                                <div className={styles.texteCard}>
                                  <Picto name="depotrapport" className={styles.picto} />
                                  <div className={styles.CardTexts}>
                                    <li key={establishment.indicators?.lateSecurityReportsCount} className={styles.reporttext}>
                                      <span className={styles.value}>
                                        {' '}
                                        {establishment?.indicators?.lateSecurityReportsCount}
                                        {' '}
                                      </span>
                                      <span className={styles.reporttext}>
                                        {' '}
                                        {t('choice_establishment.lateSecurityReportsCount')}
                                      </span>
                                    </li>
                                  </div>
                                </div>
                              ) : null}
                              {establishment?.indicators?.securityInputsDonePercent ? (
                                <div className={styles.texteCard}>
                                  <Picto name="inputs" className={styles.picto} />
                                  <div className={styles.CardTexts}>
                                    <li key={establishment.indicators?.securityInputsDonePercent} className={styles.reporttext}>
                                      <span className={styles.value}>
                                        {' '}
                                        {establishment?.indicators?.securityInputsDonePercent}
                                        {' '}
                                      </span>
                                      <span className={styles.reporttext}>
                                        {' '}
                                        {t('choice_establishment.securityInputsDonePercent')}
                                      </span>
                                    </li>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <div className={styles.texteCards}>
                            <div className={styles.CardTexts}>
                              {' '}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                />
              )) || ''}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default ChoiceEstablishment;
