/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight, faAddressCard, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';

// Components
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';

// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';

// services
import { getSocietiesList } from '../../services/societies';
import { deleteIntervention, getInterventionsList } from '../../services/interventions';
import { getUsersList } from '../../services/users';
import { getBuildingsList } from '../../services/structures';

// utils & misc
import { formatDateToUser } from '../../utils/dates';
import { downloadFileCsv } from '../../utils/downloadFile';
import { getVehiculelist } from '../../services/vehicules';
import { isInputIntervention } from '../../utils/interventions';
import cn from '../../utils/cn';

// styles
import styles from './Interventions.module.css';
import Permissions from '../../components/molecules/Permissions/Permissions';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Interventions() {
  const [openModal, setOpenModal] = useState(false);
  const [trainingToDelete, setInterventionToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  let VehiculeQueryList = useState([]);
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [context] = useAppContext();
  const [response, setResponse] = useState([]);
  const [buildingId, setBuildingId] = useState(context?.choiceEstablishment?.buildingIds[0]);
  const [vehicles, setVehicleId] = useState(null);
  const [vehiculeLoading, setVehiculeLoading] = useState(true);

  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) setBuildingId(data.data.buildings[0].id);
    },
  });

  const getInterventionsListQuery = useQuery(['interventions', page, search, vehicles !== null ? vehicles : null], () => getInterventionsList({
    page: 0,
    establishmentId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    buildingId,
    vehicleId: vehicles,
    search,
  }), {
    onSuccess: (data) => {
      setResponse(data?.data?.interventions);
      if (!search) {
        setResponse(data?.data?.interventions?.data?.data?.interventions
          ?.filter((intervention) => intervention?.building?.id === buildingId)
          ?.map((intervention) => intervention));
      }
      setVehiculeLoading(false);
    },
  });

  const findInterBulding = (id) => {
    const b = getInterventionsListQuery?.data?.data?.interventions
      ?.filter((intervention) => intervention?.building?.id === id)
      ?.map((intervention) => intervention);
    return b;
  };

  useEffect(() => {
    findInterBulding(buildingId);
    // setVehicleId(params.id);
    setResponse(findInterBulding(buildingId));
  }, [buildingId]);

  const handleBuilding = (id) => {
    onPageChange(1);
    setBuildingId(id);
    findInterBulding(id);
    setResponse(findInterBulding(id));
  };
  const getSocietiesListQuery = useQuery(['societiesList'], () => getSocietiesList({
    mine: true,
    structureId: context.choiceEstablishment.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const findSociety = (societyId) => getSocietiesListQuery?.data?.data?.societies?.find(
    (society) => society.id === societyId,
  );

  const getUsersListQuery = useQuery(['usersList'], () => getUsersList({
    structureId: context.choiceEstablishment.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const findUser = (userId) => {
    const a = getUsersListQuery?.data?.data?.users
      ?.map((user) => ({ ...user, name: `${user.firstName} ${user.lastName}` }))
      ?.find((user) => user.id === userId);
    return a;
  };

  const deleteInterventionMutation = useMutation(deleteIntervention, {
    onSuccess:
      () => {
        getInterventionsListQuery.refetch();
      },
  });

  const handleModal = (selectedInterventionToDelete) => {
    setOpenModal(true);
    setInterventionToDelete(selectedInterventionToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteInterventionMutation.mutate(trainingToDelete.id);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: t('interventions.building'),
        accessor: 'building.name',
      },
      {
        Header: t('interventions.equipment'),
        accessor: 'equipment.name',
      },
      {
        Header: t('interventions.control'),
        accessor: 'control.name',
      },
      {
        Header: t('interventions.type'),
        accessor: 'controlType',
        Cell: ({ row: { original: { control } } }) => (
          <div>
            {t(`interventions.${control.interventionType}`)}
          </div>
        ),
      },
      {
        Header: t('interventions.title'),
        accessor: 'title',
      },
      {
        Header: t('interventions.calendar'),
        accessor: 'controlDate',
        Cell: ({
          row: {
            original: {
              control, nextInputStartDate, nextInputEndDate, nextReportDate,
            },
          },
        }) => (
          <div>
            {isInputIntervention(control) ? (
              <>
                <div>{formatDateToUser(nextInputStartDate)}</div>
                <div>{formatDateToUser(nextInputEndDate)}</div>
              </>
            ) : (
              <div>{formatDateToUser(nextReportDate)}</div>
            ) }
          </div>
        ),
      },
      {
        Header: t('interventions.frequency'),
        accessor: 'frequencyTitle',
      },
      {
        Header: t('interventions.user'),
        accessor: 'userId',
        Cell: ({ row: { original: { id, userId, societyId } } }) => (
          <div>
            <FontAwesomeIcon data-tip data-for={`userColResult${id}`} icon={faAddressCard} />
            <ReactTooltip id={`userColResult${id}`} place="top" type="info" effect="solid">
              {findUser(userId)?.name ?? findSociety(societyId)?.name ?? '-'}
            </ReactTooltip>
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            {Permissions({ permission: 'INTERVENTION_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/interventions/edit/${id}`}
                  title={t('interventions.edit')}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'INTERVENTION_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('interventions.suppr')}
                  onClick={() => handleModal({ id })}
                />
              ) : null }
          </div>
        ),
      },
    ],
  );

  const columnsVehicle = React.useMemo(
    () => [
      {
        Header: t('interventions.marque'),
        accessor: 'vehicle.brand',
      },
      {
        Header: t('interventions.model'),
        accessor: 'vehicle.model',
      },
      {
        Header: t('interventions.immat'),
        accessor: 'vehicle.licensePlate',
      },
      {
        Header: t('interventions.control'),
        accessor: 'control.name',
      },
      {
        Header: t('interventions.type'),
        accessor: 'controlType',
        Cell: ({ row: { original: { control } } }) => (
          <div>
            {t(`interventions.${control.interventionType}`)}
          </div>
        ),
      },
      {
        Header: t('interventions.calendar'),
        accessor: 'controlDate',
        Cell: ({ row }) => {
          if (row.original.lastMaintenanceDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {new Date(row.original.controlDate).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: t('interventions.frequency'),
        accessor: 'frequencyTitle',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            {Permissions({ permission: 'INTERVENTION_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/interventions/edit/${id}`}
                  title={t('interventions.edit')}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'INTERVENTION_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('interventions.suppr')}
                  onClick={() => handleModal({ id })}
                />
              ) : null }
          </div>
        ),
      },
    ],
  );

  const hearder = [
    {
      label: `${t('interventions.building')}`,
      key: 'buildingName',
      cell: (row) => row.original.building.name,
    },
    {
      label: `${t('interventions.equipment')}`,
      key: 'equipment.name',
      cell: (row) => row.original.equipment.name,
    },
    {
      label: `${t('interventions.control')}`,
      key: 'control.name',
      cell: (row) => row.original.control.name,
    },
    {
      label: `${t('interventions.type')}`,
      key: 'controlType',
      cell: (row) => t(`interventions.${row.original.control.interventionType}`),
    },
    {
      label: `${t('interventions.title')}`,
      key: 'title',
    },
    {
      label: `${t('interventions.calendar')}`,
      key: 'controlDate',
      cell: (row) => new Date(row.original.controlDate).toLocaleDateString(),
    },
    {
      label: `${t('interventions.frequency')}`,
      key: 'frequencyTitle',
    },
    {
      label: `${t('interventions.user')}`,
      key: 'userId',
      cell: (row) => {
        if (row.original.userId) {
          return findUser(row.original.userId)?.name ?? '-';
        }
        return findSociety(row.original.societyId)?.name ?? '-';
      },
    },
  ];
  const handleDowload = () => {
    downloadFileCsv(findInterBulding(buildingId), hearder, `${t('interventions.page_list_name')}`);
  };

  const handlevehicule = (id) => {
    setVehicleId(id);
    getInterventionsListQuery.refetch();
    setVehiculeLoading(true);
  };

  if (context.choiceBooklet === 3) {
    VehiculeQueryList = useQuery(['vehicule', buildingId], () => getVehiculelist({
      page: 0,
      establishmentId: context.choiceEstablishment.id,
      buildingId,
      bookletId: context?.choiceBooklet,
    }), {
      onSuccess: (data) => {
        handlevehicule(data?.data?.vehicles[0]?.id);
      },
    });
  }

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('interventions.page_list_name')}
        queryError={
          getInterventionsListQuery?.error
          || deleteInterventionMutation?.error
          || getSocietiesListQuery?.error
          || getUsersListQuery?.error
          || VehiculeQueryList?.error
          || getBuildingsQuery?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          {Permissions({ permission: 'INTERVENTION_CREATE' }) !== undefined
            ? (
              <div className="row">
                <h1 className="title">{t('interventions.page_list_name')}</h1>
                <Link className={cn([styles.add, 'add col-1'])} to="/interventions/add">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="add_label">{t('interventions.add')}</span>
                </Link>
              </div>
            ) : null }
          <div className="row">
            <button
              className="add"
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.filters}>
          {context.choiceBooklet !== 3 ? (
            <div className={styles.filtersToComplete}>
              {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
                <Button
                  type="button"
                  label={`${building.name}`}
                  className={cn([styles.filter, buildingId === building.id
                    ? styles.active
                    : '', 'shadow-md'])}
                  onClick={() => handleBuilding(building?.id)}
                />
              ))}
            </div>
          ) : (
            <div className={styles.filtersToComplete}>
              {VehiculeQueryList?.data?.data?.vehicles?.map((building) => (
                <Button
                  type="button"
                  label={`${building.licensePlate} ${building.brand} ${building.model}`}
                  className={cn([styles.filter, vehicles === building.id
                    ? styles.active
                    : '', 'shadow-md'])}
                  onClick={() => handlevehicule(building?.id)}
                />
              ))}
            </div>
          )}
        </div>
        <div className={context.choiceBooklet !== 3 ? styles.table : styles.tableVehicle}>
          <Table
            columns={context.choiceBooklet !== 3 ? columns : columnsVehicle}
            isLoading={getSocietiesListQuery.isLoading
              || getUsersListQuery.isLoading
              || getInterventionsListQuery.isLoading || vehiculeLoading}
            data={findInterBulding(buildingId) || response || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={findInterBulding(buildingId)?.length || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>

  );
}

Interventions.propTypes = {};

export default Interventions;
