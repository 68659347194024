/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import {
  getEquipmentsList,
  deleteEquipment,
} from '../../services/equipments';
// Utils
import styles from './Equipments.module.css';
import { BOOKLET_NAME } from '../../utils/constant';
import useAppContext from '../../store/useAppContext';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Equipments() {
  // Hooks
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  // States
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [equipmentToDelete, setEquipmentToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  const [context] = useAppContext();

  // API Calls
  const getEquipmentsListQuery = useQuery(['equipments', page, search], () => getEquipmentsList({
    page: 0,
    search,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const deleteEquipmentMutation = useMutation(deleteEquipment, {
    onSuccess:
      () => {
        getEquipmentsListQuery.refetch();
      },
  });

  const handleModal = (selectedEquipmentToDelete) => {
    setOpenModal(true);
    setEquipmentToDelete(selectedEquipmentToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteEquipmentMutation.mutate(equipmentToDelete.id);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('equipments.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('equipments.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('equipments.family')}`,
        accessor: 'equipmentFamily.name',
      },
      {
        Header: `${t('equipments.booklet')}`,
        accessor: 'bookletIds',
        Cell: ({ row: { original: { bookletIds } } }) => (
          <>
            <p>{bookletIds.includes(1) ? BOOKLET_NAME.SANITARY : null}</p>
            <p>{bookletIds.includes(2) ? BOOKLET_NAME.SECURITY : null}</p>
          </>
        ),
      },
      {
        Header: `${t('equipments.controls')}`,
        accessor: 'countControls',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/equipements/edit/${id}`}
              title={t('equipments.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('equipments.suppr')}
              onClick={() => handleModal({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('equipments.title')}
        queryError={
          getEquipmentsListQuery?.error
          || deleteEquipmentMutation?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('equipments.title')}</h1>
            <Link className="add" to="/equipements/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('equipments.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getEquipmentsListQuery.isLoading}
            data={getEquipmentsListQuery?.data?.data?.equipments || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getEquipmentsListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Equipments;
