import React, { useState } from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useMutation } from 'react-query';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { getObservation, postLift } from '../../services/registre';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './DeskshopObservation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
// import Radio from '../../components/atoms/Radio/Radio';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Textarea from '../../components/atoms/Textarea/Textarea';
import useAppContext from '../../store/useAppContext';

function DeskshopObservation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oneObservation, setOneObservation] = useState([]);
  const urlParams = useParams();
  const [context] = useAppContext();

  const initialValues = {
    upDate: '',
    prestataireName: '',
    prestataire: false,
    document: null,
    description: '',
    SocietyName: '',
  };
  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: urlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setOneObservation(data?.data);
    },
  });
  const postLiftMutation = useMutation(postLift, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const validationSchema = Yup.object({
    prestataire: Yup.boolean(),
    upDate: Yup.string().required('global.required_field'),
    prestataireName: Yup.string().required('global.required_field'),
    document: Yup.mixed()
      .nullable()
      .typeError(t('global.wrong_type'))
      .when('prestataire', {
        is: false,
        then: Yup.mixed().nullable()
          .required('global.required_field')
          .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
          .test('type', 'global.accepted_formats', (value) => value && (
            value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg'
          )),
      }),
    description: Yup.string().required('global.required_field'),
    SocietyName: Yup.string()
      .when('prestataire', {
        is: false,
        then: Yup.string().required('global.required_field'),
      }),
  });

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('observationIds[0]', urlParams?.id);
    formData.append('reportId', oneObservation?.report?.id);
    formData.append('liftDate', values.upDate);
    formData.append('stakeholder', values.prestataireName);
    if (values.document !== null) {
      formData.append('documentFile', values.document);
    }
    formData.append('actionPerformed', values.description);
    if (values.prestataire !== true) {
      formData.append('society', values.SocietyName);
    } else {
      formData.append('society', 'regie_interne');
    }
    postLiftMutation.mutate(formData);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  console.log(formik.errors);
  return (
    <Layout
      title="DeskshopObservation"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">
            {t('raisedObs.add')}
            :
          </h1>
        </div>
        <span className="title-info">
          {oneObservation?.intervention?.title}
        </span>
      </header>
      <div className="title">
        <div>
          {getObservationQuery.isLoading && (
            <div>Loading...</div>
          )}
          {/* <div>
            Observation:
            {oneObservation?.title}
            <br />
            Numero de rapport:
            {oneObservation?.number}
          </div> */}
          <form
            onSubmit={formik.handleSubmit}
            className={cn(['form shadow-sm', styles.form])}
          >
            {/* {getObservationQuery?.data?.observationNumber} */}
            {/* <table>
              <thead>
                <tr>
                  <td>Observation</td>
                  <td>Numéro de rapport</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{oneObservation?.title}</td>
                  <td>{oneObservation?.number}</td>
                </tr>
              </tbody>
            </table> */}
            <div className={styles.table}>
              Observation - Numéro de rapport
              <br />
              <span style={{ color: 'grey' }}>
                {oneObservation?.title}
                {' - '}
                {oneObservation?.number}
              </span>
            </div>
            <div>
              {' '}
            </div>
            {/* <div className="form_group">
              <Input
                type="text"
                name="observation"
                label={t('raisedObs.observation')}
                value={oneObservation?.title}
                disabled
              />
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="number"
                label={t('raisedObs.num_rapport')}
                value={oneObservation?.number}
                disabled
              />
            </div> */}
            <div className="form_group">
              <Input
                type="date"
                name="upDate"
                label={t('raisedObs.upDate')}
                placeholder={t('raisedObs.upDate')}
                value={formik.values.upDate}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.upDate && formik.touched.upDate ? (
                <div className="error">
                  {t(formik.errors.upDate)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="prestataireName"
                label={t('raisedObs.prestataireName')}
                placeholder={t('raisedObs.prestataireName')}
                value={formik.values.prestataireName}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.prestataireName && formik.touched.prestataireName ? (
                <div className="error">
                  {t(formik.errors.prestataireName)}
                </div>
              ) : null }
            </div>
            {formik.values.prestataire === true ? (
              <div className="form_group">
                <Input
                  type="text"
                  name="SocietyName"
                  label={t('raisedObs.SocietyName')}
                  placeholder={t('raisedObs.SocietyName')}
                  value={formik.values.SocietyName}
                  onChange={formik.handleChange}
                  disabled
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
            ) : (
              <div className="form_group">
                <Input
                  type="text"
                  name="SocietyName"
                  label={t('raisedObs.SocietyName')}
                  placeholder={t('raisedObs.SocietyName')}
                  value={formik.values.SocietyName}
                  onChange={formik.handleChange}
                  required={formik.values.prestataire === false}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
            )}
            <div className={cn(['form_checkbox', styles.check])}>
              <Checkbox
                id="prestataire"
                name="prestataire"
                className={styles.check}
                label={t('raisedObs.byPrestaataire')}
                placeholder={t('raisedObs.byPrestaataire')}
                value={formik.values.prestataire}
                onChange={formik.handleChange}
                checked={formik.values.prestataire}
              />
            </div>
            {' '}
            <div className="form_group--fullwidth">
              <Textarea
                name="description"
                label={t('raisedObs.descriptionTextarea')}
                placeholder={t('raisedObs.description')}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.errors.description && formik.touched.description ? (
                <div className="error">
                  {t(formik.errors.description)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <UploadFile
                id="documentFile"
                name="document"
                label={t('contract.report')}
                fileName={formik.values.document ? formik.values.document.name : formik.values.document}
                onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
                onBlur={formik.handleBlur}
                // required={!formik.values.prestataire}
              />
              {formik.errors.document && formik.touched.document ? (
                <div className="error">
                  {t(formik.errors.document)}
                </div>
              ) : null }
            </div>
            <div className="form_footer">
              <div className="form_infos">
                <small>{t('addInterventions.mandatory_fields')}</small>
              </div>
              <Button
                type="submit"
                className="form_submit"
                label={t('raisedObs.save')}
              />
            </div>
          </form>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default DeskshopObservation;
