/* eslint-disable max-len */
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { omit } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Select from '../../components/atoms/Select/Select';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Permissions from '../../components/molecules/Permissions/Permissions';
// Hooks
import useAppContext from '../../store/useAppContext';
// Services
import {
  getTraining, postTraining, editTraining,
} from '../../services/trainings';
import { getSocietiesList } from '../../services/societies';
// Utils
import { TRAINING_TYPES, TRAINING_NAMES } from '../../utils/constant';

function AddTraining() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();

  const initialValues = {
    establishmentId: context?.choiceEstablishment?.id,
    type: '',
    name: '',
    date: '',
    headcount: 0,
    documentFile: null,
    isDocumentFile: false,
    isEditing: false,
    speakerId: null,
    speakerName: '',
    personInCharge: '',
    firstName: '',
    lastName: '',
    validUntil: '',
    houseKeeping: false,
  };

  const validationSchema = Yup.object().shape({
    establishmentId: Yup.number(),
    type: Yup.string().required('global.required_field'),
    name: Yup.string().required('global.required_field'),
    date: Yup.string().required('global.required_field'),
    headcount: Yup.number().nullable(),
    documentFile: Yup.mixed().nullable()
      .when('isEditing', {
        is: false,
        then: Yup.mixed().nullable().required('global.required_field'),
      })
      .when('isDocumentFile', {
        is: true,
        then: Yup.mixed().nullable().required('Le format de fichier est invalide : Les formats autorisés sont "pdf"; "autocad" et tous les formats "image"')
          .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
          .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg')),
      }),
    speakerId: Yup.number().nullable().when(['type', 'houseKeeping'], {
      is: (type, houseKeeping) => type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION && houseKeeping === false,
      then: Yup.number().nullable().required('global.required_field'),
    }),
    speakerName: Yup.string().nullable(),
    personInCharge: Yup.string().nullable().when('type', {
      is: TRAINING_TYPES.FIRE_SAFETY_PREVENTION,
      then: Yup.string().nullable().required('global.required_field'),
    }),
    firstName: Yup.string().nullable().when('type', {
      is: TRAINING_TYPES.MAINTENANCE_MANAGER,
      then: Yup.string().nullable().required('global.required_field'),
    }),
    lastName: Yup.string().nullable().when('type', {
      is: TRAINING_TYPES.MAINTENANCE_MANAGER,
      then: Yup.string().nullable().required('global.required_field'),
    }),
    validUntil: Yup.string().nullable().when('type', {
      is: TRAINING_TYPES.MAINTENANCE_MANAGER,
      then: Yup.string().nullable().required('global.required_field'),
    }),
    houseKeeping: Yup.bool(),
  });

  // API calls
  const postTrainingMutation = useMutation(postTraining, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_training.training_added'));
    },
  });
  const editTrainingMutation = useMutation(editTraining, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_training.training_edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => (urlParams?.action === 'edit'
      ? editTrainingMutation.mutate(formValues)
      : postTrainingMutation.mutate(formValues)),
  });

  const getTrainingQuery = useQuery('training', () => getTraining(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues({
        ...omit(data.data, 'speakerId'),
        isEditing: urlParams?.action === 'edit',
        houseKeeping: data?.data?.speakerName === null,
        speakerId: data?.data?.speakerId,
        date: format(new Date(data?.data?.date), 'yyyy-MM-dd'),
        validUntil: format(new Date(data?.data?.validUntil), 'yyyy-MM-dd'),
      });
    },
  });

  // const dowloaddoc = (data) => {
  //   const url = window.URL.createObjectURL(new Blob([data]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', getTrainingQuery?.data?.data?.documentFileName);
  //   document.body.appendChild(link);
  //   link.click();
  // };

  const types = [
    {
      value: TRAINING_TYPES.FIRE_SAFETY_PREVENTION,
      label: t('add_training.fire_safety_prevention'),
    },
    {
      value: TRAINING_TYPES.MAINTENANCE_MANAGER,
      label: t('add_training.maintenance_manager'),
    },
  ];

  const trainingsFireSafetyPrevention = [
    {
      value: TRAINING_NAMES.EVACUATION,
      label: t('add_training.evacuation'),
    },
    {
      value: TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION,
      label: t('add_training.designated_employees_evacuation'),
    },
    {
      value: TRAINING_NAMES.UNANNOUNCED_EXERCISE,
      label: t('add_training.unannounced_exercise'),
    },
    {
      value: TRAINING_NAMES.INTERNAL,
      label: t('add_training.internal'),
    },
    {
      value: TRAINING_NAMES.SSI,
      label: t('add_training.ssi'),
    },
    {
      value: TRAINING_NAMES.HANDLING_EMERGENCY_MEANS,
      label: t('add_training.handling_emergency_means'),
    },
  ];

  const trainingsMaintenanceManager = [
    {
      value: TRAINING_NAMES.ELECTRICAL_CLEARANCE,
      label: t('add_training.electrical_clearance'),
    },
    {
      value: TRAINING_NAMES.SSIAP,
      label: t('add_training.ssiap'),
    },
    {
      value: TRAINING_NAMES.SST,
      label: t('add_training.sst'),
    },
  ];

  const getSocietiesListQuery = useQuery(
    ['societies', context.choiceEstablishment.id, context.choiceBooklet],
    () => getSocietiesList(
      {
        structureId: context.choiceEstablishment.id,
        bookletId: context.choiceBooklet,
        mine: true,
        establishmentId: context?.choiceEstablishment?.id,
      },
    ),
  );

  const societies = getSocietiesListQuery?.data?.data?.societies.map((society) => (
    {
      value: society.id,
      label: society.name,
    }
  ));

  const trainingNames = formik.values.type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
    ? trainingsFireSafetyPrevention
    : trainingsMaintenanceManager;

  return (
    <Layout
      title={t('add_training.title')}
      queryError={postTrainingMutation?.error
        || editTrainingMutation?.error
        || getTrainingQuery?.error
        || getSocietiesListQuery?.error}
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_training.back_to_trainings')}</span>
          </button>
        </div>
        <h1 className="title">
          {urlParams?.action === 'edit' ? t('add_training.edit_title') : t('add_training.title')}
        </h1>
      </header>
      {getSocietiesListQuery.isLoading || getTrainingQuery.isLoading
        ? <div className="loader" />
        : (
          <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
            <div className="form_group visually-hidden">
              <Input
                id="establishmentId"
                type="text"
                name="establishmentId"
                label={t('add_training.establishment_id')}
                onChange={() => formik.setFieldValue('establishmentId', context?.choiceEstablishment?.id)}
                value={formik.values.establishmentId}
                disabled
              />
            </div>
            <div className="form_group">
              <Select
                id="type"
                label={t('add_training.type')}
                options={types}
                value={types.find((option) => option.value === formik.values.type)}
                onChange={(option) => {
                  formik.setFieldValue('type', option.value);
                  formik.setFieldValue('name', initialValues.name);
                  formik.setFieldValue('speakerId', initialValues.speakerId);
                  formik.setFieldValue('speakerName', initialValues.speakerName);
                  formik.setFieldValue('personInCharge', initialValues.personInCharge);
                  formik.setFieldValue('firstName', initialValues.firstName);
                  formik.setFieldValue('lastName', initialValues.lastName);
                  formik.setFieldValue('validUntil', initialValues.validUntil);
                  formik.setFieldValue('houseKeeping', initialValues.houseKeeping);
                }}
              />
              {formik?.errors?.type && formik?.touched?.type ? (
                <div className="error">
                  {t(formik?.errors?.type)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Select
                id="name"
                label={t('add_training.name')}
                options={trainingNames}
                value={trainingNames.find((option) => option.value === formik.values.name) || ''}
                onChange={(option) => formik.setFieldValue('name', option.value)}
              />
              {formik?.errors?.name && formik?.touched?.name ? (
                <div className="error">
                  {t(formik?.errors?.name)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="date"
                type="date"
                name="date"
                label={t('add_training.date')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date}
                min="2000-01-01"
                max="2099-12-31"
              />
              {formik?.errors?.date && formik.touched?.date ? (
                <div className="error">
                  {t(formik?.errors?.date)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="headcount"
                type="number"
                name="headcount"
                label={t('add_training.headcount')}
                step="1"
                min="0"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.headcount}
              />
              {formik?.errors?.headcount && formik?.touched?.headcount ? (
                <div className="error">
                  {t(formik?.errors?.headcount)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <div className="label">{t('add_training.document_file')}</div>
              <br />
              <UploadFile
                id="documentFile"
                name="documentFile"
                fileName={formik.values.documentFile ? formik.values.documentFile.name : formik.values.documentFileName}
                onChange={(event) => {
                  formik.setFieldValue('documentFile', event.currentTarget.files[0]);
                  formik.setFieldValue('isDocumentFile', true);
                }}
                onBlur={formik.handleBlur}
              />
              {formik?.errors?.documentFile && formik?.touched?.documentFile ? (
                <div className="error">
                  {t(formik?.errors?.documentFile)}
                </div>
              ) : null }
            </div>
            {formik.values.type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION ? (
              <>
                <div className="form_group pt-24">
                  <Checkbox
                    id="houseKeeping"
                    name="houseKeeping"
                    label={t('add_training.house_keeping')}
                    onChange={(event) => {
                      formik.setFieldValue('houseKeeping', event.target.checked);
                      formik.setFieldValue('speakerId', 0);
                      formik.setFieldValue('speakerName', t('add_training.house_keeping'));
                    }}
                    checked={formik.values.houseKeeping}
                    value={formik.values.houseKeeping}
                  />
                  {formik?.errors?.houseKeeping && formik?.touched?.houseKeeping ? (
                    <div className="error">
                      {formik?.errors?.houseKeeping}
                    </div>
                  ) : null }
                </div>
                <div className="form_group">
                  <Select
                    id="speaker"
                    label={t('add_training.speaker')}
                    options={societies}
                    onChange={(option) => formik.setFieldValue('speakerId', option.value)}
                    value={societies?.find((option) => option.value === formik.values.speakerId)}
                    isDisabled={formik.values.houseKeeping === true}
                    loading={getSocietiesListQuery.isLoading}
                  />
                  {formik?.errors?.speakerId && formik?.touched?.speakerId ? (
                    <div className="error">
                      {t(formik?.errors?.speakerId)}
                    </div>
                  ) : null }
                </div>
                <div className="form_group">
                  <Input
                    id="personInCharge"
                    type="text"
                    name="personInCharge"
                    label={t('add_training.person_in_charge')}
                    value={formik.values.personInCharge}
                    onChange={(e) => formik.setFieldValue('personInCharge', e.target.value.toLocaleUpperCase())}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.personInCharge && formik?.touched?.personInCharge ? (
                    <div className="error">
                      {t(formik?.errors?.personInCharge)}
                    </div>
                  ) : null }
                </div>
              </>
            ) : null }
            {formik.values.type === TRAINING_TYPES.MAINTENANCE_MANAGER ? (
              <>
                <div className="form_group">
                  <Input
                    id="validUntil"
                    type="date"
                    name="validUntil"
                    label={t('add_training.valid_until')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.validUntil}
                    min="2000-01-01"
                    max="2099-12-31"
                  />
                  {formik?.errors?.validUntil && formik?.touched?.validUntil ? (
                    <div className="error">
                      {t(formik?.errors?.validUntil)}
                    </div>
                  ) : null }
                </div>
                <div className="form_group">
                  <Input
                    id="lastName"
                    type="text"
                    name="lastName"
                    label={t('add_training.lastname')}
                    /* onChange={(event) => {
                      formik.setFieldValue('lastName', event.target.value);
                      formik.setFieldValue('speakerId', 0);
                    }} */
                    onChange={(e) => formik.setFieldValue('lastName', e.target.value.toLocaleUpperCase())}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik?.errors?.lastName && formik?.touched?.lastName ? (
                    <div className="error">
                      {t(formik?.errors?.lastName)}
                    </div>
                  ) : null }
                </div>
                <div className="form_group">
                  <Input
                    id="firstName"
                    type="text"
                    name="firstName"
                    label={t('add_training.firstname')}
                    onChange={(e) => formik.setFieldValue('firstName', e.target.value.toLocaleUpperCase())}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik?.errors?.firstName && formik?.touched?.firstName ? (
                    <div className="error">
                      {t(formik?.errors?.firstName)}
                    </div>
                  ) : null }
                </div>
              </>
            ) : null}
            {Permissions({ permission: 'TRAINING_EDIT' }) !== undefined
              ? (
                <div className="form_footer">
                  <div className="form_infos">
                    <small>{t('add_training.mandatory_fields')}</small>
                  </div>
                  <Button
                    type="submit"
                    className="form_submit"
                    label={t('add_training.submit')}
                    isLoading={urlParams?.action === 'edit' ? editTrainingMutation.isLoading : postTrainingMutation.isLoading}
                  />
                </div>
              ) : null }
          </form>
        )}
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_training.back_to_trainings')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddTraining;
