/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import List from '../List/List';
import ListItem from '../../atoms/ListItem/ListItem';
// Hooks
import useAppContext from '../../../store/useAppContext';
// Utils
import NavigationAdminGroup from './NavigationAdminGroup';
import NavigationUser from './NavigationUser';
import NavigationAdmin from './NavigationAdmin';
import logo from '../../../assets/img/logos/e-carnet-default-logo.png';
import logoMini from '../../../assets/img/logos/e-carnet-default-icone.png';
import logoSanitaire from '../../../assets/img/logos/e-carnet-sanitaire-logo.png';
import logoSanitaireMini from '../../../assets/img/logos/e-carnet-sanitaire-icone.png';
import logoSecurité from '../../../assets/img/logos/e-carnet-securite-logo.png';
import logoSecuritéMini from '../../../assets/img/logos/e-carnet-securite-icone.png';
import logoVehicule from '../../../assets/img/logos/e-carnet-vehicule-logo.png';
import logoVehiculeMini from '../../../assets/img/logos/e-carnet-vehicule-icone.png';

import styles from './Navigation.module.css';
import {
  USERS_ROLES,
} from '../../../utils/constant';

function Navigation({ isCollapsed }) {
  // Hooks
  const { t } = useTranslation();
  const [context] = useAppContext();

  const isSecurityBooklet = context?.choiceBooklet === 2;
  const isVehicleBooklet = context?.choiceBooklet === 3;
  const isBookletChoosed = !!context?.choiceBooklet;
  const isEstablishmentChoosed = !!context?.choiceEstablishment;
  const isAdminOrSuperAdmin = context?.user?.role === USERS_ROLES.SUPER_ADMIN || context?.user?.role === USERS_ROLES.ADMIN;
  const isAdminGroup = context?.user?.role === USERS_ROLES.ADMIN_GROUP || context?.userImpersonated?.role === USERS_ROLES.SUPER_ADMIN || context?.userImpersonated?.role === USERS_ROLES.ADMIN;
  const isUser = context?.user?.role === USERS_ROLES.USER || context.userImpersonated?.role === USERS_ROLES.USER;
  const isSanitaireBooklet = context?.choiceBooklet === 1;

  const navigateDashboards = () => {
    let url;
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (!isBookletChoosed && !isAdminOrSuperAdmin) {
      url = '/choix-carnet';
    } else if (!isEstablishmentChoosed && !isAdminOrSuperAdmin) {
      url = '/choix-carnet';
    } else if (context.choiceBooklet === 1) {
      url = `/sanitaire/${establishment?.id}-${estab}/dashboards`;
    } else if (context.choiceBooklet === 2) {
      url = `/securite/${establishment?.id}-${estab}/dashboards`;
    } else if (context.choiceBooklet === 3) {
      url = `/vehicule/${establishment?.id}-${estab}/dashboards`;
    } else {
      url = '/';
    }
    return url;
  };

  const headerLogo = () => {
    if (isBookletChoosed) {
      if (!isCollapsed) {
        if (isSecurityBooklet) return logoSecurité;
        if (isVehicleBooklet) return logoVehicule;
        if (isSanitaireBooklet) return logoSanitaire;
        if (isSecurityBooklet) return logoSecuritéMini;
        if (isVehicleBooklet) return logoVehiculeMini;
        if (isSanitaireBooklet) return logoSanitaireMini;
        return logoSanitaireMini;
      }
    }
    if (!isCollapsed) return logo;
    return logoMini;
  };

  return (
    <nav className={styles.navigation}>
      <List>
        <ListItem className={styles.logoItem}>
          <Link className={styles.link} to={navigateDashboards()}>
            <img
              className={styles.logo}
              src={headerLogo()}
              alt={t('global.site_name')}
              width={!isCollapsed ? '151' : '48'}
              height="48"
            />
          </Link>
        </ListItem>
        {(isUser) && (
          <NavigationUser isCollapsed={isCollapsed} />
        )}
        {(isAdminGroup && !isUser) && (
          <NavigationAdminGroup isCollapsed={isCollapsed} />
        )}
        {(isAdminOrSuperAdmin && !isUser) && (
          <NavigationAdmin isCollapsed={isCollapsed} />
        )}
      </List>
    </nav>
  );
}

export default Navigation;
