/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTrash,
  faList,
  faFolderOpen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import Modal from 'react-modal';
import { useMutation, useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import { getBuildingsList } from '../../services/structures';
import { deleteVehicle, getVehiculelist, typeVehicleList } from '../../services/vehicules';
import 'reactjs-popup/dist/index.css';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './MonVehicule.module.css';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';

/* eslint-disable react/prop-types */

function Monvehicule() {
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [search, setSearch] = useState('');
  const [vehiculeNombre, setVehiculeNombre] = useState();
  const [vehiclePackage, setVehiclePackage] = useState(0);
  const [buildingId] = useState(context?.choiceEstablishment?.buildingIds[2]);
  const [openModal, setOpenModal] = useState(false);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [nb, setNb] = useState(0);
  const [totalVehicle, setTotalVehicle] = useState();
  const [idBuilding, setIdBuilding] = useState();

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  // API Calls

  const getBuildingsListQuery = useQuery(['buildings', page], () => getBuildingsList({
    page: 0,
    bookletId: 3,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setIdBuilding(data?.data?.buildings?.find((building) => building?.id === buildingId)?.id);
    },
  });

  const VehiculeQueryList = useQuery(['vehicule', page, buildingId, search], () => getVehiculelist({
    page: 0,
    establishmentId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    search,
    buildingId: idBuilding,
  }), {
    onSuccess: (data) => {
      if (nb === 0) {
        setNb(1);
        setTotalVehicle(data?.data?.total);
      }
      setVehiclePackage(10);
      setVehiculeNombre(data?.data?.total);
    },
  });

  const TypeVehicule = useQuery(['typeVehicule', page], () => typeVehicleList({
    page: 0,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const SearchTypeVehicule = React.useMemo(() => {
    if (VehiculeQueryList?.data && TypeVehicule.data) {
      return VehiculeQueryList?.data?.data?.vehicles?.map((vehicule) => ({
        ...vehicule,
        vehicleTypeId: TypeVehicule?.data?.data?.vehicleTypes?.find((type) => type.id === vehicule?.vehicleTypeId),
      }));
    }
    return [];
  }, [VehiculeQueryList.data, TypeVehicule.data]);

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const deleteVehicleQuery = useMutation(deleteVehicle, {
    onSuccess:
      () => {
        toast.success(t('vehicule.vehicule_delete'));
        VehiculeQueryList.refetch();
      },
  });
  const handleDelete = (id) => {
    setOpenModal(false);
    deleteVehicleQuery.mutate(id);
    setTimeout(() => {
      VehiculeQueryList.refetch();
    }, 100);
  };
  const handleModal = (id) => {
    setOpenModal(true);
    setsupplierToDelete(id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('vehicule.type')}`,
        accessor: 'vehicleTypeId.type',
        canSort: true,
        Cell: ({ row }) => (
          <div title={row?.original?.vehicleTypeId?.label}>
            {row?.original?.vehicleTypeId?.type}
          </div>
        ),
      },
      {
        Header: `${t('vehicule.brand')}`,
        accessor: 'brand',
        canSort: true,
      },
      {
        Header: `${t('vehicule.model')}`,
        accessor: 'model',
        canSort: true,
      },
      {
        Header: `${t('vehicule.immatform')}`,
        accessor: 'licensePlate',
        canSort: true,
      },
      {
        Header: `${t('vehicule.currentMileage')}`,
        accessor: 'currentMileage',
        canSort: true,
      },
      {
        Header: `${t('vehicule.nextMaintenance')}`,
        accessor: 'date',
        Cell: ({ row }) => {
          if (row?.original?.lastMaintenanceDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {new Date(row?.original?.lastMaintenanceDate?.date).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.serviceIntervalMileage')}`,
        accessor: 'serviceIntervalMileage',
        Cell: ({ row }) => {
          if (row?.original?.lastMaintenanceMileage === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {row?.original?.lastMaintenanceMileage}
              {' '}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.datect')}`,
        accessor: 'lastControlDate',
        Cell: ({ row }) => {
          if (row?.original?.lastControlDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {new Date(row?.original?.lastControlDate?.date).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.document')}`,
        Cell: ({ row }) => (
          <div className={styles.actions}>
            <button
              type="button"
              className="action"
              alt="document du vehicule"
              title="document du vehicule"
              onClick={() => navigate(`/base-documentaire?vehicule=${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faFolderOpen} />
            </button>
          </div>
        ),
      },
      {
        Header: `${t('vehicule.loc')}`,
        Cell: ({ row }) => (
          <div className={styles.actions}>
            <button
              type="button"
              className="action list"
              alt="contrat de location"
              title="contrat de location"
              onClick={() => navigate(`/contratsVehicule/${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faFolderOpen} />
            </button>
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className={styles.actions}>
            <button
              type="button"
              className="action edit"
              alt="modifier le vehicule"
              title="modifier le vehicule"
              onClick={() => navigate(`/EditVehicule/${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              type="button"
              className="action list"
              name="check list"
              alt="accedez a la check list"
              title="accedez a la check list"
              onClick={() => navigate(`/intervention/${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
            <button
              type="button"
              className="action suppr"
              alt="supprimer le vehicule"
              title="supprimer le vehicule"
              onClick={() => handleModal(row?.original?.id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <Layout
      title={t('vehicule.title')}
      layout="table"
      table={SearchTypeVehicule || []}
      queryError={
        VehiculeQueryList?.error
        || TypeVehicule?.error
        || deleteVehicleQuery?.error
        || SearchTypeVehicule?.error
        || getBuildingsListQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('vehicule.title')}</h1>
          {totalVehicle < vehiclePackage ? (
            <Link className={cn([styles.add, 'add col-1'])} to="/add_vehicules">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('vehicule.add')}</span>
            </Link>
          ) : (
            <Link className={cn([styles.noadd, 'add col-1'])} to="/add_vehicules">
              <span className="add_label">{t('vehicule.noadd')}</span>
            </Link>
          )}
        </div>
      </header>
      <div className={styles.table}>
        <div className="row">
          <Search
            onChange={handleSearch}
            className="mb-20"
          />
        </div>
      </div>
      {/* <div className={styles.filters}>
        <div className={styles.filtersToComplete}>
          {VehiculeQueryList?.data?.data?.vehicles?.map((building) => (
            <Button
              type="button"
              label={`${building.licensePlate} ${building.model}`}
              className={cn([styles.filter, buildingId === building.id
                ? styles.active
                : '', 'shadow-md'])}
              onClick={() => handleBuilding(building?.id)}
            />
          ))}
        </div>
      </div> */}
      <h2 className="title-info">
        {t('vehicule.title_info')}
        {' '}
        {vehiculeNombre >= 0 ? vehiculeNombre : <div className="loader" />}
      </h2>
      <div className={styles.table}>
        <Table
          columns={columns}
          isLoading={SearchTypeVehicule?.isLoading}
          data={SearchTypeVehicule || []}
          hasSort
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              onPageChange={onPageChange}
              totalCount={SearchTypeVehicule?.length || 0}
              currentPage={page}
              pageSize={10}
            />
        )}
        />
      </div>
      <div>
        <button type="button" className="link" onClick={() => navigateDashboards()}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contact.dashboard')}</span>
        </button>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </Layout>
  );
}
export default Monvehicule;
