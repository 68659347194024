/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-template */
/* eslint-disable no-loop-func */

/**
 * Method to download a file
 * @param {Blob} blob
 * @param {string} fileName
 * @param {string} type - default: application/pdf
 */
export const downloadFile = (blob, fileName, type = 'application/pdf') => {
  const data = URL.createObjectURL(blob, { type });
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(data);
  link.remove();
};

/**
 * Method to download a file csv
 * @param {Array} data
 * @param {string} header
 * @param {string} fileName
 * @param {string} type - default: text/csv
 */
export const downloadFileCsv = (data, headers, fileName) => {
  const separator = ';';
  const lineBreak = '\r\n';
  let csvContent = '';

  headers.forEach((header, index) => {
    csvContent += header.label;
    if (index !== headers.length - 1) {
      csvContent += separator;
    } else {
      csvContent += lineBreak;
    }
  });

  data.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent += value;
      if (index !== headers.length - 1) {
        csvContent += separator;
      } else {
        csvContent += lineBreak;
      }
    });
  });

  const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Method to download a file csv
 * @param {Array} data
 * @param {string} header
 * @param {string} fileName
 * @param {string} type - default: text/csv
 */
export const downloadFileAnalyse = (data, headers, fileName) => {
  const separator = ';';
  const lineBreak = '\r\n';
  let csvContent = '';

  headers.forEach((header, index) => {
    csvContent += header.label;
    if (index !== headers.length - 1) {
      csvContent += separator;
    } else {
      csvContent += lineBreak;
    }
  });

  data.forEach((item) => {
    item.forEach((itemparse) => {
      headers.forEach((header, index) => {
        let value = itemparse[header.key];
        if (header.cell) {
          value = header.cell({ original: item });
        }
        csvContent += value;
        if (index !== headers.length - 1) {
          csvContent += separator;
        } else {
          csvContent += lineBreak;
        }
      });
    });
  });
  const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
/**
* Method to download a file csv
* @param {Array} data
* @param {string} header
* @param {string} fileName
* @param {string} type - default: text/csv
*/
export const downloadCsvMonCarnet = (eaufroide, eauchaude, compteur, headers, fileName) => {
  const separator = ';';
  const lineBreak = '\r\n';
  let csvContent = '';
  let csvContent1 = '';
  let csvContent2 = '';
  let csvContent3 = '';

  headers.forEach((header, index) => {
    csvContent += header.label;
    if (index !== headers.length - 1) {
      csvContent += separator;
    } else {
      csvContent += lineBreak;
    }
  });

  eaufroide.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent1 += value;
      if (index !== headers.length - 1) {
        csvContent1 += separator;
      } else {
        csvContent1 += lineBreak;
      }
    });
  });

  eauchaude.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent2 += value;
      if (index !== headers.length - 1) {
        csvContent2 += separator;
      } else {
        csvContent2 += lineBreak;
      }
    });
  });

  compteur.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent3 += value;
      if (index !== headers.length - 1) {
        csvContent3 += separator;
      } else {
        csvContent3 += lineBreak;
      }
    });
  });

  csvContent += csvContent1 + csvContent2 + csvContent3 + lineBreak;

  const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// export const downloadCsvMonCarnet = (graf1, fileName) => {
//   const separator = ';';
//   const lineBreak = '\r\n';
//   let csvContent = '';

//   console.log(graf1);

//   // headers.forEach((header, index) => {
//   //   csvContent += header.label;
//   //   if (index !== headers.length - 1) {
//   //     csvContent += separator;
//   //   } else {
//   //     csvContent += lineBreak;
//   //   }
//   // });
//   graf1.forEach((item) => {
//     item.headers.forEach((header, index) => {
//       csvContent += header.label;
//       if (index !== item.headers.length - 1) {
//         csvContent += separator;
//       } else {
//         csvContent += lineBreak;
//       }
//     });

//     item.data.forEach((data) => {
//       data.headers.forEach((header, index) => {
//         let value = item[header.key];
//         if (header.cell) {
//           value = header.cell({ original: item });
//         }
//         csvContent += value;
//         if (index !== data.headers.length - 1) {
//           csvContent += separator;
//         } else {
//           csvContent += lineBreak;
//         }
//       });
//     });
//   });

//   // data.forEach((item) => {
//   //   headers.forEach((header, index) => {
//   //     let value = item[header.key];
//   //     if (header.cell) {
//   //       value = header.cell({ original: item });
//   //     }
//   //     csvContent += value;
//   //     if (index !== headers.length - 1) {
//   //       csvContent += separator;
//   //     } else {
//   //       csvContent += lineBreak;
//   //     }
//   //   });
//   // });

//   const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

//   const url = URL.createObjectURL(blob);

//   const link = document.createElement('a');
//   link.setAttribute('href', url);
//   link.setAttribute('download', fileName);

//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

/**
 * Method to download a file xls
 * @param {Array} data
 * @param {string} header
 * @param {string} fileName
 */
// function s2ab(s) {
//   const buf = new ArrayBuffer(s.length);
//   const view = new Uint8Array(buf);
//   for (let i = 0; i < s.length; i += 1) {
//     view[i] = s.charCodeAt(i) & 0xFF;
//   }
//   return buf;
// }

// export const downloadFileXls = (headers, data) => {
//   const worksheet = XLSX.utils.json_to_sheet({ data }, { header: headers.map((h) => h.label), skipHeader: true });
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
//   const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
//   const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   const fileName = 'data.xlsx';

//   if (navigator.msSaveBlob) {
//     // IE 10+
//     navigator.msSaveBlob(blob, fileName);
//   } else {
//     const link = document.createElement('a');
//     if (link.download !== undefined) {
//       const url = URL.createObjectURL(blob);
//       link.setAttribute('href', url);
//       link.setAttribute('download', fileName);
//       link.style.visibility = 'hidden';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   }
// const headers = header.map((h) => h.label);
// XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
// const file = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
// const blob = new Blob([s2ab(file)], { type: 'application/octet-stream' });
// const url = URL.createObjectURL(blob);
// const link = document.createElement('a');
// link.href = url;
// link.download = 'data.xlsx';
// document.body.appendChild(link);
// link.click();
// document.body.removeChild(link);

export default { downloadFile, downloadFileCsv };
