/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faChevronLeft, faFileArrowDown, faChevronRight, faSortUp, faSortDown, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import styles from './monCarnet.module.css';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';
import Pagination from '../../components/molecules/Pagination/Pagination';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import { deleteAnalyse, getAnalysesList, downloadAnalyse } from '../../services/analyses';
import { downloadCsvMonCarnet } from '../../utils/downloadFile';
import { getControlsList } from '../../services/controls';
import { getTemperature } from '../../services/monCarnet';
import { getInputsToComplete } from '../../services/inputs';
import Temperature from './Temperature';
import ColdTemperature from './ColdTemperature';
import Meter from './Meter';

function MonCarnet() {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState([]);
  const [sort, setSort] = useState('asc');
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  const [buildingId, setBuildingId] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);

  // const [dowloadInfo, setDowloadInfo] = useState([]);
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const getInputsToCompleteQuery = useQuery(['inputs', buildingId], () => getInputsToComplete({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    onSuccess: (response) => {
      if (buildingId === 0) {
        setBuildingId(response?.data?.buildingInputInfos[0]?.buildingId);
      }
    },
  });

  const AnalysisList = useQuery(
    ['analyses', page, search],
    () => getAnalysesList({
      page: 0,
      search,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
    {
      onSuccess: (response) => {
        setData(response?.data?.analyses);
        setInfo(response);
      },
    },
  );

  const deleteAnalysMutate = useMutation(deleteAnalyse, {
    onSuccess: () => {
      toast.success(t('analyses.delete_success'));
      setOpenModal(false);
      AnalysisList.refetch();
    },
  });

  const dowloaddoc = (datadoc) => {
    const url = window.URL.createObjectURL(new Blob([datadoc]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'document.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const handleBuilding = (id) => {
    setBuildingId(id);
  };
  const downloadAnalysMutate = useMutation(downloadAnalyse, {
    onSuccess: (response) => {
      dowloaddoc(response.data);
    },
  });
  const handleDelete = () => {
    setOpenModal(false);
    deleteAnalysMutate.mutate(false);
  };
  const couleur = (isPneumophila, quantity) => {
    if (quantity <= 10) {
      return styles.green;
    } else if (quantity < 1000 && isPneumophila === true) {
      return styles.orange;
    } else if (quantity >= 1000 && isPneumophila === true) {
      return styles.red;
    } else {
      return styles.orange;
    }
  };
  const displayPneumophila = (isPneumophila, quantity) => {
    if (quantity <= 10) {
      return 'non detectées';
    } else if (isPneumophila === true) {
      return 'Pneumophila';
    } else {
      return 'Non Pneumophila';
    }
  };

  const calculeRange = (pages, size) => {
    const start = (pages - 1) * size;
    const end = start + size;
    if (end > data?.length) {
      return { start, end };
    }
    return { start, end: end - 1 };
  };
  const { start, end } = calculeRange(page, 10);
  const dataPage = data?.slice(start, end);

  const sortarray = (arra, fiel) => {
    const array = arra.slice(0, arra.length);
    if (fiel === 'type') {
      if (sort === 'asc') {
        const sorted = array.sort((a, b) => a.type.localeCompare(b.type));
        setData(sorted);
        setSort('desc');
      }
      if (sort === 'desc') {
        const sorted = array.sort((a, b) => b.type.localeCompare(a.type));
        setData(sorted);
        setSort('asc');
      }
      if (sort === '') {
        const sorted = array.sort((a, b) => a.type.localeCompare(b.type));
        setData(sorted);
        setSort('desc');
      }
    }
    if (fiel === 'date') {
      if (sort === 'asc') {
        const sorted = array
          .sort((a, b) => a?.samplingDate.localeCompare(b?.samplingDate));
        setData(sorted);
        setSort('desc');
      }
      if (sort === 'desc') {
        const sorted = array
          .sort((a, b) => b?.samplingDate.localeCompare(a?.samplingDate));
        setData(sorted);
        setSort('asc');
      }
      if (sort === '') {
        setData(dataPage);
        setSort('desc');
      }
    }
  };
  const handleSearch = (e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  };

  const headers = [
    {
      key: 'batiment',
      label: 'Bâtiment',
    },
    {
      key: 'label',
      label: 'Titre',
    },
    {
      key: 'x',
      label: 'Date(x)',
      cell: (row) => new Date(row.original.x).toLocaleDateString(),
    },
    {
      key: 'y',
      label: 'Valeur(y)',
      cell: (row) => row.original.y,
    },
  ];
  const TemperatureQuery = useQuery(['etablissement'], () => getTemperature({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const graf1 = (datas) => {
    const array = [];
    datas.forEach((element) => {
      element.data.forEach((infos) => {
        array.push({
          batiment: context.choiceEstablishment.name,
          x: infos.x,
          y: infos.y,
          label: element.label,
        });
      });
    });
    return array;
  };

  const graf2 = (datas) => {
    const array = [];
    datas.forEach((element) => {
      element.data.forEach((infos) => {
        array.push({
          batiment: context.choiceEstablishment.name,
          x: infos.x,
          y: infos.y,
          label: element.label,
        });
      });
    });
    return array;
  };

  const graf3 = (datas) => {
    const array = [];
    datas.forEach((element) => {
      element.data.forEach((infos) => {
        array.push({
          batiment: context.choiceEstablishment.name,
          x: infos.x,
          y: infos.y,
          label: element.label,
        });
      });
    });
    return array;
  };

  const handleDowload = () => {
    const date = new Date();
    const title = `${context.choiceEstablishment.name}_${date.toLocaleDateString()}.csv`;
    // const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    downloadCsvMonCarnet(
      graf1(TemperatureQuery?.data?.data?.temps),
      graf2(TemperatureQuery?.data?.data?.temps),
      graf3(TemperatureQuery?.data?.data?.temps),
      headers,
      title,
    );
  };

  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      return;
    }
    setActiveIndex(index);
  };

  const getControlsListQuery = useQuery(['controls', page, search], () => getControlsList({
    page: 0,
    search,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('controls.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('controls.equipments')}`,
        accessor: 'countEquipments',
      },
      {
        Header: `${t('controls.control_type')}`,
        accessor: 'type.name',
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('carnet.title')}
        layout="table"
        queryError={
          AnalysisList.error
          || getControlsListQuery?.error
          || downloadAnalysMutate?.error
          || getInputsToCompleteQuery?.error
          || TemperatureQuery?.error
        }
      >
        <header className={styles.headers}>
          <div className={cn([styles.mb20, styles.rows])}>
            <button type="button" className={styles.links} onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className={styles.rows}>
            <h1 className="title">
              {t('carnet.title')}
            </h1>
          </div>
          <div className={styles.rows}>
            <button
              className={styles.adds}
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search className={styles.mb20} onChange={handleSearch} />
          </div>
          <div className={styles.filtersToComplete}>
            {getInputsToCompleteQuery?.data?.data?.buildingInputInfos.map((building) => (
              <Button
                key={building.buildingId}
                type="button"
                label={building.buildingName}
                className={cn([styles.filter, buildingId === building.buildingId
                  ? styles.active
                  : '', 'shadow-md'])}
                onClick={() => handleBuilding(building.buildingId)}
              />
            ))}
          </div>
        </header>
        {getInputsToCompleteQuery.isLoading ? (
          <div className="loader" />
        ) : (
          <>
            <div className={cn(['rows', styles.block])} id="temperature">
              <h3 className={styles.titles}>{t('carnet.temperature')}</h3>
              <div className={styles.table}>
                <h4 className={styles.title}>{t('carnet.hot_temperature')}</h4>
                <Temperature
                  buildingId={buildingId}
                />
              </div>
              <div className={styles.table}>
                <h4 className={styles.title}>{t('carnet.cold_temperature')}</h4>
                <ColdTemperature
                  buildingId={buildingId}
                />
              </div>
            </div>
            <div className={cn(['rows', styles.block])}>
              <h3 className={styles.titles}>{t('carnet.meter')}</h3>
              <div className={styles.table}>
                <Meter
                  buildingId={buildingId}
                />
              </div>
            </div>
            <div className={cn(['rows', styles.block])}>
              <h3 className={styles.titles}>{t('carnet.maintenance')}</h3>
              <div className={styles.table}>
                <Table
                  columns={columns}
                  isLoading={getControlsListQuery.isLoading}
                  data={getControlsListQuery?.data?.data?.controls || []}
                  hasSort
                  // pagination={(
                  //   <Pagination
                  //     previousLabel={(
                  //       <FontAwesomeIcon icon={faChevronLeft} />
                  //     )}
                  //     nextLabel={(
                  //       <FontAwesomeIcon icon={faChevronRight} />
                  //     )}
                  //     onPageChange={onPageChange}
                  //     totalCount={getControlsListQuery?.data?.data?.total || 0}
                  //     currentPage={page}
                  //     pageSize={10}
                  //   />
                  // )}
                />
              </div>
            </div>
            <div>
              <div className={cn(['rows', styles.block])}>
                <h3 className={styles.titles}>{t('carnet.analyse')}</h3>
                <div className={cn([styles.wrapper, styles.shadowSm])}>
                  <table className={styles.table}>
                    <thead className={styles.tableHeader}>
                      <tr className={styles.row}>
                        <th
                          className={cn([styles.cell, styles.cellHeader])}
                        >
                          {t('analyses.type')}
                          {' '}
                          {sort === 'asc' ? (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              onClick={() => sortarray(data, 'type')}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              onClick={() => sortarray(data, 'type')}
                            />
                          )}
                        </th>
                        <th className={cn([styles.cell, styles.cellHeader])}>
                          {t('analyses.date')}
                          {' '}
                          {sort === 'asc' ? (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              onClick={() => sortarray(data, 'date')}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              onClick={() => sortarray(data, 'date')}
                            />
                          )}
                        </th>
                        <th className={cn([styles.cellDoc, styles.cellHeaderDoc])}>{t('analyses.document')}</th>
                        <th className={cn([styles.cellAccordion, styles.cellHeader])}>{t('analyses.point')}</th>
                        <th className={cn([styles.cell, styles.cellHeader])}>{t('')}</th>
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {dataPage?.map((analyse) => (
                        <tr key={analyse.id} className={styles.row}>
                          <td className={styles.cell}>{t(`analyses.${analyse.type}`)}</td>
                          <td className={styles.cell}>{new Date(analyse?.samplingDate).toLocaleDateString()}</td>
                          <td className={styles.celldoc}>
                            <Button
                              type="button"
                              className={styles.actions}
                              label={<FontAwesomeIcon icon={faFileArrowDown} />}
                              title={t('trainings.download')}
                              onClick={() => downloadAnalysMutate.mutate(analyse.id)}
                            />
                          </td>
                          <td className={styles.cellaccordion}>
                            <div>
                              {analyse.type === 'legionella' ? (
                                <div className="accordion" id="accordionExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id={`heading${analyse.id}`}>
                                      <button
                                        className={cn([styles.accordion_button, 'accordion-button'])}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${analyse.id}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse${analyse.id}`}
                                        onClick={() => handleAccordionClick(analyse.id)}
                                      >
                                        {t('DETAIL')}
                                      </button>
                                    </h2>
                                    {analyse.analysisPoints?.map((point) => (
                                      <div
                                        id={`collapse${analyse.id}`}
                                        className={cn([styles.accordion, `accordion-collapse collapse ${activeIndex === analyse.id ? 'show' : ''}`])}
                                        aria-labelledby={`heading${analyse.id}`}
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className={cn([styles.body, 'accordion-body'])}>
                                          <div className="row">
                                            <div className={cn([styles.barre, 'col-12'])}>
                                              <div className="row">
                                                <div className={cn([styles.name, 'col-xl-6 col-s-6 col-md-4'])}>
                                                  <span>{point.name}</span>
                                                </div>
                                                <div
                                                  className={cn([couleur(point.isPneumophila, point.quantity),
                                                    styles.quantity, 'col-xl-2 col-s-6 col-md-2'])}
                                                >
                                                  {point.quantity < 10 ? (
                                                    <span>
                                                      &lt; 10
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      {point.quantity}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  className={cn([couleur(point.isPneumophila, point.quantity),
                                                    styles.pneumophila, 'col-xl-4 col-s-6 col-md-4'])}
                                                >
                                                  {displayPneumophila(point.isPneumophila, point.quantity)}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <div className="accordion" id="accordionExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id={`heading${analyse.id}`}>
                                      <button
                                        className={cn([styles.accordion_button, 'accordion-button'])}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${analyse.id}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse${analyse.id}`}
                                        onClick={() => handleAccordionClick(analyse.id)}
                                      >
                                        {t('DETAIL')}
                                      </button>
                                    </h2>
                                    {analyse.analysisPoints?.map((point) => (
                                      <div
                                        id={`collapse${analyse.id}`}
                                        className={cn([styles.accordion, `accordion-collapse collapse ${activeIndex === analyse.id ? 'show' : ''}`])}
                                        aria-labelledby={`heading${analyse.id}`}
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className={cn([styles.body, 'accordion-body'])}>
                                          <div className="row">
                                            <div className={cn([styles.barre, 'col-12'])}>
                                              <div className="row">
                                                <div className={cn([styles.name, 'col-xl-5 col-s-6 col-md-6'])}>
                                                  <span>{point.name}</span>
                                                </div>
                                                <div className={cn([styles.body, 'col-xl-6 col-s-6 col-md-6'])}>
                                                  <span>
                                                    {point.valid !== null && point.valid !== '0' ? (
                                                      <div className={styles.green}>
                                                        conforme
                                                      </div>
                                                    ) : (
                                                      <div className={styles.red}>
                                                        non conforme
                                                      </div>
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className={styles.pagination}>
                  <Pagination
                    className={styles.pagination}
                    previousLabel={(
                      <FontAwesomeIcon icon={faChevronLeft} />
                    )}
                    nextLabel={(
                      <FontAwesomeIcon icon={faChevronRight} />
                    )}
                    onPageChange={onPageChange}
                    totalCount={data?.length < 10 ? 0 : info?.data?.count || 0}
                    currentPage={page}
                    pageSize={10}
                    numberElementsDisplayed={data?.length < 10 ? 0 : 10}
                  />
                </div>
              </div>
              <footer className="footer">
                <button type="button" className="link" onClick={() => navigate(-1)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>{t('retour au tableau de bord')}</span>
                </button>
              </footer>
            </div>
          </>
        )}
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">
          {t('global.modal_content')}
        </p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}
export default MonCarnet;
