/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faPlus,
  faChevronLeft,
  faChevronRight,
  faScrewdriverWrench,
  faBook,
  faFileContract,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons';
// Components
import useAppContext from '../../store/useAppContext';
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
// import Search from '../../components/molecules/Search/Search';
// Hooks
// Services
import styles from './Calendar.module.css';
import { getCalendarList } from '../../services/calendar';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Calendar() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [context] = useAppContext();
  // States
  const [openModal, setOpenModal] = useState(false);
  const currentYear = new Date().getFullYear();
  const [displayYear, setDisplayYear] = useState(currentYear);
  const [tasks, setTasks] = useState([]);

  // API Calls
  const getCalendarListQuery = useQuery('calendar', () => getCalendarList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setTasks(data?.data?.calendar);
    },
  });

  // const handleModal = (selectedControlToDelete) => {
  //   setOpenModal(true);
  //   setControlToDelete(selectedControlToDelete);
  // };

  const handlePrevYear = () => {
    if ((currentYear - 1) < displayYear) {
      setDisplayYear(displayYear - 1);
    }
  };

  const handleNextYear = () => {
    if (currentYear + 5 > displayYear) {
      setDisplayYear(displayYear + 1);
    }
  };

  const calendar = [
    {
      id: 1,
      label: `Janvier ${displayYear}`,
    },
    {
      id: 2,
      label: `Février ${displayYear}`,
    },
    {
      id: 3,
      label: `Mars ${displayYear}`,
    },
    {
      id: 4,
      label: `Avril ${displayYear}`,
    },
    {
      id: 5,
      label: `Mai ${displayYear}`,
    },
    {
      id: 6,
      label: `Juin ${displayYear}`,
    },
    {
      id: 7,
      label: `Juillet ${displayYear}`,
    },
    {
      id: 8,
      label: `Août ${displayYear}`,
    },
    {
      id: 9,
      label: `Septembre ${displayYear}`,
    },
    {
      id: 10,
      label: `Octobre ${displayYear}`,
    },
    {
      id: 11,
      label: `Novembre ${displayYear}`,
    },
    {
      id: 12,
      label: `Décembre ${displayYear}`,
    },
  ];

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('calendrier.title')}
        queryError={
          getCalendarListQuery?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {t('calendrier.title')}
            </h1>
            {/* <Link className="add" to="/">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('calendrier.add')}</span>
            </Link> */}
          </div>
          {/* <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div> */}
        </header>
        {getCalendarListQuery?.isLoading ? (
          <div className="loader" />
        ) : (
          <div>
            <div className={styles.agenda_container}>
              <div className={styles.navigation}>
                <div style={{ display: 'flex' }}>
                  <button type="button" className={styles.navigationButton} onClick={handlePrevYear}>
                    {currentYear - 1 < displayYear
                      ? (
                        <FontAwesomeIcon icon={faChevronLeft} />
                      ) : (
                        <FontAwesomeIcon style={{ visibility: 'hidden' }} icon={faChevronLeft} />
                      )}
                  </button>
                  <h2 className={styles.year}>{displayYear}</h2>
                  <button type="button" className={styles.navigationButton} onClick={handleNextYear}>
                    {currentYear + 5 > displayYear
                      ? (
                        <FontAwesomeIcon icon={faChevronRight} />
                      ) : (
                        <FontAwesomeIcon style={{ visibility: 'hidden' }} icon={faChevronRight} />
                      )}
                  </button>
                </div>
                {context?.choiceBooklet === 3
                  ? (
                    <div className={styles.legend}>
                      <div>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: 'red' }} icon={faScrewdriverWrench} />
                        <p style={{ color: 'red' }}>{t('vehicule.entretien')}</p>
                      </div>
                      <div>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: 'blue' }} icon={faBook} />
                        <p style={{ color: 'blue' }}>{t('vehicule.controles')}</p>
                      </div>
                      <div>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: 'green' }} icon={faFileContract} />
                        <p style={{ color: 'green' }}>{t('vehicule.contracts')}</p>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.legend}>
                      <div>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: 'red' }} icon={faCircleUser} />
                        <p style={{ color: 'red' }}>{t('Lifted.noSociety')}</p>
                      </div>
                      <div>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: 'blue' }} icon={faCircleUser} />
                        <p style={{ color: 'blue' }}>{t('societies.organization')}</p>
                      </div>
                      <div>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: 'green' }} icon={faCircleUser} />
                        <p style={{ color: 'green' }}>{t('societies.technician')}</p>
                      </div>
                    </div>
                  )}
              </div>
              {calendar?.map((months) => (
                <div key={months.id} className={styles.mois_container}>
                  <h2 className={styles.mois}>{months.label}</h2>
                  <ul className={styles.taches_list}>
                    <li className={styles.taches}>
                      <div className={styles.taches_container}>
                        {tasks?.map((taches) => (
                          <div key={taches.id} className={styles.tache}>
                            {taches?.dates?.map((date) => (
                              <div key={date.date}>
                                {new Date(date.date).getMonth() + 1 === months.id
                                  && new Date(date.date).getFullYear() === displayYear && (
                                    <div
                                      className={
                                        (new Date(date.date) <= new Date())
                                          ? styles.tache_last
                                          : styles.tache_content
                                      }
                                      onClick={() => (taches.click === 1 ? navigateDashboards() : null)}
                                      role="button"
                                      tabIndex={0}
                                      onKeyDown={() => (taches.click === 1 ? navigateDashboards() : null)}
                                      style={{
                                        backgroundColor: (taches.completed === false
                                        && new Date(date.date) <= new Date())
                                          ? ('#FF6969') : (''),
                                      }}
                                    >
                                      <div
                                        className={styles.tache_label}
                                        style={{ color: taches.click === 1 ? 'green' : null }}
                                      >
                                        {taches.type === 'contract' ? (
                                          <FontAwesomeIcon
                                            style={{ marginRight: '5px', color: 'green' }}
                                            icon={faFileContract}
                                            title={taches.title}
                                          />
                                        ) : null}
                                        {taches.type === 'control' ? (
                                          <FontAwesomeIcon
                                            style={{ marginRight: '5px', color: 'blue' }}
                                            icon={faBook}
                                            title={taches.title}
                                          />
                                        ) : null}
                                        {taches.type === 'interview' ? (
                                          <FontAwesomeIcon
                                            style={{ marginRight: '5px', color: 'red' }}
                                            icon={faScrewdriverWrench}
                                            title={taches.title}
                                          />
                                        ) : null}
                                        {taches?.societyInCharge?.type === null && taches.societyInCharge === null ? (
                                          <FontAwesomeIcon
                                            style={{ marginRight: '5px', color: 'green' }}
                                            icon={faCircleUser}
                                            title={taches.title}
                                          />
                                        ) : null}
                                        {taches?.societyInCharge?.type === 'organization' ? (
                                          <FontAwesomeIcon
                                            style={{ marginRight: '5px', color: 'blue' }}
                                            icon={faCircleUser}
                                            title={taches.title}
                                          />
                                        ) : null}
                                        {taches?.societyInCharge?.type === 'technician' ? (
                                          <FontAwesomeIcon
                                            style={{ marginRight: '5px', color: 'red' }}
                                            icon={faCircleUser}
                                            title={taches.title}
                                          />
                                        ) : null}
                                        {context?.choiceBooklet === 3 ? (
                                          `${taches?.vehicle?.brand} ${taches?.vehicle?.model}
                                          ${taches?.vehicle?.licensePlate}`
                                        ) : (
                                          taches.title
                                        )}
                                      </div>
                                      <div className={styles.tache_date}>
                                        {context?.choiceBooklet === 3 ? (
                                          <p>{taches?.control?.name}</p>
                                        ) : (
                                          <p>{taches?.building?.name}</p>
                                        )}
                                        <p>{new Date(date.date).toLocaleDateString('fr-FR')}</p>
                                      </div>
                                    </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
            <footer>
              <button type="button" className="link" onClick={() => navigateDashboards()}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{t('global.dashboard')}</span>
              </button>
            </footer>
          </div>
        )}
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
          // onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Calendar;
