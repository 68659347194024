/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
// import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { GetVehicle, typeVehicleList } from '../../services/vehicules';
import { getBuildingsList, getEstablishmentsList } from '../../services/structures';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';
import cn from '../../utils/cn';
import styles from './Exchange.module.css';

function ExchangeVehicule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const { page } = useTableFiltersPagination({ initialFilters: {} });
  const [vehicles, setVehicule] = useState([]);
  const [context] = useAppContext();
  // const [buldings, setBuldings] = useState([]);

  // const goBackUrl = (msg) => {
  //   navigate('/Vehicules');
  //   toast.success(msg);
  // };

  // const putContact = useMutation(EditVehicle, {
  //   onSuccess: (res) => {
  //     goBackUrl(t('contract.succes_save_modif'));
  //   },
  //   onError: (err) => {
  //     toast.error(t('contract.erreur_save_modif'));
  //   },
  // });

  const buildingsListMutate = useQuery(['buildings', page], () => getBuildingsList({
    page: 0,
    establishmentId: context.choiceEstablishment.id,
    bookletId: 3,
  }));

  const establishmentsListMutate = useQuery(['establishments', page], () => getEstablishmentsList({
    page: 0,
    bookletId: 3,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const initialValues = {
    licensePlate: '',
    buildingId: '',
    type_vehicule: '',
    brand: '',
    model: '',
    currentMileage: '',
    firstRegistrationDate: '',
    lastMaintenanceMileage: null,
    tireNumber: '',
    lastControlDate: '',
    lastMaintenanceDate: '',
    serviceIntervalMonths: '',
    serviceIntervalMileage: '',
    hasWinterTires: true,
    hasTailgate: true,
    isActive: true,
    newBuldingId: '',
    newEtablishmentId: '',
  };
  const validationSchema = Yup.object({
    licensePlate: Yup.string(),
    buildingId: Yup.string(),
    type_vehicule: Yup.string(),
    brand: Yup.string(),
    model: Yup.string(),
    currentMileage: Yup.string(),
    firstRegistrationDate: Yup.string(),
    lastMaintenanceMileage: Yup.number().nullable(),
    tireNumber: Yup.number(),
    lastControlDate: Yup.string(),
    lastMaintenanceDate: Yup.string(),
    serviceIntervalMonths: Yup.string(),
    serviceIntervalMileage: Yup.string(),
    hasTailgate: Yup.bool(),
    hasWinterTires: Yup.bool(),
    isActive: Yup.bool(),
    newBuldingId: Yup.string(),
    newEtablishmentId: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      // const config = {
      //   id: urlParams.id,
      //   data: values,
      // };
      // putContact.mutate(config);
    },
  });

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);

  const typeVehicleListMutate = useQuery(['typeVehicule', page], () => typeVehicleList({
    page: 0,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const GetVehicleMutate = useMutation(GetVehicle, {
    onSuccess: (res) => {
      const vehicules = typeVehicleListMutate.data?.data?.vehicleTypes.find((item) => item.id === res.data.vehicleTypeId);
      formik.setFieldValue('licensePlate', res?.data?.licensePlate);
      formik.setFieldValue('buildingId', res?.data?.buildingId);
      formik.setFieldValue('vehicleTypeId', vehicles);
      formik.setFieldValue('brand', res?.data?.brand);
      formik.setFieldValue('model', res?.data?.model);
      formik.setFieldValue('currentMileage', res?.data?.currentMileage);
      // formik.setFieldValue('firstRegistrationDate', res?.data?.firstRegistrationDate?.date);
      formik.setFieldValue('lastMaintenanceMileage', res?.data?.lastMaintenanceMileage);
      formik.setFieldValue('tireNumber', res?.data?.tireNumber);
      // formik.setFieldValue('lastControlDate', res?.data?.lastControlDate?.date);
      // formik.setFieldValue('lastMaintenanceDate', res?.data?.lastMaintenanceDate?.date);
      formik.setFieldValue('serviceIntervalMonths', res?.data?.serviceIntervalMonths);
      formik.setFieldValue('serviceIntervalMileage', res?.data?.serviceIntervalMileage);
      formik.setFieldValue('hasWinterTires', res?.data?.hasWinterTires);
      formik.setFieldValue('hasTailgate', res?.data?.hasTailgate);
      formik.setFieldValue('isActive', res?.data?.isActive);
      formik.setFieldValue('hasWinterTires', res?.data?.hasWinterTires);
      formik.setFieldValue('hasTailgate', res?.data?.hasTailgate);
      formik.setFieldValue('isActive', res?.data?.isActive);
      setVehicule(vehicules);
    },
  });

  useEffect(() => {
    GetVehicleMutate.mutate(urlParams.id);
  }, []);

  return (
    <Layout
      title={t('vehicule.modifvehicule')}
      queryError={
       GetVehicleMutate?.error
      }
      loading={GetVehicleMutate.isLoading}
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate('/vehicule')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('vehicule.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('vehicule.exchangeTitle')}</h1>
          </div>
        </header>
      </div>
      <form className={cn(['form shadow-sm', styles.form])} onSubmit={formik.handleSubmit} loading={GetVehicleMutate.isLoading}>
        <div className={cn([styles.title, 'form_group'])}>
          <label htmlFor="licensePlate" className="form_label">
            {t('vehicule.licensePlate')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.licensePlate}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="type_vehicule" className="form_label">
            {t('vehicule.type_vehicule')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {vehicles?.label}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="model" className="form_label">
            {t('vehicule.model')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.model}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="brand" className="form_label">
            {t('vehicule.brand')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.brand}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="currentMileage" className="form_label">
            {t('vehicule.currentMileage')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.currentMileage}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="firstRegistrationDate" className="form_label">
            {t('vehicule.date_premiere_mise_circulation')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {new Date(GetVehicleMutate.data?.data?.firstRegistrationDate?.date).toLocaleDateString()}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="lastControlDate" className="form_label">
            {t('vehicule.date_dernire_revision')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {new Date(GetVehicleMutate.data?.data?.lastControlDate?.date).toLocaleDateString()}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="lastMaintenanceMileage" className="form_label">
            {t('vehicule.kilometrage_dernier_entretien')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.lastMaintenanceMileage}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="tireNumber" className="form_label">
            {t('vehicule.nmb_pneu')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.tireNumber}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="lastMaintenanceDate" className="form_label">
            {t('vehicule.date_dernire_revision')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {new Date(GetVehicleMutate.data?.data?.lastMaintenanceDate?.date).toLocaleDateString()}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="serviceIntervalMonths" className="form_label">
            {t('vehicule.periodicite__mois_revision')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.serviceIntervalMonths}
          </span>
        </div>
        <div className="form_group">
          <label htmlFor="serviceIntervalMileage" className="form_label">
            {t('vehicule.periodicite__km_revision')}
            :
          </label>
          <br />
          <span className={styles.gras}>
            {GetVehicleMutate.data?.data?.serviceIntervalMileage}
          </span>
        </div>
        <div className={cn([styles.form_groups, 'form_group'])}>
          <Checkbox
            label={t('vehicule.hasWinterTires')}
            id="hasWinterTires"
            name="hasWinterTires"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('hasWinterTires', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.hasWinterTires === true}
            value={formik.values.hasWinterTires ? true : false}
            disabled
          />
          {formik.touched.hasWinterTires && formik.errors.hasWinterTires ? (
            <div className="form_error">{formik.errors.hasWinterTires}</div>
          ) : null}
        </div>
        <div className={cn([styles.form_groups, 'form_group'])}>
          <Checkbox
            label={t('vehicule.hasTailgate')}
            id="hasTailgate"
            name="hasTailgate"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('hasTailgate', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.hasTailgate === true}
            value={formik.values.hasTailgate ? true : false}
            disabled
          />
          {formik.touched.hasTailgate && formik.errors.hasTailgate ? (
            <div className="form_error">{formik.errors.hasTailgate}</div>
          ) : null}
        </div>
        <div className={cn([styles.form_groups, 'form_group'])}>
          <Checkbox
            label={t('vehicule.active')}
            id="isActive"
            name="isActive"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('isActive', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.isActive === true}
            value={formik.values.isActive ? true : false}
            disabled
          />
          {formik.touched.isActive && formik.errors.isActive ? (
            <div className="error">{formik.errors.isActive}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Select
            name="newEtablishmentId"
            id="newEtablishmentId"
            label={t('vehicule.new_building')}
            options={
              establishmentsListMutate?.data?.data?.establishments?.map(
                (option) => ({ ...option, label: option.name, value: option.id }),
              )
            }
            value={establishmentsListMutate?.data?.data?.establishments?.find((option) => option.value === formik.values.newEtablishmentId)}
            onChange={(option) => formik.setFieldValue('newEtablishmentId', option.value)}
            required
            valueInput={formik.values.newEtablishmentId}
            loading={establishmentsListMutate.isLoading}
          />
          {formik.errors.newEtablishmentId && formik.touched.newEtablishmentId ? (
            <div className="error">
              {t(formik.errors.newEtablishmentId)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Select
            name="newBuldingId"
            type="text"
            label={t('vehicule.new_flotte')}
            value={
              mapForSelect(buildingsListMutate?.data?.data?.buildings).find(
                (item) => item.value === formik.values.newBuldingId,
              )
            }
            options={mapForSelect(buildingsListMutate?.data?.data?.buildings)}
            onChange={(value) => formik.setFieldValue('newBuldingId', value.value)}
            required
            valueInput={formik.values.newBuldingId}
            loading={buildingsListMutate.isLoading}
          />
          {formik.errors.newBuldingId && formik.touched.newBuldingId ? (
            <div className="error">
              {t(formik.errors.newBuldingId)}
            </div>
          ) : null }
        </div>
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('vehicule.exchange')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('vehicule.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default ExchangeVehicule;
