// Views
import Home from '../views/Home/Home';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import Equipments from '../views/Equipments/Equipments';
import AddEquipment from '../views/AddEquipment/AddEquipment';
import EquipmentsFamilies from '../views/EquipmentsFamilies/EquipmentsFamilies';
import AddEquipmentFamily from '../views/AddEquipmentFamily/AddEquipmentFamily';
import Controls from '../views/Controls/Controls';
import AddControl from '../views/AddControl/AddControl';
import ControlTypes from '../views/ControlTypes/ControlTypes';
import AddControlType from '../views/AddControlType/AddControlType';
import BuildingTemplates from '../views/BuildingTemplates/BuildingTemplates';
import AddBuildingTemplate from '../views/AddBuildingTemplate/AddBuildingTemplate';
import Structures from '../views/Structures/Structures';
import AddStructure from '../views/AddStructure/AddStructure';
import Establishments from '../views/Establishments/Establishments';
import AddEstablishment from '../views/AddEstablishment/AddEstablishment';
import Users from '../views/Users/Users';
import AddUser from '../views/AddUser/AddUser';
import Trainings from '../views/Trainings/Trainings';
import AddTraining from '../views/AddTraining/AddTraining';
import ChoiceBooklet from '../views/ChoiceBooklet/ChoiceBooklet';
import Inputs from '../views/Inputs/Inputs';
import Dashboard from '../views/Dashboard/Dashboard';
import AddBuilding from '../views/AddBuilding/AddBuilding';
import DocumentDeposit from '../views/DocumentDeposit/DocumentDeposit';
import AddEquipmentLightningSecurity from '../views/AddEquipmentLightningSecurity/AddEquipmentLightningSecurity';
import Interventions from '../views/Interventions/Interventions';
import AddIntervention from '../views/AddIntervention/AddIntervention';
import FillingReports from '../views/FillingReports/FillingReports';
import AddFillingReports from '../views/AddFillingReport/AddFillingReport';
import InputSafetyLightingEquipment from '../views/InputSafetyLightingEquipment/InputSafetyLightingEquipment';
import HistoricInputs from '../views/HistoricInputs/HistoricInputs';
import DocumentCategorie from '../views/DocumentCategorie/DocumentBase';
import DocumentSousCategorie from '../views/DocumentSousCategorie/DocumentBaseSousCategorie';
import DocumentaireBase from '../views/DocumentaireBaseItem/DocumentaireBaseItem';
import AddDocument from '../views/AddDocument/AddDocument';
import EditUser from '../views/EditUser/EditUser';
import ArchivesDocumentaire from '../views/ArchiveDocumentaire/ArchiveDocument';
import AddContact from '../views/AddContact/AddContact';
import AllContact from '../views/Contact/Contact';
import Analyse from '../views/Analyse/Analyse';
import AddAnalyse from '../views/AddAnalyse/AddAnalyse';
import Society from '../views/Society/Society';
import AddSociety from '../views/AddSociety/AddSociety';
import NewSociety from '../views/AddNewSociety/AddNewSociety';
import Vacation from '../views/Vacation/Vacantion';
import AddVacation from '../views/AddVacation/AddVacantion';
import Notification from '../views/NotificationDashbord/NotificationDashbord';
import AddNotification from '../views/AddNotification/AddNotification';
import NotificationListAdmin from '../views/NotificationAdminList/NotificationAdminList';
import Contract from '../views/Contract/Contracts';
import AddContract from '../views/AddContract/AddContract';
import Amendement from '../views/Amendement/Amendement';
import AddAmendement from '../views/AddAmendement/AddAmendement';
import EditContract from '../views/EditContract/EditContract';
import AdminAddContract from '../views/AdminAddContract/AdminAddContract';
import AdminContract from '../views/AdminContract/AdminContract';
import Registre from '../views/Registry/Registry';
import ObservationsReminng from '../views/ObservationRemaining/ObservationRemaining';
import ObservationsLifted from '../views/ObservationLifted/ObservationLifted';
import Finish from '../views/finishObservation/finishObservation';
import EditObservation from '../views/EditObservation/EditObservation';
import FinishDeskshop from '../views/DeskshopObservation/DeskshopObservation';
import ShareObservation from '../views/ShareObservation/ShareObservation';
import ShareAllObs from '../views/ShareAllObs/ShareAllObservation';
import FinishAll from '../views/finishObservation/finishAllObservation';
// import MobileObsRemining from '../views/MobileObsRemining/MobileObsRemining';
import EditHistoricInput from '../views/EditHistoricInput/EditHistoricInput';
import Picture from '../views/Picture/Picture';
import InternalLifting from '../views/InternalLifting/InternalLifting';
import Signature from '../views/Signature/Signature';
import SocietyLifted from '../views/SocietyLifted/SocietyLifted';
import Dairy from '../views/Dairy/Dairy';
import EditDairies from '../views/EditDairies/EditDiaries';
import AddDairy from '../views/AddDairies/AddDairies';
import OneInputs from '../views/Oneinput/Oneinput';
import EditAmement from '../views/EditAmendement/EditAmendement';
import MonVehicule from '../views/MonVehicule/MonVehicule';
import EditVehicule from '../views/EditVehicule/EditVehicule';
import AddVehicule from '../views/AddVehicles/AddVehicles';
import ChecklistVehicule from '../views/ChecklistVechicle/ChecklistVechicle';
import DepotDoc from '../views/DepotDoc/DepotDoc';
import DepotDocVehicule from '../views/DepotDocVehicule/DepotDocVehicule';
import DepotDocAdmin from '../views/DepotDocAdmin/DepotDocAdmin';
import DashbordUser from '../views/Dashboard/DashbordUser';
import Calendar from '../views/Calendar/Calendar';
import Indicateur from '../views/Indicateur/Indicateur';
import MonCarnet from '../views/MonCarnet/MonCarnet';
import ContactVehicule from '../views/ContractVehicule/ContractVehicule';
import ExchangeVehicule from '../views/ExchangeVehicule/ExchangeVehicule';
import PermisFeu from '../views/PermisFeu/PermisFeu';
import PermisFeuEdit from '../views/PermisFeuEdit/PermisFeuEdit';
import EditDocument from '../views/EditDocument/EditDocument';
import Carlist from '../views/CarList/CarLIst';
import ChoiceEstablishmentSanitaire from '../views/ChoiceEstablishmentSanitaire/ChoiceEstablishmentSanitaire';
import ChoiceEstablishmentSecurite from '../views/ChoiceEstablishmentSecurite/ChoiceEstablishmentSecurite';
import ChoiceEstablishmentVehicle from '../views/ChoiceEstablishmentVehicle/ChoiceEstablishmentVehicle';
import DossierBase from '../views/DossierBase/DossierBase';
import AddDossier from '../views/AddDossier/AddDossier';
import Error from '../views/Error/Error';
import Version from '../views/Version/Version';
import Error404 from '../views/Error404/Error404';
import AddContractVehicle from '../views/AddContractVehicle/AddContractVehicle';
import Cron from '../views/Cron/Cron';
// Utils
import { USERS_ROLES } from '../utils/constant';

export const publicRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/inputs/:establishmentId/:buldingId/:inputsid',
    element: <OneInputs />,
  },
  {
    path: '/version',
    element: <Version />,
  },
  {
    path: '/error/',
    element: <Error />,
  },
  {
    path: '/Error404',
    element: <Error404 />,
  },

];

export const privateRoutes = [
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/choix-carnet',
    element: <ChoiceBooklet />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/version',
    element: <Version />,
  },
  {
    path: '/error/',
    element: <Error />,
  },
  {
    path: '/Error404',
    element: <Error404 />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/inputs/:establishmentId/:buldingId/:inputsid',
    element: <OneInputs />,
  },
  {
    path: '/sanitaire/choix-etablissement',
    element: <ChoiceEstablishmentSanitaire />,
  },
  {
    path: '/securite/choix-etablissement',
    element: <ChoiceEstablishmentSecurite />,
  },
  {
    path: '/vehicule/choix-etablissement',
    element: <ChoiceEstablishmentVehicle />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/:booklet/:etablissement/dashboards',
    element: <DashbordUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/depot-documents',
    element: <DocumentDeposit />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/rapports',
    element: <FillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/rapports/:action',
    element: <AddFillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/rapports/:action/:id',
    element: <AddFillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/vehicule/document_attente',
    element: <FillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/vehicule/document_attente/:action',
    element: <AddFillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/vehicule/document_attente/:action/:id',
    element: <AddFillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/formations',
    element: <Trainings />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/formations/:action',
    element: <AddTraining />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/formations/:action/:id',
    element: <AddTraining />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/saisies',
    element: <Inputs />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/saisies/historique',
    element: <HistoricInputs />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/saisies/historique/:action/:id',
    element: <EditHistoricInput />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/saisies/eclairage-securite/:inputId',
    element: <InputSafetyLightingEquipment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/interventions',
    element: <Interventions />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/intervention/:id',
    element: <Interventions />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/interventions/:action',
    element: <AddIntervention />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/interventions/:action/:id',
    element: <AddIntervention />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/safetyLightingEquipments/:action/:id',
    element: <AddEquipmentLightningSecurity />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/safetyLightingEquipments/:action/',
    element: <AddEquipmentLightningSecurity />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/structures',
    element: <Structures />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/etablissements',
    element: <Establishments />,
  },
  // TODO : NOT STANDART WAY TO NESTED ROUTES, NEED REFACTO !
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/etablissements/:action/:id',
    element: <AddEstablishment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/etablissements/add',
    element: <AddEstablishment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/etablissements/:establishmentParentId/batiments/add',
    element: <AddBuilding />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/etablissements/:establishmentParentId/batiments/:action/:id',
    element: <AddBuilding />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/equipements',
    element: <Equipments />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/equipements-familles',
    element: <EquipmentsFamilies />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/controles',
    element: <Controls />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/controles-types',
    element: <ControlTypes />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/batiments-modeles',
    element: <BuildingTemplates />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/users',
    element: <Users />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/users/add',
    element: <AddUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/users/:action/:id',
    element: <AddUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/equipements-familles/add',
    element: <AddEquipmentFamily />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/equipements-familles/:action/:id',
    element: <AddEquipmentFamily />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/equipements/add',
    element: <AddEquipment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/equipements/:action/:id',
    element: <AddEquipment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/structures/add',
    element: <AddStructure />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/structures/:structure/:action/:id',
    element: <AddStructure />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/controles/add',
    element: <AddControl />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/controles/:action/:id',
    element: <AddControl />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/controles-types/add',
    element: <AddControlType />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/controles-types/:action/:id',
    element: <AddControlType />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/batiments-modeles/add',
    element: <AddBuildingTemplate />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/batiments-modeles/:action/:id',
    element: <AddBuildingTemplate />,
  },
  {
    availableToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/',
    element: <DocumentCategorie />,
  },
  {
    availableToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:id',
    element: <DocumentSousCategorie />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categorieid/dossier/:folderId',
    element: <DocumentaireBase />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categorieid/:typeid/',
    element: <DocumentaireBase />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categorieid/:typeid/:vehiculeId',
    element: <DocumentaireBase />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/document/add/:categorieid/dossier/:folderid/',
    element: <AddDocument />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/document/add/:categorieid/:typeid/',
    element: <AddDocument />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categorieid/:typeid/:vehiculeId',
    element: <DocumentaireBase />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/document/add/:categorieid/:typeid/:vehiculeId',
    element: <AddDocument />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/Edituser/:id',
    element: <EditUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/archives',
    element: <ArchivesDocumentaire />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/contact',
    element: <AddContact />,
  },
  {
    avalaibleToRole: [USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contacts',
    element: <AllContact />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/analyse',
    element: <Analyse />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/addanalyse',
    element: <AddAnalyse />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/society',
    element: <Society />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/society/:action/:id',
    element: <AddSociety />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/society/:action',
    element: <AddSociety />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/addSociety',
    element: <NewSociety />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/vacation/:id',
    element: <Vacation />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/vacation/edit/:id',
    element: <AddVacation />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/Edituser/:id',
    element: <EditUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/notification',
    element: <Notification />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/addNotification',
    element: <AddNotification />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/notificationlist/admin',
    element: <NotificationListAdmin />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contracts',
    element: <Contract />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contracts/add',
    element: <AddContract />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contractsVehicule/add',
    element: <AddContractVehicle />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/amendement/:id/:society',
    element: <Amendement />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: 'edit/amendements/:contractid/:amandementid',
    element: <EditAmement />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/amendement/add/:id',
    element: <AddAmendement />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contracts/edit/:id',
    element: <EditContract />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contractsVehicule/edit/:id',
    element: <AddContractVehicle />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contracts/admin',
    element: <AdminAddContract />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contracts/:viewadmin/:id',
    element: <AdminContract />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/registre',
    element: <Registre />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/Observations/remaining/:id',
    element: <ObservationsReminng />,
  },
  // {
  //   avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
  //   path: '/Observations/remainingMobile/:id',
  //   element: <MobileObsRemining />,
  // },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/Observations/lifted/:id',
    element: <ObservationsLifted />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/Observations/finished/:id',
    element: <FinishDeskshop />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/observations/edit/:id',
    element: <EditObservation />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/observations/finishdeskshop/:id',
    element: <Finish />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/observations/share/:id',
    element: <ShareObservation />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/observations/shareall/:id',
    element: <ShareAllObs />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/observations/finishall/:id',
    element: <FinishAll />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/picture/:id',
    element: <Picture />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/internal_lifting/:id',
    element: <InternalLifting />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/signature/:id',
    element: <Signature />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/external_lifting/:id',
    element: <SocietyLifted />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/Dairy',
    element: <Dairy />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/journal/add',
    element: <AddDairy />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/jounal/edit/:id',
    element: <EditDairies />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/vehicule',
    element: <MonVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/vosvehicules',
    element: <Carlist />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/EditVehicule/:id',
    element: <EditVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/add_vehicules',
    element: <AddVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/checklistVehicule/:id',
    element: <ChecklistVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/depot_documents',
    element: <DepotDocVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/depot-document/admin',
    element: <DepotDocAdmin />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/depot_document',
    element: <DepotDoc />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/calendar',
    element: <Calendar />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/indicateur',
    element: <Indicateur />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/MonCarnet',
    element: <MonCarnet />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contratsVehicule',
    element: <ContactVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/contratsVehicule/:id',
    element: <ContactVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN_GROUP],
    path: '/tranfert/Vehicule/:id',
    element: <ExchangeVehicule />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/permisfeu',
    element: <PermisFeu />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/permisfeu/edit/:id',
    element: <PermisFeuEdit />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/document/edit/:id',
    element: <EditDocument />,
  },
  {
    avalaibleToRole: [USERS_ROLES.SUPER_ADMIN],
    path: '/cron',
    element: <Cron />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categoryid/dossier',
    element: <DossierBase />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categoryid/dossier/add',
    element: <AddDossier />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ADMIN_GROUP],
    path: '/base-documentaire/:categoryid/dossier/edit/:folderId',
    element: <AddDossier />,
  },
];
