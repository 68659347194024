/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// Components
import Picto from '../../atoms/Picto/Picto';
import Divider from '../../atoms/Divider/Divider';
import List from '../List/List';
import ListItem from '../../atoms/ListItem/ListItem';
import Permissions from '../Permissions/Permissions';
// Hooks
import useAppContext from '../../../store/useAppContext';
// Utils
import styles from './Navigation.module.css';
import cn from '../../../utils/cn';
import {
  INTERVENTIONS, INPUTS, INTERVENTIONSVEHICULE,
} from '../../../utils/constant';

function NavigationUser({ isCollapsed }) {
  // States
  const [accordionInterventionsClosed, setAccordionInterventionsClosed] = useState(false);
  // Hooks
  const { t } = useTranslation();
  const [context] = useAppContext();

  const isBookletChoosed = !!context?.choiceBooklet;
  const isSecurityBooklet = context.choiceBooklet === 2;
  const isVehicleBooklet = context.choiceBooklet === 3;
  const isSanitaryBooklet = context.choiceBooklet === 1;
  const isEstablishmentChoosed = !!context?.choiceEstablishment;

  const interventions = [
    {
      link: INTERVENTIONS.INTERVENTIONS_LINK,
      picto: INTERVENTIONS.INTERVENTIONS_PICTO,
      label: 'navigation.interventions',
      disabled: !(Permissions({ permission: 'INTERVENTION_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.DAIRY_LINK,
      picto: INTERVENTIONS.DAIRY_PICTO,
      label: 'navigation.dairy',
      disabled: !(Permissions({ permission: 'DAIRY_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INPUTS.INPUTS_LINK,
      picto: INPUTS.INPUTS_PICTO,
      label: 'navigation.inputs',
      disabled: !(Permissions({ permission: 'INPUT_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.ANALYSE_LINK,
      picto: INTERVENTIONS.ANALYSE_PICTO,
      label: 'navigation.analyse',
      disabled: !(Permissions({ permission: 'ANALYSE_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined)
      || context?.choiceBooklet !== 1,
    },
    {
      link: INTERVENTIONS.RAPPORT_LINK,
      picto: INTERVENTIONS.RAPPORT_PICTO,
      label: 'navigation.rapports',
      disabled: !(Permissions({ permission: 'REPORT_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.DOCUMENT_LINK,
      picto: INTERVENTIONS.DOCUMENT_PICTO,
      label: 'navigation.filling_other_documents',
      disabled: !(Permissions({ permission: 'OTHERDOC_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.CALENDAR_LINK,
      picto: INTERVENTIONS.CALENDAR_PICTO,
      label: 'navigation.calendar',
      disabled: !(Permissions({ permission: 'CALENDAR_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.TRAININGS_LINK,
      picto: INTERVENTIONS.TRAININGS_PICTO,
      label: 'navigation.trainings',
      disabled: !(Permissions({ permission: 'TRAINING_LIST' }) !== undefined
        || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.ARCHIVE_LINK,
      picto: INTERVENTIONS.ARCHIVE_PICTO,
      label: 'navigation.archives',
    },
    {
      link: INTERVENTIONS.PRESTATAIRE_LINK,
      picto: INTERVENTIONS.PRESTATAIRE_PICTO,
      label: 'navigation.providers',
      disabled: !(Permissions({ permission: 'SOCIETY_LIST' }) !== undefined
        || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
  ];

  const interventionsVehicule = [
    {
      link: INPUTS.INPUTS_LINK,
      picto: INPUTS.INPUTS_PICTO,
      label: 'navigation.inputs',
      disabled: !(Permissions({ permission: 'INPUT_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.INTERVENTIONS_LINK,
      picto: INTERVENTIONS.INTERVENTIONS_PICTO,
      label: 'navigation.interventions',
      disabled: !(Permissions({ permission: 'INTERVENTION_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONSVEHICULE.DOC_ATTENTE,
      picto: INTERVENTIONS.RAPPORT_PICTO,
      label: 'navigation.documents',
      disabled: !(Permissions({ permission: 'REPORT_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.CALENDAR_LINK,
      picto: INTERVENTIONS.CALENDAR_PICTO,
      label: 'navigation.calendar',
      disabled: !(Permissions({ permission: 'CALENDAR_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
    {
      link: INTERVENTIONS.CONTRACT_VEHICULE_LINK,
      picto: INTERVENTIONS.CONTRACT_VEHICULE_PICTO,
      label: 'dashboard.contracts_vehicule',
      disabled: !(Permissions({ permission: 'CONTRACT_LIST' }) !== undefined
      || Permissions({ permission: 'BOOKLET_READ' }) !== undefined),
    },
  ];

  const navigateDashboards = () => {
    let url;
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      url = `/sanitaire/${establishment?.id}-${estab}/dashboards`;
    }
    if (context.choiceBooklet === 2) {
      url = `/securite/${establishment?.id}-${estab}/dashboards`;
    }
    if (context.choiceBooklet === 3) {
      url = `/vehicule/${establishment?.id}-${estab}/dashboards`;
    }
    return url;
  };

  return (
    <span>
      <ListItem>
        <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/choix-carnet">
          <Picto className={styles.picto} name="ecarnetsmallwhite" />
          {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.choice_booklet')}</span>)}
        </Link>
      </ListItem>
      {isBookletChoosed && isSecurityBooklet && (
        <ListItem>
          <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/securite/choix-etablissement">
            <Picto className={styles.picto} name="etablissementsBlanc" />
            {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.choice_establishment')}</span>)}
          </Link>
        </ListItem>
      )}
      {isBookletChoosed && isVehicleBooklet && (
        <ListItem>
          <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/vehicule/choix-etablissement">
            <Picto className={styles.picto} name="etablissementsBlanc" />
            {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.choice_establishment')}</span>)}
          </Link>
        </ListItem>
      )}
      {isBookletChoosed && !isSecurityBooklet && !isVehicleBooklet && (
        <ListItem>
          <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/sanitaire/choix-etablissement">
            <Picto className={styles.picto} name="etablissementsBlanc" />
            {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.choice_establishment')}</span>)}
          </Link>
        </ListItem>
      )}
      <Divider />
      {((isBookletChoosed && isEstablishmentChoosed))
      && (
      <>
        <ListItem>
          <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to={navigateDashboards()}>
            <Picto className={styles.picto} name="home" />
            {!isCollapsed ? (
              <span className={styles.linkLabel}>{t('navigation.dashboard')}</span>
            ) : null}
          </Link>
        </ListItem>
        <Divider />
        {isSanitaryBooklet ? (
          <>
            {Permissions({ permission: 'REPORT_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined ? (
                <ListItem>
                  <div
                    role="button"
                    className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                    aria-hidden="true"
                  >
                    <Link
                      className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                      to="/monCarnet"
                    >
                      <Picto className={styles.picto} name="monRegistreBlanc" />
                      {!isCollapsed ? <span className={styles.linkLabel}>{t('navigation.monCarnet')}</span> : null}
                    </Link>
                  </div>
                </ListItem>
              ) : null }
          </>
        ) : isSecurityBooklet ? (
          <>
            {Permissions({ permission: 'REPORT_LIST' }) !== undefined
            || Permissions({ permission: 'BOOKLET_READ' }) !== undefined ? (
              <ListItem>
                <div
                  role="button"
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  aria-hidden="true"
                >
                  <Link
                    className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                    to="/registre"
                  >
                    <Picto className={styles.picto} name="monRegistreBlanc" />
                    {!isCollapsed ? <span className={styles.linkLabel}>{t('dashboard.my_ledger')}</span> : null}
                  </Link>
                </div>
              </ListItem>
              ) : null }
          </>
        ) : isVehicleBooklet ? (
          <>
            {Permissions({ permission: 'DOCUMENT_LIST' }) !== undefined
            || Permissions({ permission: 'BOOKLET_READ' }) !== undefined ? (
              <ListItem>
                <div
                  role="button"
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  aria-hidden="true"
                >
                  <Link
                    className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                    to="/vehicule"
                  >
                    <Picto className={styles.picto} name="vehiculeblanc" />
                    {!isCollapsed ? <span className={styles.linkLabel}>{t('dashboard.vehicule_book')}</span> : null}
                  </Link>
                </div>
              </ListItem>
              ) : null }
          </>
        ) : null}
        {Permissions({ permission: 'DOCUMENT_LIST' }) !== undefined
          || Permissions({ permission: 'BOOKLET_READ' }) !== undefined ? (
            <ListItem>
              <div
                role="button"
                className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                aria-hidden="true"
              >
                <Link
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  to="/base-documentaire"
                >
                  <Picto className={styles.picto} name="monRegistreBlanc" />
                  {!isCollapsed ? <span className={styles.linkLabel}>{t('dashboard.documentary_base')}</span> : null}
                </Link>
              </div>
            </ListItem>
          ) : null }
        <Divider />
        {isVehicleBooklet ? (
          <>
            <ListItem>
              <div
                role="button"
                className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                onClick={() => { setAccordionInterventionsClosed(!accordionInterventionsClosed); }}
                aria-hidden="true"
              >
                <Picto className={styles.picto} name="interventionsWhite" />
                {!isCollapsed ? (
                  <>
                    <span className={styles.linkLabel}>{t('navigation.interventions')}</span>
                    <FontAwesomeIcon
                      className={styles.linkPicto}
                      icon={accordionInterventionsClosed ? faChevronDown : faChevronRight}
                    />
                  </>
                ) : null}
              </div>
              {accordionInterventionsClosed ? (
                <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
                  {interventionsVehicule.map((item) => {
                    if (!isSecurityBooklet && item.label === 'navigation.trainings') return null;
                    if (item.disabled === true) return null;
                    return (
                      <ListItem key={item.id} className={styles.subItem}>
                        <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                          <Picto className={styles.picto} name={item.picto} />
                          <span className={styles.linkLabel}>{t(item.label)}</span>
                        </Link>
                      </ListItem>
                    );
                  })}
                </List>
              ) : null}
            </ListItem>
          </>
        ) : (
          <ListItem>
            <div
              role="button"
              className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
              onClick={() => { setAccordionInterventionsClosed(!accordionInterventionsClosed); }}
              aria-hidden="true"
            >
              <Picto className={styles.picto} name="interventionsWhite" />
              {!isCollapsed ? (
                <>
                  <span className={styles.linkLabel}>{t('navigation.interventions')}</span>
                  <FontAwesomeIcon
                    className={styles.linkPicto}
                    icon={accordionInterventionsClosed ? faChevronDown : faChevronRight}
                  />
                </>
              ) : null}
            </div>
            {accordionInterventionsClosed ? (
              <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
                {interventions.map((item) => {
                  if (!isSecurityBooklet && item.label === 'navigation.trainings') return null;
                  if (item.disabled === true) return null;
                  return (
                    <ListItem key={item.id} className={styles.subItem}>
                      <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                        <Picto className={styles.picto} name={item.picto} />
                        <span className={styles.linkLabel}>{t(item.label)}</span>
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            ) : null}
          </ListItem>
        )}
        {Permissions({ permission: 'CONTRACT_LIST' }) !== undefined
          || Permissions({ permission: 'BOOKLET_READ' }) !== undefined ? (
            <ListItem>
              <div
                role="button"
                className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                aria-hidden="true"
              >
                <Link
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  to={isVehicleBooklet ? '/contratsVehicule' : '/contracts'}
                >
                  <Picto className={styles.picto} name="contratsBlanc" />
                  {!isCollapsed ? <span className={styles.linkLabel}>{t('navigation.contracts')}</span> : null}
                </Link>
              </div>
            </ListItem>
          ) : null }
        {Permissions({ permission: 'STANDBY_READ' }) !== undefined
          || Permissions({ permission: 'BOOKLET_READ' }) !== undefined ? (
            <ListItem>
              <div
                role="button"
                className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                aria-hidden="true"
              >
                <Link
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  to={`/vacation/${context?.choiceEstablishment?.id}`}
                >
                  <Picto className={styles.picto} name="monRegistreBlanc" />
                  {!isCollapsed ? <span className={styles.linkLabel}>{t('navigation.holiday')}</span> : null}
                </Link>
              </div>
            </ListItem>
          ) : null }
        <Divider />
        <ListItem>
          <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/contact">
            <Picto className={styles.picto} name="enveloppe" />
            {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.contact')}</span>)}
          </Link>
        </ListItem>
        <Divider />
      </>
      )}
    </span>
  );
}

export default NavigationUser;
