/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import { getControlsList, deleteControl } from '../../services/controls';
// Utils
import styles from './Controls.module.css';
import useAppContext from '../../store/useAppContext';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Controls() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  // States
  const [openModal, setOpenModal] = useState(false);
  const [controlToDelete, setControlToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  const [context] = useAppContext();

  // API Calls
  const getControlsListQuery = useQuery(['controls', page, search], () => getControlsList({
    page: 0,
    search,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const deleteControlMutation = useMutation(deleteControl, {
    onSuccess:
      () => {
        getControlsListQuery.refetch();
        setControlToDelete({});
      },
  });

  const handleModal = (selectedControlToDelete) => {
    setOpenModal(true);
    setControlToDelete(selectedControlToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteControlMutation.mutate(controlToDelete.id);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('controls.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('controls.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('controls.equipments')}`,
        accessor: 'countEquipments',
      },
      {
        Header: `${t('controls.control_type')}`,
        accessor: 'type.name',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/controles/edit/${id}`}
              title={t('controls.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('controls.suppr')}
              onClick={() => handleModal({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('controls.title')}
        queryError={
          getControlsListQuery?.error
          || deleteControlMutation?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {t('controls.title')}
            </h1>
            <Link className="add" to="/controles/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('controls.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getControlsListQuery.isLoading}
            data={getControlsListQuery?.data?.data?.controls || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getControlsListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Controls;
