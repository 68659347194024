import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
// Components
import Input from '../../../atoms/Input/Input';
import Textarea from '../../../atoms/Textarea/Textarea';
import Button from '../../../atoms/Button/Button';

import styles from '../RadioNumberTime/RadioNumberTime.module.css';
import cn from '../../../../utils/cn';
import { useAppContext } from '../../../../store/AppContext';

function Number({
  label, value, onChange, id, step,
}) {
  const { t } = useTranslation();

  const [pop, setPop] = useState();
  const [remarque, setRemarque] = useState('');
  const [remarqueFinal, setRemarqueFinal] = useState();
  const EffectPop = useRef();
  const ColorRemarque = useRef();
  const [RemarqueNull, setRemarqueNull] = useState();
  const [titlePop, setTitlePop] = useState();
  const [Nb, setNb] = useState();
  const [context] = useAppContext();
  const navigate = useNavigate();
  const [minimun, setMinimun] = useState();

  useEffect(() => {
    if (context.choiceBooklet === 3) {
      setMinimun(value?.mileageIndex);
    } else {
      setMinimun(value?.paramLowLimit);
    }
  }, []);

  const PopUp = (nb, min) => {
    setPop(true);
    setRemarqueNull();
    let title;
    if (context.choiceBooklet !== 3) {
      title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancien [${min}]`;
    } else {
      title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancienne valeur [${min}]`;
    }
    setTitlePop(title);
    setNb(nb);
  };
  const PopEffect = () => {
    EffectPop.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValue = () => {
    setRemarqueFinal();
    setPop(false);
    setRemarque('');
    document.getElementById(`number-${id}`).focus();
  };
  const PopValid = (params) => {
    if (remarque !== '') {
      setRemarqueFinal(remarque);
      setPop(false);
    } else {
      EffectPop.current.style.transform = 'scale(1.01)';
      setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
      setRemarqueNull(true);
    }
    if (params === 'valid') {
      ColorRemarque.current.style.color = 'green';
    } else if (params === 'aide') {
      ColorRemarque.current.style.color = 'orange';
    }
  };

  return (
    <div>
      <div className="inputForm" style={{ borderBottom: remarqueFinal && 'none' }}>
        <div className="inputForm_control inputForm_label">
          {label}
        </div>
        <div style={{ color: 'grey' }}>
          {`Ancienne valeur : ${value.mileageIndex}`}
        </div>
        <div className="inputForm_control inputForm_input">
          <Input
            id={`number-${id}`}
            name={`number-${id}`}
            type="number"
            labelHidden
            value={value.number
              || value?.chlorineRate
              || value?.weight
              || value?.numberOfSwimmers
              || value?.ph
              // || value?.mileageIndex
              || value?.hydrometricDegree}
            onChange={(e) => onChange({ ...value, number: e.target.value })}
            step={step}
            onBlur={(e) => {
              if (e.target.value < minimun && e.target.value !== '') {
                PopUp(e.target.value, minimun);
              }
            }}
            min="0"
          />
        </div>
      </div>
      {!remarqueFinal ? (
        <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
          <div className="inputForm_control inputForm_label">
            {'Commentaire de l\'alerte'}
          </div>
          <div className={styles.remarque} style={{ borderBottom: '0' }}>
            <p style={{ color: '#28337e' }}>{value.alertComment}</p>
          </div>
        </div>
      ) : null }
      <div style={{ display: remarqueFinal ? '' : 'none' }}>
        <div className={styles.remarque}>
          <p ref={ColorRemarque}>{remarqueFinal}</p>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => { PopUp(Nb, minimun); }}
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: pop ? '' : 'none' }} ref={EffectPop}>
        {context.choiceBooklet !== 3
          ? (
            <>
              <h3 className={styles.title_popUp}>{titlePop}</h3>
              <div className={styles.textarea_popUp}>
                <Textarea
                  style={RemarqueNull && {
                    border: 'red solid 1px',
                  }}
                  id="remarque"
                  name="remarque"
                  label={t('alert.remarque')}
                  placeholder={RemarqueNull ? 'Veuillez indiquer une remarque..' : ''}
                  value={remarque}
                  onChange={(e) => { setRemarque(e.target.value); }}
                />
              </div>
              <div className={styles.div_button}>
                <Button
                  type="button"
                  label={t('alert.valider')}
                  onClick={() => {
                    PopValid('valid');
                    onChange({ ...value, remarque: { alert: true, alertComment: remarque, alertHelp: false } });
                  }}
                  className={cn([styles.btn_popUp, styles.btn_blue])}
                />
                <Button
                  type="button"
                  label={t('alert.aide')}
                  onClick={() => {
                    PopValid('aide');
                    onChange({ ...value, remarque: { alert: true, alertComment: remarque, alertHelp: true } });
                  }}
                  className={cn([styles.btn_popUp, styles.btn_orange])}
                />
                <Button
                  type="button"
                  label={t('alert.change_value')}
                  onClick={() => { ChangeValue(); }}
                  className={cn([styles.btn_popUp, styles.btn_red])}
                />
              </div>
            </>
          ) : (
            <>
              <h3 className={styles.title_popUp}>{titlePop}</h3>
              <div className={styles.div_button}>
                {value.lastInputId !== null ? (
                  <Button
                    type="button"
                    label={t('alert.change_value_historique')}
                    onClick={() => { navigate(`/saisies/historique/edit/${value.lastInputId}`); }}
                    className={cn([styles.btn_popUp, styles.btn_blue])}
                  />
                ) : null}
                <Button
                  type="button"
                  label={t('alert.change_value')}
                  onClick={() => { ChangeValue(); }}
                  className={cn([styles.btn_popUp, styles.btn_red])}
                />
              </div>
            </>
          )}
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: pop ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffect(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
}

Number.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Number.defaultProps = {
  step: 1,
};

export default Number;
