/* eslint-disable max-len */
import api from './_api';

/**
 * Get one Inputs
 * @param {id: Number} inputId
 * @returns {Promise<AxiosResponse>}
 */
export const getTemperature = (inputId) => api.get(`/mybooklet/temperature/${inputId.establishmentId}`);

/**
 * Get one Inputs
 * @param {id: Number} inputId
 * @returns {Promise<AxiosResponse>}
 */
export const getMeter = (inputId) => api.get(`/mybooklet/meters/${inputId.establishmentId}`);

/**
 * PUT - PUT Input
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const putInput = (input) => api.put(`/inputs/${input.id}`, input);
