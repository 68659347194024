import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { GetVersion } from '../../services/version';

function Version() {
  const version = useState('3.1.103');
  const date = useState('2024-02-26');
  const getVersion = useQuery(['categorie'], () => GetVersion());

  return (
    <div>
      <span>
        Version front :
        {version}
        -
        {date}
      </span>
      <br />
      <span>
        Version back :
        {getVersion.data?.data?.version}
        -
        {getVersion.data?.data?.date}
      </span>
    </div>
  );
}

export default Version;
