import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../components/template/Layout';
import useAppContext from '../../store/useAppContext';

import styles from './Error.module.css';
import Button from '../../components/atoms/Button/Button';

function Error() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const error = sessionStorage.getItem('error');
  const errorPath = sessionStorage.getItem('errorPath');
  const [context] = useAppContext();

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  useEffect(() => {
  }, []);

  const handleRefresh = () => {
    navigate(-2);
    sessionStorage.removeItem('error');
    sessionStorage.removeItem('errorPath');
  };
  setTimeout(() => {
    navigateDashboards();
  }, 5000);

  return (
    <Layout
      title="Error"
      description="Error"
      keywords="Error"
    >
      <button type="button" className={styles.links} onClick={() => navigate('/dashboards')}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
      <div className={styles.content}>
        <p className="mb-20">
          {t('error.errors')}
          :
        </p>
        <div className="mb-20">
          <span className={styles.error}>{error}</span>
          {' '}
          <span>{t('error.sur')}</span>
          {' '}
          {' '}
          <span className={styles.error}>{errorPath}</span>
        </div>
        <p className="mb-20">{t('error.message')}</p>
        <p className="mb-20">{t('error.merci')}</p>
      </div>
      <div className={styles.button}>
        <Button
          type="button"
          label={t('error.refresh')}
          className="add"
          onClick={handleRefresh}
        >
          {' '}
        </Button>
      </div>
    </Layout>
  );
}

export default Error;
