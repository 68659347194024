/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFileArrowDown,
  faCircle,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { sortBy } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
// Hooks
import useAppContext from '../../store/useAppContext';
// Services
import { getBuildingsList } from '../../services/structures';
// Utils
import styles from './ArchiveDocument.module.css';
import cn from '../../utils/cn';
import Permissions from '../../components/molecules/Permissions/Permissions';
import { getArchiveList } from '../../services/registre';
import { TRAINING_NAMES, TRAINING_TYPES } from '../../utils/constant';

function Archives() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  // States
  const [search, setSearch] = useState('0');
  const [selectedList, setSelectedList] = useState('0');
  const [buildingId, setBuildingId] = useState(context?.choiceEstablishment?.buildingIds[0]);
  // const [array, setArray] = useState();
  const [Registres, setRegistres] = useState([]);
  const [approvedBody, setApprovedBody] = useState([]);
  const [internalManagement, setInternalManagement] = useState([]);
  const [qualifiedTechnician, setQualifiedTechnician] = useState([]);
  const [safetyCommittee, setSafetyCommittee] = useState([]);
  const [training, setTraining] = useState([]);

  // API Calls

  const getRegistreListQuery = useQuery(['RegistreList'], () => getArchiveList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    onSuccess: (data) => {
      setRegistres(data);
      setApprovedBody(data?.data?.approvedBody);
      setInternalManagement(data?.data?.internalManagement);
      setQualifiedTechnician(data?.data?.qualifiedTechnician);
      setSafetyCommittee(data?.data?.safetyCommittee);
      setTraining(data?.data?.training);
    },
  });

  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) setBuildingId(data.data.buildings[0].id);
    },
  });

  const docfile = (id, data) => {
    window.open(`http://api-ecarnet.dev.zol.fr/reports/download-report/${id}/${data}`, '_blank');
  };

  const handleBuilding = (id) => {
    setBuildingId(id);
  };

  const uniqueEquipmentSet = new Set();

  const equipmentSafetyCommittee = Registres?.data?.safetyCommittee
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipmentQualifiedTechnician = Registres?.data?.qualifiedTechnician
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipmentInternalManagement = Registres?.data?.internalManagement
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipmentApprovedBody = Registres?.data?.approvedBody
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipementOption = [
    { value: '0', label: 'TOUS' },
    ...equipmentSafetyCommittee,
    ...equipmentQualifiedTechnician,
    ...equipmentInternalManagement,
    ...equipmentApprovedBody,
  ];

  useEffect(() => {
    const newApprovedBody = Registres?.data?.approvedBody?.filter((option) => {
      if (option.equipment.name === search || search === 'TOUS') {
        return option;
      }
      return null;
    });
    setApprovedBody(newApprovedBody);
    const newInternalManagement = Registres?.data?.internalManagement?.filter((option) => {
      if (option.equipment.name === search || search === 'TOUS') {
        return option;
      }
      return null;
    });
    setInternalManagement(newInternalManagement);
    const newQualifiedTechnician = Registres?.data?.qualifiedTechnician?.filter((option) => {
      if (option.equipment.name === search || search === 'TOUS') {
        return option;
      }
      return null;
    });
    setQualifiedTechnician(newQualifiedTechnician);
    const newSafetyCommittee = Registres?.data?.safetyCommittee?.filter((option) => {
      if (option.equipment.name === search || search === 'TOUS') {
        return option;
      }
      return null;
    });
    setSafetyCommittee(newSafetyCommittee);
    const newTraining = Registres?.data?.training?.filter((option) => {
      if (search === 'TOUS') {
        return option;
      }
      return null;
    });
    setTraining(newTraining);
  }, [search]);

  const options = React.useMemo(() => {
    let optionsArray = [
      { value: '0', label: 'TOUS' },
      { value: '1', label: 'COMMISSION DE SÉCURITÉ' },
      { value: '2', label: 'ORGANISMES AGRÉÉS' },
      { value: '3', label: 'TECHNICIENS COMPÉTENTS' },
      { value: '4', label: 'FORMATION' },
      { value: '5', label: 'RÉGIE INTERNE' },
    ];
    if (Registres?.data?.safetyCommittee.length < 1) {
      optionsArray = optionsArray.filter((option) => option.value !== '1');
    }
    if (Registres?.data?.internalManagement?.length < 1) {
      optionsArray = optionsArray.filter((option) => option.value !== '5');
    }
    return optionsArray;
  }, [Registres]);

  const mapForSelect = (arrayToMap) => (
    arrayToMap?.length
      ? sortBy(arrayToMap, ['name']).map((item) => ({
        ...item,
        label: item.label,
        value: item.value,
      }))
      : []);
  const commission = React.useMemo(
    () => [
      {
        Header: t('registre.commisiondesecu'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report.societyInChargeName',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.resquise'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.prescRestantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const organisme = React.useMemo(
    () => [
      {
        Header: t('registre.RapportOrganismes'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report.societyInChargeName',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const technicien = React.useMemo(
    () => [
      {
        Header: t('registre.techie'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report.societyInChargeName',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const formation = React.useMemo(
    () => [
      {
        Header: `${t('trainings.date_title')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          format(new Date(date), 'dd/MM/yyyy')
        ),
      },
      {
        Header: `${t('trainings.company')}`,
        accessor: 'society',
        Cell: ({
          row: {
            original: {
              speakerName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? speakerName || t('add_training.house_keeping')
            : '-'}`
        ),
      },
      {
        Header: `${t('trainings.fullname')}`,
        accessor: 'fullname',
        Cell: ({
          row: {
            original: {
              firstName, lastName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? '-'
            : `${firstName} ${lastName}`}`
        ),
      },
      {
        Header: `${t('trainings.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          `${type === TRAINING_TYPES?.FIRE_SAFETY_PREVENTION
            ? t('add_training.fire_safety_prevention')
            : t('add_training.maintenance_manager')}`
        ),
      },
      {
        Header: `${t('trainings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          `${name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS ? t('add_training.handling_emergency_means') : ''}
          ${name === TRAINING_NAMES.EVACUATION ? t('add_training.evacuation') : ''}
          ${name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION
            ? t('add_training.designated_employees_evacuation')
            : ''
          }
          ${name === TRAINING_NAMES.UNANNOUNCED_EXERCISE ? t('add_training.unannounced_exercise') : ''}
          ${name === TRAINING_NAMES.SSI ? t('add_training.ssi') : ''}
          ${name === TRAINING_NAMES.INTERNAL ? t('add_training.internal') : ''}
          ${name === TRAINING_NAMES.SSIAP ? t('add_training.ssiap') : ''}
          ${name === TRAINING_NAMES.SST ? t('add_training.sst') : ''}
          ${name === TRAINING_NAMES.ELECTRICAL_CLEARANCE ? t('add_training.electrical_clearance') : ''}`
        ),
      },
      {
        Header: `${t('trainings.headcount')}`,
        accessor: 'headcount',
      },
      {
        Header: `${t('trainings.person_in_charge')}`,
        accessor: 'personInCharge',
      },
      {
        Header: `${t('trainings.document')}`,
        accessor: 'documentFileName',
        Cell: ({ row: { original: { documentFile, documentFileName, id } } }) => (
          documentFile !== null && (
            <Button
              type="button"
              className="action"
              label={<FontAwesomeIcon icon={faFileArrowDown} />}
              title={t('trainings.download')}
              onClick={() => docfile(id, documentFileName)}
            />
          )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
  );
  const regie = React.useMemo(
    () => [
      {
        Header: t('registre.regieName'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.intervenant'),
        accessor: 'report?.societyInChargeName',
      },
      {
        Header: t('registre.interventioninterneDate'),
        accessor: 'report?.depositDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.depositDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.depositDate).toLocaleDateString();
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName)}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <Layout
      title={t('archives.title')}
      queryError={
        getRegistreListQuery?.error
        || getBuildingsList?.error
      }
      layout="table"
    >
      <header className="header">
        <div className="mb-20">
          <button type="button" className="link" onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('archives.back')}</span>
          </button>
        </div>
        <div className="row mb-5">
          <h1 className="title">
            {t('archives.title')}
          </h1>
        </div>
        <div className={cn(['shadow-sm', styles.form])}>
          <div className="from-group">
            <Select
              className={cn(['col-4', styles.select])}
              label={t('archives.equipement')}
              name="equipement"
              type="text"
              value={
                mapForSelect(equipementOption)
                  ?.find((item) => item.value === search)
              }
              onChange={(value) => {
                setSearch(value.label);
              }}
              options={mapForSelect(equipementOption)}
              loading={getRegistreListQuery?.isLoading}
            />
          </div>
          <div className="from-group">
            <Select
              className={cn(['col-4', styles.select])}
              label={t('archives.type')}
              name="type"
              type="text"
              value={
                  options.find((item) => item.value === selectedList)
              }
              onChange={(value) => {
                setSelectedList(value.value.toLowerCase());
              }}
              options={options}
            />
          </div>
        </div>
      </header>
      <div className={styles.filters}>
        <div className={styles.filtersToComplete}>
          {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
            <Button
              type="button"
              label={`${building.name}`}
              className={cn([styles.filter, buildingId === building.id
                ? styles.active
                : '', 'shadow-md'])}
              onClick={() => handleBuilding(building?.id)}
            />
          ))}
        </div>
      </div>
      <div className={styles.shadow}>
        {(selectedList === '' || selectedList === '0' || selectedList === '1') && safetyCommittee?.length >= 1
          ? (
            <div className={styles.table}>
              <Table
                columns={commission}
                isLoading={getRegistreListQuery.isLoading}
                data={safetyCommittee || []}
                hasSort
              />
            </div>
          ) : null }
        {selectedList === '' || selectedList === '0' || selectedList === '2' ? (
          <div className={styles.table}>
            <Table
              columns={organisme}
              isLoading={getRegistreListQuery.isLoading}
              data={approvedBody || []}
              hasSort
            />
          </div>
        ) : null }
        {selectedList === '' || selectedList === '0' || selectedList === '3' ? (
          <div className={styles.table}>
            <Table
              columns={technicien}
              isLoading={getRegistreListQuery.isLoading}
              data={qualifiedTechnician || []}
              hasSort
            />
          </div>
        ) : null }
        {selectedList === '' || selectedList === '0' || selectedList === '4' ? (
          <div className={styles.tableFormation}>
            <Table
              columns={formation}
              isLoading={getRegistreListQuery.isLoading}
              data={training || []}
              hasSort
            />
          </div>
        ) : null }
        {(selectedList === '' || selectedList === '0' || selectedList === '5') && internalManagement?.length >= 1
          ? (
            <div className={styles.tableRegie}>
              <Table
                columns={regie}
                isLoading={getRegistreListQuery.isLoading}
                data={internalManagement || []}
                hasSort
              />
            </div>
          ) : null }
      </div>
      <footer className="footer">
        <div className="mb-20">
          <button type="button" className="link" onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('archives.back')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Archives;
